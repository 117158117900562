<!-- clase box -->
<div class="subbasicbox chat" [ngClass]="{ 'active' : chats[chaturl]?.active, 'nuevomensaje' : chats[chaturl]?.nuevomessage}">

  <div class="boximage">
      <a routerLink="/dashboard/mensajes/chat/{{chaturl}}">

          <img *ngIf="theuser?.avatar else defaultheadavatar" src="{{theuser?.avatar}}" alt="{{theuser?.nombres | titlecase }} {{theuser?.apellidos | titlecase }}">
          <ng-template #defaultheadavatar>
            <img src="assets/img/defaultavatar.jpg" alt="{{theuser?.nombres | titlecase }} {{theuser?.apellidos | titlecase }}">
          </ng-template>

          <div class="status" [ngClass]="{
            'online': presencia === 'online',
            'away':  presencia === 'away',
            '':  presencia === 'offline'
          }">
          </div>


      </a>
  </div>
  <div class="boxtitles">
        <h4>{{theuser?.nombres | titlecase }} {{theuser?.apellidos | titlecase }}</h4>
        <ng-container *ngIf="tiempoDesconexion">
          <p> Activo hace {{tiempoDesconexion}}</p>
        </ng-container>

  </div>
  <div>
    <div class="boxchatvideocall">

      <a href="https://in.thecampus.education/webapp/#/?conference={{theuser?.usuario | titlecase}}&name={{themainuser_nombre | titlecase}} {{themainuser_apellidos | titlecase}}&role=guest&callType=video" target="_blank">
        <img src="assets/img/videocall.svg" >
        </a>
    </div>

  </div>

  <div class="boxdata">
        <div class="lastchat">

              <p>{{ LastChatMessage?.message | slice:0:13 }}</p>

        </div>
  </div>

  <div class="boxclose">
      <div class="close" >
        <a (click)="borrarchat(mainuid, theuser?.uid)">
          <svg xmlns="http://www.w3.org/2000/svg" width="11.513" height="8.063" viewBox="0 0 11.513 8.063">
            <g id="Grupo_9481" data-name="Grupo 9481" transform="translate(-5.602 -5.601)">
              <path id="Trazado_9279" data-name="Trazado 9279" d="M17.115,9.633Z" fill="#2D3453"/>
              <path id="Trazado_9280" data-name="Trazado 9280" d="M16.889,9.164a.575.575,0,0,0-.814,0l0,0-3.045,3.05L9.982,9.164a.578.578,0,0,0-.817.817l3.05,3.045-3.05,3.045a.578.578,0,0,0,.817.817l3.045-3.05,3.045,3.05a.578.578,0,0,0,.817-.817l-3.05-3.045,3.05-3.045a.575.575,0,0,0,0-.814Z" transform="translate(-3.394 -3.393)" fill="#2D3453"/>
            </g>
          </svg>
        </a>
      </div>
  </div>
</div>
<!--/clase box -->
