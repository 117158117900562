<div class="loginwrap">
  <div class="loginbox forget">
      <header>
          <div class="logo">
              <img src="/assets/img/logocolor.png" alt="">
          </div>
          <div class="title">
              <h1>Restablecer contraseña</h1>
          </div>
      </header>
      <article>

         <p>
             Por favor escriba su nombre de usuario o su correo electrónico y recibirá un link para crear una nueva contraseña a su mail.
         </p>


         <div class="loginform">
             <label for="">
                 Nombre de usuario o e-mail
             </label>
             <input class="user" type="email" placeholder="Nombre de usuario o e-mail" [(ngModel)]="email">
             <p class="response">{{response}}</p>

             <div class="submitbutton">

                 <input class="acceso" type="submit" value="ENVIAR" (click)="enviar()">
             </div>

         </div>

      </article>
      <footer>

      </footer>
  </div>
</div>
