<div class="alertas">
  <div *ngIf="menuHistorialActivo == false; else boxTitle" class="title-alert">
    <div id="title">
      <img src="assets/img/alertIcon.svg" />
      <h1>Mis alertas</h1>
    </div>
    <a (click)="changeMenu()" routerLink="/admin/alertas" class="button">
      Historial de reportes
      <img src="assets/img/historial.svg" />
    </a>
  </div>
  <ng-template #boxTitle>
    <div class="title-alert">
      <div id="title">
        <img src="assets/img/alertIcon.svg" />
        <h1>Historial alertas</h1>
      </div>
      <a (click)="changeMenu()" routerLink="/admin/alertas" class="button">
        Mis alertas
        <img src="assets/img/alertButton.svg" />
      </a>
    </div>
  </ng-template>

  <div
    *ngIf="menuHistorialActivo == false; else elsebox"
    class="lista-colegios"
  >
    <app-alertabox
      class="caja-alerta"
      *ngFor="let alertaDato of alertas"
      [alerta]="alertaDato"
    >
    </app-alertabox>
  </div>
  <ng-template #elsebox>
    <div id="dataTitle">
      <h2>Nombre</h2>
      <h2>Colegio</h2>
      <h2>Fecha</h2>
      <h2>Id</h2>
      <h2>Estado</h2>
    </div>
    <div class="dataContainer">
      <app-historialbox
        class="caja-alerta"
        *ngFor="let alertaDatoHistorial of alertasHistorial"
        [alerta]="alertaDatoHistorial"
      >
      </app-historialbox>
    </div>
  </ng-template>
</div>