<div>

    <section class="basicbox clase grupo">
      <header>
        <div class="wrapicon">

                <img src="/assets/img/grupos-b.svg" alt="">

        </div>
        <h3>Mis Clases </h3>

        <div class="searchcol">
          <img src="assets/img/search.svg">
          <input class="searchgroup" type="text" placeholder="Buscar clases" [(ngModel)]="searchValue">
        </div>

        <div>
          <ng-container *ngIf="this.userdata?.rol == 'director'">
            <a routerLink="/dashboard/creargrupo" class="button">
              <div class="plusicon">+</div>
              Crear clase
            </a>
          </ng-container>
        </div>
      </header>

      <div>
        <p class="groupcounter">

          <ng-container *ngIf="clasecontador === 1">
            {{clasecontador}} clase
          </ng-container>
          <ng-container *ngIf="clasecontador > 1">
            {{clasecontador}} clases
          </ng-container>

        </p>
      </div>

       <div class="claseheader">

         <ng-container *ngFor="let clase of misclases | search:searchValue ;">


            <div class="subbasicbox chat headinguser">

                <div class="boximage">


                  <img *ngIf="clase.imagen else defaultclassavatar" src="{{clase.imagen}}" alt="{{clase.nombre | titlecase }}">
                  <ng-template #defaultclassavatar><img src="assets/img/clase_default.jpg" alt="{{clase.nombre | titlecase }}"></ng-template>

                </div>
                <div class="boxtitles">
                      <h4>{{clase.nombre | titlecase }}</h4>
                      <p class="admins colegio">{{clase.colegio_nombre | titlecase }}</p>


                </div>
                <div class="rigthbuttons">

                    <ng-container *ngIf="this.userdata?.rol == 'profesor'">
                      <button [disabled]="clicked" (click)="DeleteClass(clase.uid);  clicked = true;" >
                        Borrar clase
                      </button>
                    </ng-container>

                    <ng-container *ngIf="this.userdata.rol != 'profesor'">
                      <div></div>
                    </ng-container>


                    <a routerLink="/dashboard/clase/{{clase.uid}}">
                      Ver clase

                    </a>



                </div>

                <div class="boxdata">

                    <div class="adminsgrid">
                        <div>
                          <p>{{clase.curso_nombre | titlecase }}</p>
                        </div>

                    </div>

                </div>


            </div>


        </ng-container>

       </div>

        <div>

        </div>


    </section>


  </div>
