<section class="basicbox fondo">
    <header class="headermisala padtop">
      <div class="icon">
          <div class="wrapicon">
              <img src="/assets/img/cog.svg" alt="">
          </div>
      </div>
      <h3>
         Mis ajustes de la sala
      </h3>
      <div class="copybutton">
        <button class="button" (click)="copy()" title="Comparte este link para&#013;que accedan a tu sala ">
          Link de mi Sala
        </button>
        <div *ngIf="copymsg==true">
          <p> <b>¡Link copiado!</b> </p>
        </div>
      </div>
    </header>
    <div class="submenu">
    <ul>
      
      <li routerLink="/dashboard/profile/{{ userUid }}/misala/pin"
          (click)="
            UpdateBreadcrumb(
              'Pin',
              '/dashboard/profile/' + userUid + '/misala/pin'
            )
          ">
          Pin
      </li>
      <li
          routerLink="/dashboard/profile/{{ userUid }}/misala/layout"
          (click)="
            UpdateBreadcrumb(
              'Layout',
              '/dashboard/profile/' + userUid + '/misala/layout'
            )
          "
        >
        Layout
      </li>
      <li
          routerLink="/dashboard/profile/{{ userUid }}/misala/dispositivos"
          (click)="
            UpdateBreadcrumb(
              'Dispositivos',
              '/dashboard/profile/' + userUid + '/misala/dispositivos'
            )
          "
        >
          Dispositivos
      </li>
      <!-- <li routerLink="/dashboard/profile/{{ userUid }}/misala/estadisticas"
      (click)="
        UpdateBreadcrumb(
          'Estadisticas',
          '/dashboard/profile/' + userUid + '/misala/estadisticas'
        )
      ">
      Estadísticas
  </li> -->
    </ul>
  </div>
    <div class="basicbox uploadfile">
            <router-outlet></router-outlet>
    </div>
</section>