<!-- /////USUARIO AJENO///// -->

<div class="perfil" *ngIf="noMyUser == true">
  <div class="claseheader">
    <div class="subbasicbox chat headinguser2">
      <div>
        <div class="imgContainer">
          <img
            *ngIf="avatar; else defaultavatar"
            class="imgPro2"
            src="{{ avatar }}"
            alt="{{ nombres | titlecase }} {{ apellidos | titlecase }}"
          />
        </div>
        <ng-template #defaultavatar
          ><img
            class="imgPro2"
            src="assets/img/defaultavatar.jpg"
            alt="{{ nombres | titlecase }} {{ apellidos | titlecase }}"
        /></ng-template>
      </div>
      <div class="boxtitles2">
        <div class="userName">
          <h4>{{ nombres | titlecase }} {{ apellidos | titlecase }}</h4>
        </div>
        <div class="userData">
          <h5>Tel: {{ telefono }}</h5>
          <h5>Email: {{ email }}</h5>
          <h5>Fecha de nacimiento: {{ fechanac | date : "dd/MM/yyyy" }}</h5>
          <h5>País: {{ pais | titlecase }}</h5>
          <h5>Género: {{ genero | titlecase }}</h5>
        </div>
      </div>
      <div class="links">
        <div>
          <img
            routerLink="/dashboard/mensajes/chat/{{ chaturl }}"
            src="/assets/img/mensaje.svg"
          />
        </div>
        <div>
          <a
          href="https://in.thecampus.education/webapp/#/?conference={{
            usuario
          }}&name={{
              user.nombres | titlecase
            }} {{ user.apellidos | titlecase }}&role=guest&callType=video"
            target="_blank"
          >
            <img src="assets/img/videollamada.svg" />
          </a>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- /////USUARIO NORMAL ///// -->

<div class="perfil" *ngIf="user && noMyUser == false">
  <div class="claseheader">
    <!-- clase box -->
    <div class="subbasicbox chat headinguser">
      <div class="boximage">
        <img
          *ngIf="avatar; else defaultavatar"
          src="{{ avatar }}"
          alt="{{ nombres | titlecase }} {{ apellidos | titlecase }}"
        />
        <ng-template #defaultavatar
          ><img
            src="assets/img/defaultavatar.jpg"
            alt="{{ nombres | titlecase }} {{ apellidos | titlecase }}"
        /></ng-template>
      </div>
      <div class="boxtitles">
        <div class="userName">
          <h4>{{ nombres | titlecase }} {{ apellidos | titlecase }}</h4>
        </div>
        <div class="userData">
          <h5>Tel: {{ telefono }}</h5>
          <h5>Email: {{ email }}</h5>
          <h5>Fecha de nacimiento: {{ fechanac | date : "dd/MM/yyyy" }}</h5>
          <h5>País: {{ pais | titlecase }}</h5>
          <h5>Género: {{ genero | titlecase }}</h5>
        </div>
        <p class="curso"></p>
      </div>
      <div class="rigthbuttons">
        <a>
          Ajustes de mi Perfil
          <span>
            <img src="/assets/img/user-w.svg" alt="" />
          </span>
        </a>
      </div>

      <div class="boxdata">
        <ul>
          <li>
            <a
              routerLink="/dashboard/profile/{{ uid }}/ajustes/"
              (click)="
                UpdateBreadcrumb(
                  'Perfil',
                  '/dashboard/profile/' + uid + '/ajustes/'
                )
              "
            >
              Ajustes de mi Perfil
            </a>
          </li>
          <li>
                <a routerLink="/dashboard/profile/{{uid}}/pass/">
                    Contraseña
                </a>
            </li>

          <li>
            <a
              routerLink="/dashboard/profile/{{ uid }}/foto/"
              (click)="
                UpdateBreadcrumb(
                  'Cambiar foto',
                  '/dashboard/profile/' + uid + '/foto/'
                )
              "
            >
              Cambiar foto
            </a>
          </li>

          <li>
            <a
              routerLink="/dashboard/profile/{{ uid }}/miseventos/"
              (click)="
                UpdateBreadcrumb(
                  'Mis Eventos',
                  '/dashboard/profile/' + uid + '/miseventos/'
                )
              "
            >
              Eventos
            </a>
          </li>
          <li>
            <a
              routerLink="/dashboard/profile/{{ uid }}/misala/"
              (click)="
                UpdateBreadcrumb(
                  'Ajustes mi sala',
                  '/dashboard/profile/' + uid + '/misala/'
                )
              "
            >
              Mi sala
            </a>
          </li>
        </ul>
      </div>
    </div>
    <!--/clase box -->
  </div>
  <div class="formcontent">
    <router-outlet *ngIf="currentuid == urlid"> </router-outlet>
  </div>
</div>
