<div>

  <section class="basicbox clase grupo">
    <header>
        <div class="wrapicon">
              <img src="/assets/img/grupos-b.svg" alt="">
        </div>
      <h3>Crear una nueva clase</h3>
    </header>

    <div>

      <section>

        <h4 class="marbot">1-Nombre y Descripción de la clase</h4>

        <div class="twocolsg">

          <div>

            <div class="formgroup">
              <label for="idnombreg">Nombre de la clase </label>
              <input id="idnombreg" class="form-control" type="text" placeholder="Escribir.." [(ngModel)]="nombre">
            </div>

            <div class="formgroup">
              <label for="iddescg">Descripción de la clase </label>
              <textarea id="iddescg" class="form-control" placeholder="Escribir.." [(ngModel)]="descripcion"></textarea>
            </div>

            <ng-container *ngIf="colegio != undefined">

              <div class="formgroup">
                <label for="idcolegio">Colegio </label>
                <input id="idcolegio" id="idfechanac" class="form-control" type="text"  [(ngModel)]="colegio.nombre" disabled>
              </div>

              <div class="formgroup">
                <label for="idcurso">Curso </label>
                <select id="idcurso" class="form-control" [(ngModel)]="curso">
                  <ng-container *ngFor="let curso of cursos;" >
                    <option value="{{curso}}">{{curso}}</option>
                  </ng-container>
                </select>
              </div>

            </ng-container>

          </div>

          <div></div>

        </div>

      </section>


      <section>

        <div class="twocolsg">

          <div>

            <h4 class="marbot3">2-Subir Imagen de la clase   </h4>

            <p>
              Sube una imagen para usarla como imagen de perfil de esta clase. La imagen se mostrará en la página principal de la clase y en resultados de búsqueda.
            </p>

            <div class="basicbox pad50 uploadfile">
              <div class="subbox" (click)="file.click()">

                  <img src="/assets/img/uploadicon.svg" alt="">
                  <input [hidden]="true" type="file" (change)="fileChangeEvent($event)" accept="image/*" #file>
                  <p>Arrastra el archivo aquí </p>

              </div>
              <p> <br></p>

            </div>

          </div>

          <div>

            <h4 class="marbot2">
              Visualización de la imagen de la clase
            </h4>

            <p>
              Visualiza la imagen de la clase en tamaño real
            </p>


            <div class="basicbox pad50 visualizaimg">
              <div class="subbox">

                <image-cropper *ngIf="imageChangedEvent"
                [imageChangedEvent]="imageChangedEvent"
                [maintainAspectRatio]="true"
                [aspectRatio]="1 / 1"
                [cropperMinWidth]="150"
                [cropperMinHeight]="150"
                format="jpg"
                (imageCropped)="imageCropped($event)"
                (imageLoaded)="imageLoaded()"
                (cropperReady)="cropperReady()"
                (loadImageFailed)="loadImageFailed()"></image-cropper>

              </div>

              <p>150 x 150 px</p>
            </div>

          </div>

        </div>

      </section>



      <section>

            <h4 class="martop marbot4">3-Invitar miembros      </h4>

            <div class="search">
              <img src="assets/img/search.svg">
              <input type="text" placeholder="Buscar miembros "  [(ngModel)]="searchValue">
            </div>

            <p>
              Primeramente busca los miembros a quien quieres adicionar a la clase, haz la selección de los miembros de la clase en la lista de abajo y pulsa el botón de añadir (+)
            </p>

            <div class="breadcrumb">
              <ul>
               <ng-container *ngFor="let user of userlist;">
                  <li>
                      <span>
                          {{user.payload.doc.data().nombres}} {{user.payload.doc.data().apellidos}}
                          <span class="close" (click)="removeuser(user.payload.doc.data().uid)">
                              <svg xmlns="http://www.w3.org/2000/svg" width="11.513" height="8.063" viewBox="0 0 11.513 8.063">
                                 <g id="Grupo_9481" data-name="Grupo 9481" transform="translate(-5.602 -5.601)">
                                   <path id="Trazado_9279" data-name="Trazado 9279" d="M17.115,9.633Z" fill="#fff"/>
                                   <path id="Trazado_9280" data-name="Trazado 9280" d="M16.889,9.164a.575.575,0,0,0-.814,0l0,0-3.045,3.05L9.982,9.164a.578.578,0,0,0-.817.817l3.05,3.045-3.05,3.045a.578.578,0,0,0,.817.817l3.045-3.05,3.045,3.05a.578.578,0,0,0,.817-.817l-3.05-3.045,3.05-3.045a.575.575,0,0,0,0-.814Z" transform="translate(-3.394 -3.393)" fill="#fff"/>
                                 </g>
                               </svg>

                          </span>

                      </span>

                  </li>
               </ng-container>
              </ul>
          </div>

          <ng-container *ngIf="results">

            <div class="basicbox twocolb">

              <ng-container *ngFor="let result of results | search2:searchValue;">

                <ng-container *ngIf="result.payload.doc.data().uid != userUid">

                  <!--userbox-->
                  <div class="userbox">
                    <div class="useravatar">
                        <img *ngIf="result.payload.doc.data().avatar else defaultheadavatar" src="{{result.payload.doc.data().avatar}}" alt="{{result.payload.doc.data().nombres}} {{result.payload.doc.data().apellidos}}">
                        <ng-template #defaultheadavatar><img src="assets/img/defaultavatar.jpg" alt="{{result.payload.doc.data().nombres}} {{result.payload.doc.data().apellidos}}"></ng-template>
                    </div>
                    <div>
                      <h4>{{result.payload.doc.data().nombres}} {{result.payload.doc.data().apellidos}}</h4>
                    </div>
                    <div >
                      <div>
                        <div class="plus" (click)="adduser(result)">
                          +
                        </div>
                      </div>
                      <div>

                      </div>
                    </div>
                  </div>
                  <!--/userbox-->

                </ng-container>

              </ng-container>

            </div>

          </ng-container>

      </section>

    </div>

    <p>{{saveresponse}}</p>
    <button class="button" [disabled]="clicked"  (click)="createclass();">Guardar </button>



  </section>


</div>
