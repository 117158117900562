import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../../../services/authentication.service';
import { EventosService } from '../../../services/eventos.service';
import { ClasesService } from '../../../services/clases.service';
import { UserService } from '../../../services/user.service';
import { NotificacionesService } from './../../../services/notificaciones.service';
import { AngularFireFunctions } from '@angular/fire/functions';
import { fi } from 'date-fns/locale';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';

@Component({
  selector: 'app-eventos',
  templateUrl: './eventos.component.html',
  styleUrls: ['./eventos.component.css'],
})
export class EventosComponent implements OnInit {
  userUid: any;
  nombre: string;
  descripcion: string;
  clase: any;
  estudiantes = [];
  fechainicio: any;
  horainicio: any;
  fechafinal: any;
  horafinal: any;
  userdata: any;
  clases = [];
  selectclase: any;
  selectuser = [];
  invitados: any;
  usuariosparaclase: any;
  clasetoda = [];
  notificacion: any;
  estudianteDatos: any;
  clicked = false;
  ocultarForm = false;
  videollamada: any = 'no';
  linkvideo: string = '';
  emails: any = new Array();
  email: any;
  formatoEmailMensaje: string;
  usuario:any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private eventosService: EventosService,
    private clasesService: ClasesService,
    private userService: UserService,
    private notificacionesService: NotificacionesService,
    private functions: AngularFireFunctions,
    private router: Router,
    private breadService: BreadcrumbService,
    private authenticationService: AuthenticationService
  ) {}

  ngOnInit(): void {
    this.estudiantes = new Array();
    this.getmainuserdata();
  }

  /* trae informacion principal del usuario logueado */
  getmainuserdata() {
    const Userdata: any = this.userService.getUserinfodata();

    if (Userdata === undefined) {
      this.authenticationService.isAuth().subscribe((auth) => {
        if (auth) {
          this.userUid = auth.uid; /* id del usuario */

          this.authenticationService
            .isUserAdmin(this.userUid)
            .subscribe((userdata) => {
              if (userdata[0]) {
                this.userdata = userdata[0];
                this.usuario=this.userdata.usuario

                this.userdata.clases.forEach((clase) => {
                  this.clasesService.getClaseById(clase).subscribe((tclase) => {
                    this.clases.push(tclase);
                  });
                });
              }
            });
        }
      });
    } else {
      this.userUid = Userdata.payload.doc.data().uid; /* id del usuario */
      this.userdata = Userdata.payload.doc.data();
      this.usuario = Userdata.payload.doc.data().usuario;

      this.userdata.clases.forEach((clase) => {
        this.clasesService.getClaseById(clase).subscribe((tclase) => {
          this.clases.push(tclase);
        });
      });
    }
  }

  selectclass(event) {
    this.clasesService.getClaseById(event).subscribe((clase) => {
      this.selectclase = clase.payload.data();
      this.selectuser = [];
      this.selectclase.usuarios.forEach((seclase) => {
        if (seclase != this.userUid) {
          this.userService.getUserById(seclase).subscribe((user) => {
            this.selectuser.push(user[0]);
          });
        }
      });
    });
  }

  selectinvitados(event) {
    this.clasesService.getClaseById(this.clase).subscribe((clase) => {
      this.selectclase = clase.payload.data();
      this.clasetoda = [];
      this.selectclase.usuarios.forEach((seclase) => {
        if (seclase != this.userUid) {
        this.userService.getUserById(seclase).subscribe((user) => {
          this.usuariosparaclase = user[0].payload.doc.data();
          this.clasetoda.push(this.usuariosparaclase.uid);
        });
      }
      });
    });
  }

  getFecha() {
    const current = new Date();
    current.setHours(current.getHours());
    current.setMinutes(current.getMinutes());
    current.setMonth(current.getMonth());
    current.setDate(current.getDate());
    return current;
  }

  createevent() {
    this.ocultarForm = true;
    this.notificacion = '';

    if (this.nombre === '' || this.nombre === undefined) {
      this.notificacion = 'Debe tener un nombre';
      setTimeout(() => {
        this.ocultarForm = false;
      }, 1500);
      return;
    }

    if (
      (this.clase === '' || this.clase === undefined) &&
      this.emails.length == 0
    ) {
      this.notificacion = 'Debe escoger una clase';
      setTimeout(() => {
        this.ocultarForm = false;
      }, 1500);
      return;
    }

    if (
      (this.invitados === '' || this.invitados === undefined) &&
      this.emails.length == 0
    ) {
      this.notificacion = 'Debe escoger una opción de invitados';
      setTimeout(() => {
        this.ocultarForm = false;
      }, 1500);
      return;
    }
    if (this.estudiantes.length <= 0 && this.emails.length == 0) {
      this.notificacion = 'Debe escoger algun invitados';
      setTimeout(() => {
        this.ocultarForm = false;
      }, 1500);
      return;
    }

    if (this.fechainicio === '' || this.fechainicio === undefined) {
      this.notificacion = 'Debe tener una fecha de inicio';
      setTimeout(() => {
        this.ocultarForm = false;
      }, 1500);
      return;
    }

    if (this.horainicio === '' || this.horainicio === undefined) {
      this.notificacion = 'Debe tener una hora de inicio';
      setTimeout(() => {
        this.ocultarForm = false;
      }, 1500);
      return;
    }

    if (this.fechafinal === '' || this.fechafinal === undefined) {
      this.notificacion = 'Debe tener una fecha de finalización';
      setTimeout(() => {
        this.ocultarForm = false;
      }, 1500);
      return;
    }

    if (this.horafinal === '' || this.horafinal === undefined) {
      this.notificacion = 'Debe tener una hora de finalización';
      setTimeout(() => {
        this.ocultarForm = false;
      }, 1500);
      return;
    }

    if (this.fechafinal < this.fechainicio) {
      this.notificacion =
        'La fecha de inicio tiene que ser anterior a la fecha final';
      setTimeout(() => {
        this.ocultarForm = false;
      }, 1500);
      return;
    }

    if (
      this.fechainicio == this.fechafinal &&
      this.horafinal < this.horainicio
    ) {
      this.notificacion =
        'La hora de inicio tiene que ser anterior a la hora final si es el mismo dia';
      setTimeout(() => {
        this.ocultarForm = false;
      }, 1500);
      return;
    }

    var fechaActual = this.getFecha();
    var fechaFinalComprobación = new Date(
      this.fechafinal + ' ' + this.horafinal
    );
    if (fechaActual > fechaFinalComprobación) {
      this.notificacion = 'La fecha del evento no puede ser en el pasado';
      setTimeout(() => {
        this.ocultarForm = false;
      }, 1500);
      return;
    }

    if (this.descripcion === undefined) {
      this.descripcion = '';
    }

    if (this.videollamada == 'si') {
      this.linkvideo =
        'https://in.thecampus.education/webapp/#/?conference=' +
        this.userdata.usuario +
        '&role=guest&callType=video';
    }

    if (this.invitados === 'clase') {
      if (this.clasetoda.length > 0) {
        this.clasetoda.push(this.userUid);
        var asis = new Array();
        var noasis = new Array();
        const evento = {
          nombre: this.nombre,
          descripcion: this.descripcion,
          clase: this.clase,
          estudiantes: this.clasetoda,
          fecha_inicio: new Date(this.fechainicio + ' ' + this.horainicio),
          fechainicio: this.fechainicio,
          horainicio: this.horainicio,
          fecha_final: new Date(this.fechafinal + ' ' + this.horafinal),
          fechafinal: this.fechafinal,
          horafinal: this.horafinal,
          creado: this.userUid,
          asistentes: asis,
          noasistentes: noasis,
          videoconferencia: this.linkvideo,
          emails: this.emails,
        };

        var fechainicio = this.fechainicio.split('-').reverse().join('-');
        this.eventosService.createdEvento(evento).then((evnt) => {
          this.notificacion = 'El evento ha sido creado';

          setTimeout(() => {
            this.notificacion = '';
            this.ocultarForm = false;
            this.router.navigate([
              '/dashboard/profile/' + this.userUid + '/miseventos/',
            ]);
          }, 2000);

          this.clasetoda.forEach((estudiante) => {
            const notification = {
              user: estudiante,
              texto:
                'Ha sido invitado al evento ' +
                this.nombre +
                '. El dia ' +
                fechainicio +
                ', hora: ' +
                this.horainicio,
              url: '/dashboard/profile/' + estudiante + '/miseventos',
              verlo: estudiante,
              fecha: new Date(),
              event_id: evnt.id,
            };
            this.notificacionesService.createdNotificacion(notification);

            if (this.userUid !== estudiante) {
              var linkEmail: string;
              var textEmail: string;
              var textboton: string;
              if (this.linkvideo != '') {
                linkEmail = this.linkvideo;
                textEmail =
                  'Ha sido invitado a la videoconferencia ' +
                  this.nombre +
                  '. El dia ' +
                  fechainicio +
                  ', hora: ' +
                  this.horainicio;
                textboton = 'Ir a la conferencia';
              } else {
                linkEmail = 'https://thecampus.education/';
                textEmail =
                  'Ha sido invitado al evento ' +
                  this.nombre +
                  '. El dia ' +
                  fechainicio +
                  ', hora: ' +
                  this.horainicio;
                textboton = 'Iniciar sesión';
              }
              // this.userService.getUserById(estudiante).subscribe((tuser) => {
              //   this.estudianteDatos = tuser[0].payload.doc.data();
              //   const callable = this.functions.httpsCallable('sendEmail');
              //   const obs = callable({
              //     email: this.estudianteDatos.email,
              //     asunto: 'Ha sido invitado a un evento en The Campus',
              //     title: 'Ha sido invitado a un evento',
              //     text: textEmail,
              //     link: linkEmail,
              //     linktext: textboton,
              //   });
              //   obs.subscribe(async (res) => {});
              // });
            }
          });
          var linkEmail: string;
          var textEmail: string;
          var textboton: string;
          if (this.linkvideo != '') {
            linkEmail = this.linkvideo;
            textEmail =
              'Ha sido invitado a la videoconferencia ' +
              this.nombre +
              '. El dia ' +
              fechainicio +
              ', hora: ' +
              this.horainicio;
            textboton = 'Ir a la conferencia';
          } else {
            linkEmail = 'https://thecampus.education/';
            textEmail =
              'Ha sido invitado al evento ' +
              this.nombre +
              '. El dia ' +
              fechainicio +
              ', hora: ' +
              this.horainicio;
            textboton = 'Iniciar sesión';
          }
          this.emails.forEach((element) => {
            const callable = this.functions.httpsCallable('sendEmail');
            const obs = callable({
              email: element,
              asunto: 'Ha sido invitado a una videoconferencia',
              title: 'Ha sido invitado a una videoconferencia',
              text: textEmail,
              link: linkEmail,
              linktext: 'Ir a la videoconferencia',
            });
            obs.subscribe(async (res) => {});
          });
        });
      }
    } else {
      this.estudiantes.push(this.userUid);
      var asis = new Array();
      var noasis = new Array();

      var clase;
      if (this.clase === '' || this.clase === undefined) {
        clase = '';
      } else {
        clase = this.clase.toLowerCase();
      }

      const evento = {
        nombre: this.nombre.toLowerCase(),
        descripcion: this.descripcion,
        clase: clase,
        estudiantes: this.estudiantes,
        fecha_inicio: new Date(this.fechainicio + ' ' + this.horainicio),
        fechainicio: this.fechainicio,
        horainicio: this.horainicio,
        fecha_final: new Date(this.fechafinal + ' ' + this.horafinal),
        fechafinal: this.fechafinal,
        horafinal: this.horafinal,
        creado: this.userUid,
        asistentes: asis,
        noasistentes: noasis,
        videoconferencia: this.linkvideo,
        emails: this.emails,
      };

      var fechainicio = this.fechainicio.split('-').reverse().join('-');

      this.eventosService.createdEvento(evento).then((evnt) => {
        this.notificacion = 'El evento ha sido creado';
        setTimeout(() => {
          this.notificacion = '';
          this.router.navigate([
            '/dashboard/profile/' + this.userUid + '/miseventos/',
          ]);
        }, 1000);

        this.estudiantes.forEach((estudiante) => {
          const notification = {
            user: estudiante,
            texto:
              'Ha sido invitado al evento: ' +
              this.nombre +
              '. El dia ' +
              fechainicio +
              ', hora: ' +
              this.horainicio,
            url: '/dashboard/profile/' + estudiante + '/miseventos',
            verlo: estudiante,
            fecha: new Date(),
            event_id: evnt.id,
          };
          this.notificacionesService.createdNotificacion(notification);

          if (this.userUid !== estudiante) {
            var linkEmail: string;
            var textEmail: string;
            var textboton: string;
            if (this.linkvideo != '') {
              linkEmail = this.linkvideo;
              textEmail =
                'Ha sido invitado a la videoconferencia ' +
                this.nombre +
                '. El dia ' +
                fechainicio +
                ', hora: ' +
                this.horainicio;
              textboton = 'Ir a la conferencia';
            } else {
              linkEmail = 'https://thecampus.education/';
              textEmail =
                'Ha sido invitado al evento ' +
                this.nombre +
                '. El dia ' +
                fechainicio +
                ', hora: ' +
                this.horainicio;
              textboton = 'Iniciar sesión';
            }
            // this.userService.getUserById(estudiante).subscribe((tuser) => {
            //   this.estudianteDatos = tuser[0].payload.doc.data();
            //   const callable = this.functions.httpsCallable('sendEmail');
            //   const obs = callable({
            //     email: this.estudianteDatos.email,
            //     asunto: 'Ha sido invitado a un evento en The Campus',
            //     title: 'Ha sido invitado a un evento',
            //     text: textEmail,
            //     link: linkEmail,
            //     linktext: textboton,
            //   });
            //   obs.subscribe(async (res) => {});
            // });
          }
        });
        var linkEmail: string;
        var textEmail: string;
        var textboton: string;
        if (this.linkvideo != '') {
          linkEmail = this.linkvideo;
          textEmail =
            'Ha sido invitado a la videoconferencia: ' +
            this.nombre +
            '. El dia ' +
            fechainicio +
            ', hora: ' +
            this.horainicio;
          textboton = 'Ir a la conferencia';
        } else {
          linkEmail = 'https://thecampus.education/';
          textEmail =
            'Ha sido invitado al evento: ' +
            this.nombre +
            '. El dia ' +
            fechainicio +
            ', hora: ' +
            this.horainicio;
          textboton = 'Iniciar sesión';
        }
        this.emails.forEach((element) => {
          const callable = this.functions.httpsCallable('sendEmail');
          const obs = callable({
            email: element,
            asunto: 'Ha sido invitado a una videoconferencia',
            title: 'Ha sido invitado a una videoconferencia',
            text: textEmail,
            link: linkEmail,
            linktext: 'Ir a la videoconferencia',
          });
          obs.subscribe(async (res) => {});
        });
      });
    }
  }
  getUserId(event, uid) {
    if (event.target.checked) {
      this.estudiantes.push(uid);
    } else {
      this.estudiantes = this.estudiantes.filter((m) => m != uid);
    }
  }
  addEmail() {
    if (this.validateEmail(this.email)) {
      if (!this.emails.includes(this.email)) {
        this.emails.push(this.email);
        this.email = '';
      }
    } else {
      this.formatoEmailMensaje = 'Formato de email no válido';
      setTimeout(() => {
        this.formatoEmailMensaje = '';
      }, 2000);
    }
  }

  quitarEmail(email) {
    var posicion = this.emails.indexOf(email);
    this.emails.splice(posicion, 1);
  }

  UpdateBreadcrumb(name, url) {
    this.breadService.addtobread(name, url);
  }

  validateEmail(email) {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }
}
