<div *ngIf="cargado">

<app-header [nombres]="nombres" [apellidos]="apellidos" [colegio]="colegio" [genero]="genero"></app-header>

    <div class="maingrid">
        <div>

          <app-menu [uidb]="mainUserUidDb" [uid]="userUid" [avatar]="useravatar" [rolUser]="rolUsuario"></app-menu>

        </div>

        <div>

                <!-- content -->
                <div class="contenido">

                   <section class="basicbox2 horojo medium martop misclases">
                       <header routerLink="/dashboard/misgrupos">
                           <h3>Mis Clases</h3>
                           <div class="icon" routerLink="/dashboard/misgrupos"  (click)="UpdateBreadcrumb('Mis Clases', '/dashboard/misgrupos')">
                               <div>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 38 38">
                                  <g id="Grupo_9429" data-name="Grupo 9429" transform="translate(-4 -4)">
                                    <path id="Trazado_8129" data-name="Trazado 8129" d="M5.357,12.143a1.357,1.357,0,0,0,1.357-1.357V9.958A3.25,3.25,0,0,1,9.958,6.714h.828a1.357,1.357,0,0,0,0-2.714H9.958A5.965,5.965,0,0,0,4,9.958v.828A1.357,1.357,0,0,0,5.357,12.143Z" fill="#23273b"/>
                                    <path id="Trazado_8130" data-name="Trazado 8130" d="M10.786,53.429H9.958a3.25,3.25,0,0,1-3.244-3.244v-.828a1.357,1.357,0,0,0-2.714,0v.828a5.965,5.965,0,0,0,5.958,5.958h.828a1.357,1.357,0,1,0,0-2.714Z" transform="translate(0 -14.143)" fill="#23273b"/>
                                    <path id="Trazado_8131" data-name="Trazado 8131" d="M50.185,4h-.828a1.357,1.357,0,0,0,0,2.714h.828a3.25,3.25,0,0,1,3.244,3.244v.828a1.357,1.357,0,1,0,2.714,0V9.958A5.965,5.965,0,0,0,50.185,4Z" transform="translate(-14.143)" fill="#23273b"/>
                                    <path id="Trazado_8132" data-name="Trazado 8132" d="M54.786,48a1.357,1.357,0,0,0-1.357,1.357v.828a3.25,3.25,0,0,1-3.244,3.244h-.828a1.357,1.357,0,0,0,0,2.714h.828a5.965,5.965,0,0,0,5.958-5.958v-.828A1.357,1.357,0,0,0,54.786,48Z" transform="translate(-14.143 -14.143)" fill="#23273b"/>
                                    <path id="Trazado_8133" data-name="Trazado 8133" d="M26.794,34.937h0a9.442,9.442,0,0,0-.889-.482.664.664,0,0,1-.2-1.079,5.428,5.428,0,0,0,1.615-4.133,5.7,5.7,0,0,0-.075-.679h0a5.145,5.145,0,0,0-.2-.76.7.7,0,0,0-.088-.231,5.265,5.265,0,0,0-.224-.5c-.041-.075-.075-.156-.115-.231a5.42,5.42,0,0,0-.407-.618h0a5.429,5.429,0,0,0-8.378,6.905c.076.092.154.181.235.267a.679.679,0,0,1-.019.959.686.686,0,0,1-.185.126,9.54,9.54,0,0,0-4.194,3.8,3.183,3.183,0,0,0,2.714,4.811H27.378a3.155,3.155,0,0,0,2.816-4.635A9.541,9.541,0,0,0,26.794,34.937Z" transform="translate(-2.966 -6.508)" fill="#23273b"/>
                                    <path id="Trazado_8134" data-name="Trazado 8134" d="M43.7,26.169a9.541,9.541,0,0,0-4.173-3.78.679.679,0,0,1-.343-.9.686.686,0,0,1,.14-.2,5.509,5.509,0,0,0,1.106-6.256,4.907,4.907,0,0,0-2.606-2.558,5.483,5.483,0,0,0-7.263,2.717c-.045.1-.088.2-.127.3a2.2,2.2,0,0,0,.122,1.846,4.231,4.231,0,0,0,1.357,1.466,8.1,8.1,0,0,1,3.474,5.327A8.235,8.235,0,0,1,35.16,27.9a2.4,2.4,0,0,0,.658,2.416h0a2.375,2.375,0,0,0,1.649.679h3.481A3.189,3.189,0,0,0,43.7,26.169Z" transform="translate(-8.448 -2.565)" fill="#23273b"/>
                                  </g>
                                </svg>
                               </div>
                           </div>
                       </header>
                       <div id="flexContainer">

                         <article>
  
  
                          <ng-container *ngFor="let clase of misclases">
  
                            <!-- clase box -->
                            <div class="subbasicbox2">
  
                              <div class="boximage">
                                  <a routerLink="/dashboard/clase/{{clase.payload?.doc.id}}" (click)="UpdateBreadcrumb(clase.payload?.doc.data().nombre, '/dashboard/clase/'+clase.payload?.doc.id)">
                                    <img *ngIf="clase.payload?.doc.data().imagen else defaultclassavatar" src="{{clase.payload?.doc.data().imagen}}" alt="{{clase.payload?.doc.data().nombre | titlecase }}">
                                    <ng-template #defaultclassavatar><img src="assets/img/clase_default.jpg" alt="{{clase.payload?.doc.data().nombre | titlecase }}"></ng-template>
                                  </a>
                              </div>
                              <div class="boxtitles">
                                     <h4><a routerLink="/dashboard/clase/{{clase.payload?.doc.id}}">{{clase.payload?.doc.data().nombre | titlecase }} </a></h4>
                                     <p>{{clase.payload?.doc.data().colegio_nombre | titlecase }}</p>
                              </div>
                              <div>
                                   <!--<div class="number">
                                       3
                                   </div>-->
                                  <div class="videocall">
                                    <a routerLink="/dashboard/clase/{{clase.payload?.doc.id}}">
                                      <img src="/assets/img/chatbox-b.svg" >
                                    </a>
                                  </div>
                              </div>
  
                              <div class="boxdata">
                                     <p>{{clase.payload?.doc.data().curso_nombre | titlecase }}</p>
                              </div>
  
                               <div class="boxvideocall">
                                      <div class="videocall">
                                         <a title="The campus video" (click)="irSala(clase)">
                                          <img src="/assets/img/videocall.svg" alt="">
                                         </a>
                                      </div>
                               </div>
                          </div>
                          <!--/clase box -->
  
                          </ng-container>
  
                         </article>
  
                         <footer>
                              <a routerLink="/dashboard/misgrupos"  (click)="UpdateBreadcrumb('Mis Clases', '/dashboard/misgrupos')" class="button medium center">
                                  Ver todos
                              </a>
                         </footer>
                       </div>
                   </section>





                    <section class="contentwrapper">

                        <div class="breadcrumb">
                               <div id="container">
                                <ng-container *ngFor="let bread of breadcrumb;" id="container">
                                   <div routerLink="{{bread.link}}" id="migas">
                                       <div id="migasF">
                                         <div id="nameM">
                                           {{bread.nombre}}
                                         </div>
                                         <div>
                                           <span class="close" (click)="RemoveBreadcrumb(bread.nombre)">
                                               <svg xmlns="http://www.w3.org/2000/svg" width="11.513" height="8.063" viewBox="0 0 11.513 8.063">
                                                  <g id="Grupo_9481" data-name="Grupo 9481" transform="translate(-5.602 -5.601)">
                                                    <path id="Trazado_9279" data-name="Trazado 9279" d="M17.115,9.633Z" fill="#fff"/>
                                                    <path id="Trazado_9280" data-name="Trazado 9280" d="M16.889,9.164a.575.575,0,0,0-.814,0l0,0-3.045,3.05L9.982,9.164a.578.578,0,0,0-.817.817l3.05,3.045-3.05,3.045a.578.578,0,0,0,.817.817l3.045-3.05,3.045,3.05a.578.578,0,0,0,.817-.817l-3.05-3.045,3.05-3.045a.575.575,0,0,0,0-.814Z" transform="translate(-3.394 -3.393)" fill="#fff"/>
                                                  </g>
                                                </svg>
                                              </span>
                                         </div>

                                      </div>
                                    </div>
                                </ng-container>
                              </div>
                            
                        </div>


                        <!-- contenedor cambiante -->
                        <section class="changecontent">
                          <router-outlet>
                          </router-outlet>
                        </section>
                        <!-- /contenedor cambiante -->

                    </section>



                </div>
                <!-- /content -->




        </div>
    </div>
  </div>