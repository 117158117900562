import { NotificacionesService } from './../../services/notificaciones.service';
import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../services/authentication.service';
import { UserService } from '../../services/user.service';


@Component({
  selector: 'app-notificaciones',
  templateUrl: './notificaciones.component.html',
  styleUrls: ['./notificaciones.component.css']
})
export class NotificacionesComponent implements OnInit {
  lasnotificaciones: any;
  itemsperpage = 10;
  totalpages;
  p = 1;

  constructor(private notificacionesService: NotificacionesService,
              private userService: UserService,
              private authenticationService: AuthenticationService) {

    const Userdata: any = this.userService.getUserinfodata();

    if (Userdata === undefined) {
      this.authenticationService.isAuth().subscribe(auth =>{
        if(auth) {
          this.notificacionesService.getNotificaciones(auth.uid).subscribe(notificaciones => {
            this.lasnotificaciones = notificaciones;
            this.totalpages = Math.round(this.lasnotificaciones.length / this.itemsperpage);
            if(this.totalpages === 0) {
              this.totalpages = 1;
            }
          });
        }
      })
    } else {
      this.notificacionesService.getNotificaciones(Userdata.payload.doc.data().uid).subscribe(notificaciones => {
        this.lasnotificaciones = notificaciones;
        this.totalpages = Math.round(this.lasnotificaciones.length / this.itemsperpage);
        if(this.totalpages === 0) {
          this.totalpages = 1;
        }
      });
    }
  }

  ngOnInit(): void {
  }

  deletenotificacion(uid) {
    this.notificacionesService.removeNotificacion(uid).then((data) => {
    });
  }

  vernotificacion(uid) {
    const update = {
      verlo: '',
    }
    this.notificacionesService.updateNotificacion(uid, update).then((data) => {
    });
  }

  nextpage() {
    if (this.p < this.totalpages) {
      this.p++;
    }
  }

  prevpage() {
    if (this.p > 1) {
      this.p--;
    }
  }

}
