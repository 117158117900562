import { Component, OnInit } from '@angular/core';
import { UserService } from './../services/user.service';
import { AuthenticationService } from './../services/authentication.service';
import { BreadcrumbService } from '../services/breadcrumb.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {
  mainUserUidDb: string;
  mainuserid: string;
  nombres: string;
  apellidos: string;
  mainuseremail: any;
  useravatar: string;
  breadcrumb = [];
  misclases = [];

  datosUsuario: any;

  constructor(private userService: UserService,
              private authenticationService: AuthenticationService,
              private router: Router,
              private breadService: BreadcrumbService) { }

  ngOnInit(): void {

    const Userdata: any = this.userService.getUserinfodata();

    if (Userdata === undefined) {

      this.getCurrentUser();

    } else {
      this.mainuserid = Userdata.payload.doc.data().uid;

      this.authenticationService.isUserAdmin(this.mainuserid).subscribe(userdata => {
        // TODO: check and redirect to login
        // if( userdata.rol != 'admin' ){
        // }
      });

      this.mainUserUidDb = Userdata.payload.doc.id;
      this.datosUsuario = Userdata.payload.doc.data();
      this.nombres = this.datosUsuario.nombres;
      this.apellidos = this.datosUsuario.apellidos;
      this.useravatar = this.datosUsuario.avatar;
      
      this.mainuseremail = Userdata.payload.doc.data().email;
      


    }
  }

  getCurrentUser() {
    this.authenticationService.isAuth().subscribe(auth => {
      if(auth) {
        this.mainuserid = auth.uid;

        this.authenticationService.isUserAdmin(this.mainuserid).subscribe(userdata => {
          // TODO: check and redirect to login
          // if( userdata.rol != 'admin' ){
          // }
        });

        this.userService.getUserById(this.mainuserid).subscribe(userdata => {
          if(userdata[0].payload.doc.data()){
            this.mainUserUidDb = userdata[0].payload.doc.id;

            this.datosUsuario = userdata[0].payload.doc.data();
            this.nombres = this.datosUsuario.nombres;
            this.apellidos = this.datosUsuario.apellidos;
            this.useravatar = this.datosUsuario.avatar;
          }
        });
      }
    });
  }

  UpdateBreadcrumb(name, url) {
    this.breadService.addtobread(name, url);
  }

  RemoveBreadcrumb(name) {
    this.breadService.removetobread(name);
    this.router.navigate(['admin']);
  }


}
