import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../../../../services/authentication.service';
import { EventosService } from '../../../../services/eventos.service';
import { ClasesService } from '../../../../services/clases.service';
import { UserService } from '../../../../services/user.service';
import { NotificacionesService } from '../../../../services/notificaciones.service';
import { AngularFireFunctions } from '@angular/fire/functions';

@Component({
  selector: 'app-editarevento',
  templateUrl: './editarevento.component.html',
  styleUrls: ['./editarevento.component.css'],
})
export class EditareventoComponent implements OnInit {
  userUid: any;
  nombre: string;
  descripcion: string;
  clase: any;
  estudiantes: any;
  fechainicio: any;
  horainicio: any;
  fechafinal: any;
  horafinal: any;
  userdata: any;
  clases = [];
  selectclase: any;
  selectuser = [];
  invitados: any;
  usuariosparaclase: any;
  clasetoda = [];
  notificacion: any;

  eventid: string;
  eventdata: any;
  estudiantesorg: any;
  evdata: any;
  estudianteDatos: any;
  videollamada: string;
  linkvideo: string;
  videoconferencia: string;
  emails: any = new Array();
  email: any;
  formatoEmailMensaje: string;
  

  constructor(
    private activatedRoute: ActivatedRoute,
    private eventosService: EventosService,
    private clasesService: ClasesService,
    private userService: UserService,
    private notificacionesService: NotificacionesService,
    private functions: AngularFireFunctions,
    private authenticationService: AuthenticationService,
    private router: Router,
  ) {
    /* toma el parametro uid que viene en la url */
    this.estudiantes = new Array();
    this.activatedRoute.params.subscribe((params) => {
      /* uid del evento actual */
      this.eventid = params['uid'];

      const Userdata: any = this.userService.getUserinfodata();

      if (Userdata === undefined) {
        this.getmainuserdata();
       
      } else {
        this.userUid = Userdata.payload.doc.data().uid;

        this.userdata = Userdata.payload.doc.data();

        this.userdata.clases.forEach((clase) => {
          this.clasesService.getClaseById(clase).subscribe((tclase) => {
            this.clases.push(tclase);
          });
        });
        
        this.geteventdata(this.eventid);
      }
    });
  }

  ngOnInit(): void {}

  /* trae informacion principal del usuario logueado */
  getmainuserdata() {
    this.authenticationService.isAuth().subscribe((auth) => {
      if (auth) {

        this.userUid = auth.uid; /* id del usuario */

        this.authenticationService
          .isUserAdmin(this.userUid)
          .subscribe((userdata) => {
            if (userdata[0]) {
              this.userdata = userdata[0];

              this.userdata.clases.forEach((clase) => {
                this.clasesService.getClaseById(clase).subscribe((tclase) => {
                  this.clases.push(tclase);
                });
              });

              this.geteventdata(this.eventid);
            }
          });
      }

    });
  }

  geteventdata(eventid) {
    this.eventdata = '';
    this.nombre = '';
    this.descripcion = '';
    this.clase = '';
    this.estudiantes = '';
    this.estudiantesorg = '';
    this.fechainicio = '';
    this.horainicio = '';
    this.fechafinal = '';
    this.horafinal = '';
    this.videoconferencia = '',
    this.emails=''

    this.evdata = this.eventosService
      .getEventoById(eventid)
      .subscribe((tevent) => {
    
        this.eventdata = tevent.payload.data();
  
        this.nombre = this.eventdata.nombre;
        this.descripcion = this.eventdata.descripcion;
        this.clase = this.eventdata.clase;
        this.estudiantes = this.eventdata.estudiantes;
        this.estudiantesorg = this.eventdata.estudiantes;
        this.fechainicio = this.eventdata.fechainicio;
        this.horainicio = this.eventdata.horainicio;
        this.fechafinal = this.eventdata.fechafinal;
        this.horafinal = this.eventdata.horafinal;
        this.videoconferencia =  this.eventdata.videoconferencia;
        if(this.videoconferencia !=null && this.videoconferencia!=''){
          this.videollamada = "si";
          this.emails=this.eventdata.emails
        }
        else{
          this.videollamada = "no";
        }
      });
  }

  selectclass(event) {
    this.clasesService.getClaseById(event).subscribe((clase) => {
      this.selectclase = clase.payload.data();
      this.selectuser = [];
      this.selectclase.usuarios.forEach((seclase) => {
        if (seclase != this.userUid) {
        this.userService.getUserById(seclase).subscribe((user) => {
          this.selectuser.push(user[0]);
        });
      }
      });
    });
  }

  selectinvitados(event) {
    this.clasesService.getClaseById(this.clase).subscribe((clase) => {
      this.selectclase = clase.payload.data();
      this.clasetoda = [];
      this.selectclase.usuarios.forEach((seclase) => {
        if (seclase != this.userUid) {
        this.userService.getUserById(seclase).subscribe((user) => {
          this.usuariosparaclase = user[0].payload.doc.data();
          this.clasetoda.push(this.usuariosparaclase.uid);
        });
      }
      });
    });
  }

  getFecha() {
    const current = new Date();
    current.setHours(current.getHours());
    current.setMinutes(current.getMinutes());
    current.setMonth(current.getMonth());
    current.setDate(current.getDate());
    return current;
  }


  createevent() {
    this.evdata.unsubscribe();

    if (this.nombre === '' || this.nombre === undefined) {
      this.notificacion = 'Debe tener un nombre';
      return;
    }

    if (
      (this.clase === '' || this.clase === undefined) &&
      this.emails.length == 0
    ) {
      this.notificacion = 'Debe escoger una clase';
      return;
    }
    if (
      (this.invitados === '' || this.invitados === undefined) &&
      this.emails.length == 0
    ) {
      this.notificacion = 'Debe escoger una opción de invitados';
      return;
    }
    if (this.estudiantes.length <= 0 && this.emails.length == 0) {
      this.notificacion = 'Debe escoger algun invitados';
      return;
    }

    if (this.fechainicio === '' || this.fechainicio === undefined) {
      this.notificacion = 'Debe tener una fecha de inicio';
      return;
    }

    if (this.horainicio === '' || this.horainicio === undefined) {
      this.notificacion = 'Debe tener una hora de inicio';
      return;
    }

    if (this.fechafinal === '' || this.fechafinal === undefined) {
      this.notificacion = 'Debe tener una fecha de finalización';
      return;
    }

    if (this.horafinal === '' || this.horafinal === undefined) {
      this.notificacion = 'Debe tener una hora de finalización';
      return;
    }

    if (this.fechafinal < this.fechainicio) {
      this.notificacion =
        'La fecha de inicio tiene que ser anterior a la fecha final';
 
      return;
    }

    if (
      this.fechainicio == this.fechafinal &&
      this.horafinal < this.horainicio
    ) {
      this.notificacion =
        'La hora de inicio tiene que ser anterior a la hora final si es el mismo dia';
 
      return;
    }

    var fechaActual = this.getFecha();
    var fechaFinalComprobación = new Date(
      this.fechafinal + ' ' + this.horafinal
    );
    if (fechaActual > fechaFinalComprobación) {
      this.notificacion = 'La fecha del evento no puede ser en el pasado';
    
      return;
    }


    if (this.descripcion === undefined) {
      this.descripcion = '';
    }

    if (this.videollamada == 'si') {
      this.linkvideo =
        'https://in.thecampus.education/webapp/#/?conference=' +
        this.userdata.usuario +
        '&role=guest&callType=video';
    }


    if (this.invitados === 'clase') {
      if (this.clasetoda.length > 0) {
        const evento = {
          nombre: this.nombre.toLowerCase(),
          descripcion: this.descripcion.toLowerCase(),
          clase: this.clase.toLowerCase(),
          estudiantes: this.clasetoda,
          fecha_inicio: new Date(this.fechainicio + ' ' + this.horainicio),
          fechainicio: this.fechainicio,
          horainicio: this.horainicio,
          fecha_final: new Date(this.fechafinal + ' ' + this.horafinal),
          fechafinal: this.fechafinal,
          horafinal: this.horafinal,
          videoconferencia:this.linkvideo,
          emails: this.emails,
         
        };

        var fechainicio = this.fechainicio.split('-').reverse().join('-');

        this.eventosService.editEvento(this.eventid, evento).then((evnt) => {
          const difference = this.clasetoda.filter(
            (x) => !this.estudiantesorg.includes(x)
          );

          if (difference.length > 0) {
            difference.forEach((estudiante) => {
              const notification = {
                user: estudiante,
                texto:
                  'Ha sido invitado al evento ' +
                  this.nombre +
                  '. El dia ' +
                  fechainicio +
                  ', hora: ' +
                  this.horainicio,
                url: '',
                verlo: estudiante,
                fecha: new Date(),
              };
              this.notificacionesService.createdNotificacion(notification);

              if (this.userUid !== estudiante) {
                var linkEmail: string;
                var textEmail:string;
                if (this.linkvideo != '') {
                  linkEmail = this.linkvideo;
                  textEmail= 'Ha sido invitado a la videoconferencia ' + this.nombre +'. El dia ' +fechainicio +', hora: ' +this.horainicio;
                } else {
                  linkEmail = 'https://thecampus.education/';
                  textEmail = 'Ha sido invitado al evento ' +this.nombre +'. El dia ' +fechainicio +', hora: ' +this.horainicio
                }
                // this.userService.getUserById(estudiante).subscribe((tuser) => {
                //   this.estudianteDatos = tuser[0].payload.doc.data();
                //   const callable = this.functions.httpsCallable('sendEmail');
                //   const obs = callable({
                //     email: this.estudianteDatos.email,
                //     asunto: 'Ha sido invitado a un evento en The Campus',
                //     title: 'Ha sido invitado a un evento',
                //     text: textEmail,
                //     link: linkEmail,
                //     linktext: 'Inicia sesión',
                //   });
  
                //   obs.subscribe(async (res) => {});
                // });
              }
            });
          }
          var linkEmail: string;
          var textEmail: string;
          var textboton: string;
          if (this.linkvideo != '') {
            linkEmail = this.linkvideo;
            textEmail =
              'Ha sido invitado a la videoconferencia ' +
              this.nombre +
              '. El dia ' +
              fechainicio +
              ', hora: ' +
              this.horainicio;
            textboton = 'Ir a la conferencia';
          } else {
            linkEmail = 'https://thecampus.education/';
            textEmail =
              'Ha sido invitado al evento ' +
              this.nombre +
              '. El dia ' +
              fechainicio +
              ', hora: ' +
              this.horainicio;
            textboton = 'Iniciar sesión';
          }
          this.emails.forEach((element) => {
            const callable = this.functions.httpsCallable('sendEmail');
            const obs = callable({
              email: element,
            asunto: 'Ha sido invitado a una videoconferencia',
            title: 'Ha sido invitado a una videoconferencia',
            text: textEmail,
            link: linkEmail,
            linktext: 'Ir a la videoconferencia',
            });
            obs.subscribe(async (res) => {});
          });


          this.geteventdata(this.eventid);

          this.notificacion = 'El evento ha sido editado';
          setTimeout(() => {
            this.notificacion = '';
          }, 2000);
        });
      }
    } else {
      //this.estudiantes.push(this.userUid);

      const evento = {
        nombre: this.nombre.toLowerCase(),
        descripcion: this.descripcion.toLowerCase(),
        clase: this.clase.toLowerCase(),
        estudiantes: this.estudiantes,
        fecha_inicio: new Date(this.fechainicio + ' ' + this.horainicio),
        fechainicio: this.fechainicio,
        horainicio: this.horainicio,
        fecha_final: new Date(this.fechafinal + ' ' + this.horafinal),
        fechafinal: this.fechafinal,
        horafinal: this.horafinal,
        videoconferencia: this.linkvideo,
        emails: this.emails,
   
      };

      var fechainicio = this.fechainicio.split('-').reverse().join('-');

      this.eventosService.editEvento(this.eventid, evento).then((evnt) => {
        const difference = this.estudiantes.filter(
          (x) => !this.estudiantesorg.includes(x)
        );

        if (difference.length > 0) {
          difference.forEach((estudiante) => {
            const notification = {
              user: estudiante,
              // tslint:disable-next-line: max-line-length
              texto:
                'Ha sido invitado al evento ' +
                this.nombre +
                '. El dia ' +
                fechainicio +
                ', hora: ' +
                this.horainicio,
              url: '',
              verlo: estudiante,
              fecha: new Date(),
            };
            this.notificacionesService.createdNotificacion(notification);

            if (this.userUid !== estudiante) {
              var linkEmail: string;
              var textEmail:string;
              var textboton: string;
              if (this.linkvideo != '') {
                linkEmail = this.linkvideo;
                textEmail= 'Ha sido invitado a la videoconferencia: ' + this.nombre +'. El dia ' +fechainicio +', hora: ' +this.horainicio;
                textboton = 'Ir a la conferencia';
              } else {
                linkEmail = 'https://thecampus.education/';
                textEmail = 'Ha sido invitado al evento: ' +this.nombre +'. El dia ' +fechainicio +', hora: ' +this.horainicio;
                textboton = 'Iniciar sesión';
              }
              // this.userService.getUserById(estudiante).subscribe((tuser) => {
              //   this.estudianteDatos = tuser[0].payload.doc.data();
              //   const callable = this.functions.httpsCallable('sendEmail');
              //   const obs = callable({
              //     email: this.estudianteDatos.email,
              //     asunto: 'Ha sido invitado a un evento en The Campus',
              //     title: 'Ha sido invitado a un evento',
              //     text: textEmail,
              //     link: linkEmail,
              //     linktext: textboton,
              //   });

              //   obs.subscribe(async (res) => {});
              // });
            }
          });
        }
        var linkEmail: string;
        var textEmail: string;
        var textboton: string;
        if (this.linkvideo != '') {
          linkEmail = this.linkvideo;
          textEmail =
            'Ha sido modificada la videoconferencia: ' +
            this.nombre +
            '. El dia ' +
            fechainicio +
            ', hora: ' +
            this.horainicio;
          textboton = 'Ir a la conferencia';
        } else {
          linkEmail = 'https://thecampus.education/';
          textEmail =
            'Ha sido modificado el evento: ' +
            this.nombre +
            '. El dia ' +
            fechainicio +
            ', hora: ' +
            this.horainicio;
          textboton = 'Iniciar sesión';
        }
        this.emails.forEach((element) => {
          const callable = this.functions.httpsCallable('sendEmail');
          const obs = callable({
            email: element,
            asunto: 'Ha sido invitado a una videoconferencia',
            title: 'Ha sido invitado a una videoconferencia',
            text: textEmail,
            link: linkEmail,
            linktext: 'Ir a la videoconferencia',
          });
          obs.subscribe(async (res) => {});
        });

        this.geteventdata(this.eventid);

        this.notificacion = 'El evento ha sido editado';
        setTimeout(() => {
          this.notificacion = '';
          this.router.navigate([
            '/dashboard/profile/' + this.userUid + '/miseventos/',
          ]);
        }, 2000);
      });
    }
  }

  getUserId(event, uid) {
  
    if (event.target.checked) {
    
      this.estudiantes.push(uid);
    } else {
     
      this.estudiantes = this.estudiantes.filter((m) => m != uid);
    }
  }
  addEmail() {
    if (this.validateEmail(this.email)) {
      if (!this.emails.includes(this.email)) {
        this.emails.push(this.email);
        this.email = '';
      }
    } else {
      this.formatoEmailMensaje = 'Formato de email no válido';
      setTimeout(() => {
        this.formatoEmailMensaje = '';
      }, 2000);
    }
  }
  quitarEmail(email) {
    var posicion = this.emails.indexOf(email);
    this.emails.splice(posicion, 1);
  }
  validateEmail(email) {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }
}
