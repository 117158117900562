<div id="containerAlertas">
  <div class="alertas">
    <header class="title-alert">
      <img src="assets/img/alertIcon.svg" />
      <h1>Mis alertas</h1>
    </header>

    <div class="infoContainer" *ngIf="infoU == true">
      <img src="{{ user.avatar }}" />
      <div id="info">
        <h3>{{ user.nombres | titlecase }}</h3>
        <h3>{{ user.email }}</h3>
       
      </div>
      <div id="icons">
        <img (click)="boxInfoUser(user.uid)" src="assets/img/close.svg" />
        <img routerLink="/dashboard/mensajes/chat/{{chat}} "src="assets/img/msgIcon.svg" />
      </div>
    </div>

    <div id="dataTitle">
      <h2>Nombre</h2>
      <h2>Fecha</h2>
      <h2>Id</h2>
    </div>
    <div
      [ngClass]="{
        dataContainerSmall: infoU === true,
        dataContainer: infoU == false
      }"
    >
      <app-alertabox
        (valueResponse)="quitarAlertas($event)"
        (infoUser)="boxInfoUser($event)"
        *ngFor="let alertaDato of alertas"
        [alertaEntera]="alertaDato"
      >
      </app-alertabox>
    </div>
  </div>
</div>
