import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../services/authentication.service';

@Component({
  selector: 'app-olvidarpass',
  templateUrl: './olvidarpass.component.html',
  styleUrls: ['./olvidarpass.component.css']
})
export class OlvidarpassComponent implements OnInit {
  email: string;
  response: string;

  constructor(private authenticationService: AuthenticationService) { }

  ngOnInit(): void {
  }

  enviar() {
    if (this.email === null || this.email === '' || !this.validateEmail(this.email)) {
      this.response = 'Debe escribir un e-mail';
      return;
    }

    this.authenticationService.resetemail(this.email).then((ok: any) => {
      this.response = 'Se ha enviado el link a su mail';
    }).catch((error: any) => {
        if(error.code === 'auth/user-not-found') {
          this.response = 'No existe un usuario relacionado con ese email';
        } else {
          this.response = 'Ha ocurrido un error';
        }
    });
  }

  validateEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

}
