<!-- mensajes -->
<div class="archivos">
  <section class="basicbox clase">
    <header>
      <div class="wrapicon">
        <img src="/assets/img/folder-b.svg" alt="" />
      </div>
      <div>
        <h3 *ngIf="urluid == 'user'; else defaultitle">Mis Archivos</h3>
        <ng-template #defaultitle>
          <h3>Archivos de {{ clasenombre | titlecase }}</h3>
        </ng-template>
      </div>
    </header>

    <div class="Actions">
      <div class="grupo1">
        <div class="searchfile">
          <input
            type="text"
            placeholder="Buscar archivos "
            (keyup)="search()"
            [(ngModel)]="searchValue"
          />
          <a id="buttonArchivo">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20.578"
              height="20.6"
              viewBox="0 0 20.578 20.6"
            >
              <path
                id="Trazado_9282"
                data-name="Trazado 9282"
                d="M30.993,29.611,27.185,25.8a9.091,9.091,0,1,0-1.457,1.482l3.808,3.808a1.009,1.009,0,0,0,.716.307.928.928,0,0,0,.716-.307A1.074,1.074,0,0,0,30.993,29.611Zm-3.834-9.584a7.156,7.156,0,1,1-2.1-5.086A7.152,7.152,0,0,1,27.159,20.027Z"
                transform="translate(-10.7 -10.8)"
                fill="#fff"
              />
            </svg>
          </a>
        </div>

        <div class="breadcrumfile">
          <ng-container *ngFor="let bread of this.breadcrumb; let i = index">
            <ng-container *ngIf="i == 0">
              <a (click)="returnhome()"> {{ bread.name }} / </a>
            </ng-container>
            <ng-container *ngIf="i != 0">
              <a (click)="gofolder(i, bread.uid)"> {{ bread.name }} / </a>
            </ng-container>
          </ng-container>
        </div>
      </div>

      <div class="grupo2">
        <div class="buttons">
          <div class="subir">
            <ng-container *ngIf="espacioocupado >= 0">
              <ng-container *ngIf="espacioocupado < maxspace">
                <div (click)="file.click()">
                  <input
                    [hidden]="true"
                    type="file"
                    (change)="uploadfile($event)"
                    #file
                  />
                  <a class="button" id="buttonArchivo"> Subir Archivo </a>
                </div>
              </ng-container>
            </ng-container>
          </div>
          <div class="createfoldercol">
            <div class="createfolder">
              <input
                type="text"
                placeholder="Nombre de la carpeta"
                [(ngModel)]="foldername"
              />
              <a (click)="createFolder()" id="buttonArchivo">
                <img src="/assets/img/plusicon.svg" />
              </a>
            </div>
          </div>
        </div>
        <div class="ocupado">
          <div>
            <progress
              value="{{ espacioocupado }}"
              max="{{ maxspace }}"
            ></progress>
          </div>
          <div>
            <p class="maxfile">
              <br />
              Ocupados <b>{{ espacioocupado / 1024 / 1024 | number: "1.2-2" }}</b> mb de
              1000 mb<br />
              <ng-container *ngIf="porcentaje!=null">
               Subiendo: {{ porcentaje }} %
              </ng-container>
              
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="filestable">
      <div class="dataTitle">
        <div class="order">
          <img src="/assets/img/sort.svg" alt="order" (click)="ordenarPorNombre()" title="Ordenar por Nombre"/>
          <p>Nombre</p>
        </div>
        <div>
          <p>Tamaño</p>
        </div>
        <div class="order2">
          <img src="/assets/img/sort.svg" alt="order" (click)="ordenarPorFecha()" title="Ordenar por Fecha"/>
          <p >Fecha y Hora</p>
        </div>
        <div>
          <p>Compartido por</p>
        </div>
      </div>
      <div class="tablecontent">
        <ng-container
          *ngFor="let archivo of this.losarchivos | search: searchValue ; let i = index"
        >
          <ng-container *ngIf="archivo.filedata.folder">
            <!-- file item -->
            <div class="filename">
              <div
                class="delete"
                *ngIf="
                  archivo.filedata.propietario == currentuserid;
                  else elseboxfolder
                "
                id="buttonArchivo"
                (click)="deletefolder(archivo.uid)"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="11.513"
                  height="8.063"
                  viewBox="0 0 11.513 8.063"
                >
                  <g
                    id="Grupo_9481"
                    data-name="Grupo 9481"
                    transform="translate(-5.602 -5.601)"
                  >
                    <path
                      id="Trazado_9279"
                      data-name="Trazado 9279"
                      d="M17.115,9.633Z"
                      fill="#2D3453"
                    />
                    <path
                      id="Trazado_9280"
                      data-name="Trazado 9280"
                      d="M16.889,9.164a.575.575,0,0,0-.814,0l0,0-3.045,3.05L9.982,9.164a.578.578,0,0,0-.817.817l3.05,3.045-3.05,3.045a.578.578,0,0,0,.817.817l3.045-3.05,3.045,3.05a.578.578,0,0,0,.817-.817l-3.05-3.045,3.05-3.045a.575.575,0,0,0,0-.814Z"
                      transform="translate(-3.394 -3.393)"
                      fill="#2D3453"
                    />
                  </g>
                </svg>
              </div>
              <ng-template #elseboxfolder>
                <div class="nodelete"></div>
              </ng-template>
              <div (click)="changefolder(archivo.uid, archivo.filedata.nombre)">
                <img src="/assets/img/file-folder.svg" alt="" />
              </div>
              <div    id="buttonArchivo" (click)="changefolder(archivo.uid, archivo.filedata.nombre)">
                <p>{{ archivo.filedata.nombre }}</p>
              </div>
            </div>
            <div>
              <p></p>
            </div>
            <div>
              <p>{{ archivo.filedata.fecha.toDate() | date: "MMM d, y" }}</p>
            </div>
            <div class="userowner">
              <div>
                <p>{{ archivo.propietario | titlecase }}</p>
              </div>
              <div>
                <img
                  *ngIf="archivo?.avatar; else defaultheadavatar"
                  src="{{ archivo?.avatar }}"
                  alt="{{ archivo?.propietario | titlecase }}"
                />
                <ng-template #defaultheadavatar>
                  <img
                    src="assets/img/defaultavatar.jpg"
                    alt="{{ archivo?.propietario | titlecase }}"
                  />
                </ng-template>
              </div>
            </div>
            <!-- file item -->
          </ng-container>

          <ng-container *ngIf="!archivo.filedata.folder">
            <!-- file item -->
            <div class="filename">
              <div
                class="delete"
                *ngIf="
                  archivo.filedata.propietario == currentuserid;
                  else elsebox
                "
                id="buttonArchivo"
                (click)="deletefile(archivo.filedata.url, archivo.uid)"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="11.513"
                  height="8.063"
                  viewBox="0 0 11.513 8.063"
                >
                  <g
                    id="Grupo_9481"
                    data-name="Grupo 9481"
                    transform="translate(-5.602 -5.601)"
                  >
                    <path
                      id="Trazado_9279"
                      data-name="Trazado 9279"
                      d="M17.115,9.633Z"
                      fill="#2D3453"
                    />
                    <path
                      id="Trazado_9280"
                      data-name="Trazado 9280"
                      d="M16.889,9.164a.575.575,0,0,0-.814,0l0,0-3.045,3.05L9.982,9.164a.578.578,0,0,0-.817.817l3.05,3.045-3.05,3.045a.578.578,0,0,0,.817.817l3.045-3.05,3.045,3.05a.578.578,0,0,0,.817-.817l-3.05-3.045,3.05-3.045a.575.575,0,0,0,0-.814Z"
                      transform="translate(-3.394 -3.393)"
                      fill="#2D3453"
                    />
                  </g>
                </svg>
              </div>

              <ng-template #elsebox>
                <div class="nodelete"></div>
              </ng-template>
              <div>
                <a href="{{ archivo.filedata.url }}" target="_blank">
                  <img
                    *ngIf="checkimage(archivo.filedata.nombre) === 'image'"
                    src="{{ archivo.filedata.url }}"
                  />
                  <img
                    *ngIf="checkimage(archivo.filedata.nombre) === 'video'"
                    src="/assets/img/file-video.svg"
                  />
                  <img
                    *ngIf="checkimage(archivo.filedata.nombre) === 'doc'"
                    src="/assets/img/file-document.svg"
                  />
                </a>
              </div>
              <div>
                <p>
                  <a href="{{ archivo.filedata.url }}" target="_blank">{{
                    archivo.filedata.nombre
                  }}</a>
                </p>
              </div>
            </div>
            <div>
              <p>
                {{ archivo.filedata.size / 1024 / 1024 | number: "1.2-2" }} Mb
              </p>
            </div>
            <div>
              <p>{{ archivo.filedata.fecha.toDate() | date: "MMM d, y" }}</p>
            </div>
            <div class="userowner">
              <div>
                <p>{{ archivo.propietario | titlecase }}</p>
              </div>
              <div>
                <img
                  *ngIf="archivo?.avatar; else defaultheadavatar"
                  src="{{ archivo?.avatar }}"
                  alt="{{ archivo?.propietario | titlecase }}"
                />
                <ng-template #defaultheadavatar>
                  <img
                    src="assets/img/defaultavatar.jpg"
                    alt="{{ archivo?.propietario | titlecase }}"
                  />
                </ng-template>
              </div>
            </div>

            <!-- file item -->
          </ng-container>
        </ng-container>
      </div>
    </div>
  </section>
</div>
<!-- /mensajes -->
