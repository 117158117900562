import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dispositivos',
  templateUrl: './dispositivos.component.html',
  styleUrls: ['./dispositivos.component.css'],
})
export class DispositivosComponent implements OnInit {
  camaras: any[];
  micros: any[];
  audios: any[];
  n: number = 0;
  apagado: boolean = false;
  deviceId: any;

  constructor() {}

  ngOnInit(): void {
 
    this.videoMedias().then(() =>{
      this.selectCamera(this.camaras[this.n].deviceId)
      this.selectAudio(this.audios[0].deviceId)
      this.selectAudio(this.micros[0].deviceId)
    }
    );
    
    
  }
  //////CÁMARA/////

  changeCamera() {
    if (this.n === this.camaras.length - 1) {
    
      this.videoMedias().then(() =>{
        this.selectCamera(this.camaras[this.n].deviceId)
        this.deviceId=this.camaras[this.n].deviceId;
        this.n = 0;
      }
      );
     
    } else {
    
        this.videoMedias().then(() =>{
          this.selectCamera(this.camaras[this.n].deviceId)
          this.deviceId=this.camaras[this.n].deviceId;
          this.n++;
        }
        );
    }
    this.apagado=false;
}
  async videoMedias() {
    const stream = await window.navigator.mediaDevices
      .enumerateDevices()
      .then((videoMedia) => {
        const todos = [];
        const camaraList = [];
        const audioList = [];
        const microList = [];
        videoMedia.forEach((dispositivo) => {
          const type = dispositivo.kind;
          todos.push(dispositivo);
          if (type === 'videoinput') {
            camaraList.push(dispositivo);
          }
          if (type === 'audioinput') {
            microList.push(dispositivo);
          }
          if (type === 'audiooutput') {
            audioList.push(dispositivo);
          }
        });
        this.camaras = camaraList;
        this.audios = audioList;
        this.micros = microList;
      });
  }
  async selectCamera(id) {
    var constraints = {
      deviceId: id,
      video: true,
      audio: true,
      width: { ideal: 1080 },
    };
    const video = await window.navigator.mediaDevices
      .getUserMedia({ video: constraints })
      .then((mediastream) => {
        const tracks = mediastream.getTracks();
        document.querySelector('video').srcObject = mediastream;
        document.querySelector('video').play();
       
      })
      .catch(function (err) {});
  }

  async apagar() {
    this.eleccion();
  }
  eleccion(){
    if(this.apagado==true){
      this.encenderCamara().then(()=>{
        this.apagado=false
        localStorage.setItem("apagado", "false" );
      })
    }
    else{
      this.apagarCamara().then(()=>{
        this.apagado=true
        localStorage.setItem("apagado", "true" );
      })
    }
  }

  async apagarCamara() {
    var constraints = {
      video: false,
      audio: false,
      width: { ideal: 2160 },
    };
    console.log('apagando camara');
    await window.navigator.mediaDevices
      .getUserMedia({ video: constraints, audio: true })
      .then((mediastream) => {
        document.querySelector('video').srcObject = mediastream;
        const tracks = mediastream.getTracks();
        tracks.forEach(function (track) {
          track.stop();
        });
      });
  }
  async encenderCamara() {
    console.log('encendiendo camara');
    this.selectCamera(this.deviceId);
  }

  async selectAudio(id) {
    var constraints = { deviceId: id, audio: true };
    const audio = await window.navigator.mediaDevices
      .getUserMedia({ audio: constraints })
      .then((mediastream) => {
        const tracks = mediastream.getAudioTracks();
      });
  }
}
