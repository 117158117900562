import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class CursosService {

  constructor(private firestore: AngularFirestore) { }

  getCursos() {
    return this.firestore.collection('cursos').snapshotChanges();
  }

  getCursosNow() {
    return this.firestore.collection('cursos').get();
  }

  getCursosById(uid) {
    return this.firestore.collection('cursos').doc(uid).snapshotChanges();
  }

  createdCurso(curso) {
    return this.firestore.collection('cursos').add(curso);
  }

  editCurso(uid, curso) {
    return this.firestore.collection('cursos').doc(uid).update(curso);
  }

  getCursosByColegioid(uid) {
    return this.firestore.collection('cursos', ref => ref.where('colegios', 'array-contains' , uid)).snapshotChanges();
  }
  getCursosByColegioidNow(uid) {
    return this.firestore.collection('cursos', ref => ref.where('colegios', 'array-contains' , uid)).get();
  }

  getCursosByClassid(classuid) {
    return this.firestore.collection('cursos', ref => ref.where('clases', 'array-contains' , classuid)).snapshotChanges();
  }
  getCursosByClassidNow(classuid) {
    return this.firestore.collection('cursos', ref => ref.where('clases', 'array-contains' , classuid)).get();
  }
  deleteCurso(uid){
    return this.firestore.collection('cursos').doc(uid).delete();
  }
}
