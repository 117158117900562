import { PasswordresetComponent } from './auth_handlers_page/passwordreset.component';
import { SoporteComponent } from './soporte/soporte.component';
import { OlvidarpassComponent } from './olvidarpass/olvidarpass.component';
import { RegistroComponent } from './registro/registro.component';
import { DashboardModule } from './dashboard/dashboard.module';
import { LoginComponent } from './login/login.component';
import { AdminModule } from './admin/admin.module';;
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthenticationGuard } from './services/authentication.guard';
import { RegistroAcademicaComponent } from './registro-academica/registro-academica.component';
import { RedirectComponent } from './redirect/redirect.component';

const routes: Routes = [
  {
    path: '', component: LoginComponent
  },
  {
    path: 'login', component: LoginComponent
  },
  {
    path: 'admin',
    loadChildren: () => AdminModule,
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'dashboard',
    loadChildren: () => DashboardModule,
    canActivate: [AuthenticationGuard],
    data: {
      rol: 'admin'
    }
  },
  {
    path: 'registro', component: RegistroComponent
  },
  {
    path: 'registroAcademica', component: RegistroAcademicaComponent
  },
  {
    path: 'olvidar', component: OlvidarpassComponent
  },
  {
    path: 'soporte', component: SoporteComponent
  },
  {
    path: 'auth', component: PasswordresetComponent
  },
  {path: ':sala', component: RedirectComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
