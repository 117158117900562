import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../services/user.service';
import { AuthenticationService } from '../../../services/authentication.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-ajustes',
  templateUrl: './ajustes.component.html',
  styleUrls: ['./ajustes.component.css']
})
export class AjustesComponent implements OnInit {
  user: any;
  nombres: string;
  apellidos: string;
  email: string;
  codigo: string;
  telefono: string;
  fechanac: Date;
  genero: string;
  pais: string;
  avatar: string;
  uid: string;
  uid_in_userdatabase: any;
  saveresponse: string;
  currentuid: string;

  alerta:boolean=false;

  constructor(private userService: UserService,
              private authenticationService: AuthenticationService,
              private datepipe:DatePipe) {
    }


  ngOnInit(): void {

    const Userdata: any = this.userService.getUserinfodata();

    if (Userdata === undefined) {

      this.authenticationService.getStatus().subscribe((status) => {
        this.currentuid = status.uid;

        this.userService.getUserById(this.currentuid).subscribe((data) => {
          if(data[0]) {
          this.user = data[0].payload.doc.data();
          this.uid_in_userdatabase = data[0].payload.doc.id;
          this.uid = this.currentuid;
          this.nombres = this.user.nombres[0].toUpperCase()+this.user.nombres.substr(1).toLowerCase();
          this.apellidos = this.user.apellidos[0].toUpperCase()+this.user.apellidos.substr(1).toLowerCase();
          this.email = this.user.email;
          this.codigo = this.user.codigo;
          this.telefono = this.user.telefono;
          this.fechanac = this.user.fechanac ;
          this.genero = this.user.genero[0].toUpperCase()+this.user.genero.substr(1).toLowerCase();
          this.pais = this.user.pais[0].toUpperCase()+this.user.pais.substr(1).toLowerCase();
          this.avatar = this.user.avatar;
          }
        }, (error) => {
          console.log(error);
        });

      });

    } else {
      this.currentuid = Userdata.payload.doc.data().uid;

      this.user = Userdata.payload.doc.data();
      this.uid_in_userdatabase = Userdata.payload.doc.id;
      this.uid = this.currentuid;
      this.nombres = this.user.nombres[0].toUpperCase()+this.user.nombres.substr(1).toLowerCase();
      this.apellidos = this.user.apellidos[0].toUpperCase()+this.user.apellidos.substr(1).toLowerCase();
      this.email = this.user.email;
      this.codigo = this.user.codigo;
      this.telefono = this.user.telefono;
      this.fechanac = this.user.fechanac;
      this.genero = this.user.genero[0].toUpperCase()+this.user.genero.substr(1).toLowerCase();
      this.pais = this.user.pais[0].toUpperCase()+this.user.pais.substr(1).toLowerCase();
      this.avatar = this.user.avatar;

    }

  }
  saveSettings() {
    const theuser = {
      uid: this.uid,
      codigo: this.codigo.toLowerCase(),
      telefono: this.telefono.toLowerCase(),
      fechanac: new Date(this.fechanac + ' 00:00'),
      genero: this.genero.toLowerCase(),
      pais: this.pais.toLowerCase()
    };
    this.userService.editUser(theuser, this.uid_in_userdatabase).then((update) => {
      this.saveresponse = 'Cambios guardados';
      this.alerta=true;
      setTimeout(() => {
        this.saveresponse = '';
        this.alerta=false;
     }, 2200);
    }).catch((error) =>  {
      this.saveresponse = 'Ha ocurrido un error';
      setTimeout(() => {this.saveresponse = ''; }, 2200);
    });
  }

}
