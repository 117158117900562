import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-misala',
  templateUrl: './misala.component.html',
  styleUrls: ['./misala.component.css']
})
export class MisalaComponent implements OnInit {
  userUid: any;
  copymsg:boolean=false;
  email:any;

  constructor(private breadService:BreadcrumbService,
    private activatedRoute: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private userService: UserService) { }

  ngOnInit(): void {
    const Userdata: any = this.userService.getUserinfodata();

    if (Userdata === undefined) {
      this.authenticationService.isAuth().subscribe((auth) => {
        if (auth) {
          this.userUid = auth.uid;
          this.userService.getUserByIdNow(this.userUid).subscribe((data)=>{
            data.forEach((user)=>{
                this.email=user.data().email
            })
          })
        }
      })
    }
    else{
      this.userUid = Userdata.payload.doc.data().uid;
      this.email = Userdata.payload.doc.data().email;
    }
  }

  UpdateBreadcrumb(name, url) {
    this.breadService.addtobread(name, url);
  }
  copy(){
    let url = "https://thecampus.education/" + this.email;
    navigator.clipboard.writeText(url)

    this.copymsg=true;
    setTimeout(() => {
      this.copymsg=false;
    },1800);

  }

}
