import { ClasesService } from './../../services/clases.service';
import { ArchivosService } from './../../services/archivos.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../../services/authentication.service';
import { AngularFireStorage } from '@angular/fire/storage';
import { UserService } from './../../services/user.service';

@Component({
  selector: 'app-archivos',
  templateUrl: './archivos.component.html',
  styleUrls: ['./archivos.component.css'],
})
export class ArchivosComponent implements OnInit {
  currentuserid: string;
  fileupload: any;
  urluid: string;
  foldername: string;
  porcentaje: number;
  thefile: any;
  currentfolder = 'root';
  losarchivos = [];
  breadcrumb = [{ name: 'inicio', uid: 0 }];
  espacioocupado: number;
  maxspace = 1048576000.0; //1000mb
  propietario: any;
  updateroot = true;
  classuid: string;
  getfilesid: string;
  searchValue: string;
  clasenombre: string;
  clase: any;

  rol: string;

  orderbyname: boolean = false;
  orderbydate: boolean = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private angularfireStorage: AngularFireStorage,
    private archivosService: ArchivosService,
    private clasesservice: ClasesService,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      this.urluid = params['uid'];

      if (this.urluid === 'user') {
        const Userdata: any = this.userService.getUserinfodata();
        if (Userdata === undefined) {
          this.authenticationService.getStatus().subscribe(
            (status) => {
              this.currentuserid = status.uid;
              this.getfilesid = this.currentuserid;
              this.classuid = '';
              this.updateroot = true;
              this.getarchivos();
            },
            (error) => {
              console.log(error);
            }
          );
        } else {
          this.currentuserid = Userdata.payload.doc.data().uid;

          this.getfilesid = this.currentuserid;
          this.classuid = '';
          this.updateroot = true;
          this.getarchivos();
        }
      } else {
        this.authenticationService.getStatus().subscribe((status) => {
          this.currentuserid = status.uid;
          this.userService.getUserById(this.currentuserid).subscribe((user) => {
            var use = user[0].payload.doc.data();
            this.rol = use['rol'];
          });
        });

        this.classuid = this.urluid;
        this.getfilesid = this.urluid;
        this.updateroot = true;

        this.archivosService
          .getGroupArchivos(this.getfilesid)
          .subscribe((files) => {
            this.espacioocupado = 0;

            files.forEach((fileData: any) => {
              if (!fileData.payload.doc.data().folder) {
                this.espacioocupado =
                  this.espacioocupado + fileData.payload.doc.data().size;
              }
            });
          });

        this.clasesservice
          .getClaseById(this.classuid)
          .subscribe((infoclase) => {
            const clasedata: any = infoclase.payload.data();
            this.clase = infoclase;
            this.clasenombre = clasedata.nombre;
          });
        this.authenticationService.getStatus().subscribe(
          (status) => {
            this.currentuserid = status.uid;
            this.getarchivos();
          },
          (error) => {
            console.log(error);
          }
        );
      }
    });
  }

  uploadfile(event) {
    const fileSize = event.target.files[0].size;
    if (this.espacioocupado + fileSize < this.maxspace) {
      if (event) {
        const file = event.target.files[0];
        const filePath =
          this.getfilesid +
          '/' +
          Date.now() +
          file.name.replace(/\s/g, '').replace(/[^a-zA-Z0-9-.]/g, '');
        const fileRef = this.angularfireStorage.ref(filePath);
        const taks = this.angularfireStorage.upload(filePath, file);
        taks.percentageChanges().subscribe((porcentaje) => {
          this.porcentaje = Math.round(porcentaje);
        });

        taks.then((fileuploaded) => {
          this.thefile = this.angularfireStorage.ref(filePath).getDownloadURL();
          this.porcentaje = null;
          this.thefile.subscribe((p) => {
            let archivo = {};
            if (this.classuid) {
              archivo = {
                url: p,
                nombre: file.name,
                propietario: this.currentuserid,
                grupo: [this.classuid],
                folder: false,
                parent: this.currentfolder,
                size: file.size,
                fecha: new Date(),
              };
            } else {
              archivo = {
                url: p,
                nombre: file.name,
                propietario: this.currentuserid,
                grupo: [],
                folder: false,
                parent: this.currentfolder,
                size: file.size,
                fecha: new Date(),
              };
            }
            this.archivosService
              .createdArchivo(archivo)
              .then((filelist) => {
                setTimeout(() => {
                  this.getarchivos();
                }, 500);
              })
              .catch((error) => {
                console.log(error);
              });
          });
        });
      }
    } else {
      console.log(
        'No se pudo subir el archivo porque es más grande que el espacio disponible'
      );
    }
  }

  createFolder() {
    if (this.foldername != '') {
      let folder = {};
      if (this.classuid) {
        folder = {
          nombre: this.foldername,
          propietario: this.currentuserid,
          grupo: [this.classuid],
          folder: true,
          parent: this.currentfolder,
          fecha: new Date(),
        };
      } else {
        folder = {
          nombre: this.foldername,
          propietario: this.currentuserid,
          grupo: [],
          folder: true,
          parent: this.currentfolder,
          fecha: new Date(),
        };
      }
      this.foldername = '';
      this.archivosService
        .createdArchivo(folder)
        .then((filelist) => {
          setTimeout(() => {
            this.getarchivos();
          }, 500);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  getarchivos() {
    if (this.classuid) {
      this.archivosService
        .getGroupArchivos(this.getfilesid)
        .subscribe((files) => {
          // this.espacioocupado = 0;
          if (this.updateroot) {
            this.losarchivos = [];
          }
          if (this.rol == 'estudiante') {
            files.forEach((fileData: any) => {
              this.userService
                .getUserById(fileData.payload.doc.data().propietario)
                .subscribe((user) => {
                  this.propietario = user[0].payload.doc.data();
                  if (this.updateroot) {
                    if (
                      !this.losarchivos.some(
                        (el) => el.uid === fileData.payload.doc.id
                      )
                    ) {
                      if (
                        fileData.payload.doc.data().parent === 'root' &&
                        (fileData.payload.doc.data().propietario ==
                          this.currentuserid ||
                          this.clase.payload
                            .data()
                            .administradores.includes(
                              fileData.payload.doc.data().propietario
                            ))
                      ) {
                        this.losarchivos.push({
                          filedata: fileData.payload.doc.data(),
                          uid: fileData.payload.doc.id,
                          propietario:
                            this.propietario.nombres +
                            ' ' +
                            this.propietario.apellidos,
                          avatar: this.propietario.avatar,
                        });
                      }
                    }
                  }
                  // if (!fileData.payload.doc.data().folder) {
                  //   this.espacioocupado =
                  //     this.espacioocupado + fileData.payload.doc.data().size;
                  // }
                });
            });
          } else {
            files.forEach((fileData: any) => {
              this.userService
                .getUserById(fileData.payload.doc.data().propietario)
                .subscribe((user) => {
                  this.propietario = user[0].payload.doc.data();
                  if (this.updateroot) {
                    if (
                      !this.losarchivos.some(
                        (el) => el.uid === fileData.payload.doc.id
                      )
                    ) {
                      if (fileData.payload.doc.data().parent === 'root') {
                        this.losarchivos.push({
                          filedata: fileData.payload.doc.data(),
                          uid: fileData.payload.doc.id,
                          propietario:
                            this.propietario.nombres +
                            ' ' +
                            this.propietario.apellidos,
                          avatar: this.propietario.avatar,
                        });
                      }
                    }
                  }
                  // if (!fileData.payload.doc.data().folder) {
                  //   this.espacioocupado =
                  //     this.espacioocupado + fileData.payload.doc.data().size;
                  // }
                });
            });
          }
        });
    } else {
      this.archivosService.getArchivos(this.getfilesid).subscribe((files) => {
        this.espacioocupado = 0;
        if (this.updateroot) {
          this.losarchivos = [];
        }
        files.forEach((fileData: any) => {
          this.userService
            .getUserById(fileData.payload.doc.data().propietario)
            .subscribe((user) => {
              this.propietario = user[0].payload.doc.data();
              if (this.updateroot) {
                if (
                  !this.losarchivos.some(
                    (el) => el.uid === fileData.payload.doc.id
                  )
                ) {
                  if (fileData.payload.doc.data().parent === 'root') {
                    this.losarchivos.push({
                      filedata: fileData.payload.doc.data(),
                      uid: fileData.payload.doc.id,
                      propietario:
                        this.propietario.nombres +
                        ' ' +
                        this.propietario.apellidos,
                      avatar: this.propietario.avatar,
                    });
                  }
                }
              }

              if (!fileData.payload.doc.data().folder) {
                this.espacioocupado =
                  this.espacioocupado + fileData.payload.doc.data().size;
              }
            });
        });
      });
    }
  }

  changefolder(uid, name) {
    this.archivosService.getArchivoparents(uid).subscribe((files) => {
      this.losarchivos = [];

      files.forEach((fileData: any) => {
        if (fileData.payload.doc.data().parent === uid) {
          this.userService
            .getUserById(fileData.payload.doc.data().propietario)
            .subscribe((user) => {
              this.propietario = user[0].payload.doc.data();
              if (
                !this.losarchivos.some(
                  (el) => el.uid === fileData.payload.doc.id
                )
              ) {
                this.losarchivos.push({
                  filedata: fileData.payload.doc.data(),
                  uid: fileData.payload.doc.id,
                  propietario:
                    this.propietario.nombres + ' ' + this.propietario.apellidos,
                  avatar: this.propietario.avatar,
                });
              }
            });
        }
      });

      if (!this.breadcrumb.some((el) => el.name === name)) {
        this.breadcrumb.push({ name: name, uid: uid });
      }
      this.currentfolder = uid;
      this.updateroot = false;
    });
  }

  deletefile(path, uid) {
    this.angularfireStorage.storage
      .refFromURL(path)
      .delete()
      .then((filedel) => {
        this.archivosService.deletefile(uid).then((delfiledoc) => {
          setTimeout(() => {
            this.getarchivos();
          }, 500);
        });
      });
  }

  deletefolder(uid) {
    this.archivosService.getArchivoparents(uid).subscribe((files) => {
      files.forEach((fileData: any) => {
        if (fileData.payload.doc.data().folder) {
          this.deletefolder(fileData.payload.doc.id);
        } else {
          this.deletefile(
            fileData.payload.doc.data().url,
            fileData.payload.doc.id
          );
        }
      });

      this.archivosService.deletefile(uid).then((delfiledoc) => {
        setTimeout(() => {
          this.getarchivos();
        }, 500);
      });
    });
  }

  returnhome() {
    this.breadcrumb = [{ name: 'inicio', uid: 0 }];
    this.updateroot = true;
    this.getarchivos();
    this.currentfolder = 'root';
  }

  gofolder(i, uid) {
    this.breadcrumb.length = i + 1;
    this.archivosService.getArchivoparents(uid).subscribe((files) => {
      this.losarchivos = [];
      files.forEach((fileData: any) => {
        this.losarchivos.push({
          filedata: fileData.payload.doc.data(),
          uid: fileData.payload.doc.id,
        });
      });
      this.currentfolder = uid;
    });
  }

  search() {
    if (!this.searchValue) {
      this.getarchivos();
      return;
    }
    if (this.classuid) {
      this.archivosService
        .getGroupArchivos(this.getfilesid)
        .subscribe((files) => {
          files.forEach((fileData: any) => {
            this.userService
              .getUserById(fileData.payload.doc.data().propietario)
              .subscribe((user) => {
                this.propietario = user[0].payload.doc.data();

                if (this.searchValue) {
                  if (
                    !this.losarchivos.some(
                      (el) => el.uid === fileData.payload.doc.id
                    )
                  ) {
                    this.losarchivos.push({
                      filedata: fileData.payload.doc.data(),
                      uid: fileData.payload.doc.id,
                      propietario:
                        this.propietario.nombres +
                        ' ' +
                        this.propietario.apellidos,
                      avatar: this.propietario.avatar,
                    });
                  }
                }
              });
          });
        });
    } else {
      this.archivosService.getArchivos(this.getfilesid).subscribe((files) => {
        files.forEach((fileData: any) => {
          this.userService
            .getUserById(fileData.payload.doc.data().propietario)
            .subscribe((user) => {
              this.propietario = user[0].payload.doc.data();
              if (this.searchValue) {
                if (
                  !this.losarchivos.some(
                    (el) => el.uid === fileData.payload.doc.id
                  )
                ) {
                  this.losarchivos.push({
                    filedata: fileData.payload.doc.data(),
                    uid: fileData.payload.doc.id,
                    propietario:
                      this.propietario.nombres +
                      ' ' +
                      this.propietario.apellidos,
                    avatar: this.propietario.avatar,
                  });
                }
              }
            });
        });
      });
    }
  }

  checkimage(url) {
    let rutype = '';
    if (url.match(/\.(jpeg|jpg|gif|png)$/) != null) {
      rutype = 'image';
    } else if (
      url.match(/\.(mp4|3gp|mov|avi|wmv|flv|m3u8|ts|3g2|mpeg|ogv|webm)$/) !=
      null
    ) {
      rutype = 'video';
    } else {
      rutype = 'doc';
    }
    return rutype;
  }

  ordenarPorNombre() {
    this.orderbyname=!this.orderbyname;
    if(this.orderbyname){
      this.ordenarDes('nombre')
    }
    else{
      this.ordenarAsc('nombre')
    }
    
  }
  ordenarPorFecha() {
    this.orderbydate=!this.orderbydate;
    if(this.orderbydate){
      this.ordenarDes('fecha')
    }
    else{
      this.ordenarAsc('fecha')
    }
    
  }

  ordenarDes(item){
    this.losarchivos.sort(function (a, b) {
      if (a.filedata[item] < b.filedata[item]) {
        return -1;
      } else if (a.filedata[item] > b.filedata[item]) {
        return 1;
      } else {
        return 0;
      }
    });
  }

  ordenarAsc(item){
    this.losarchivos.sort(function (a, b) {
      if (a.filedata[item] > b.filedata[item]) {
        return -1;
      } else if (a.filedata[item] < b.filedata[item]) {
        return 1;
      } else {
        return 0;
      }
    });
  }
}
