import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class EventosService {

  constructor(private firestore: AngularFirestore) { }

  getEventos() {
    return this.firestore.collection('eventos').snapshotChanges();
  }

  getEventosByUserid(uid) {
    return this.firestore.collection('eventos', ref => ref.where('creado', '==' , uid)).snapshotChanges();
  }

  getEventosByinvited(uid) {
    return this.firestore.collection('eventos', ref => ref.where('estudiantes', 'array-contains' , uid )).snapshotChanges();
  }
  getConfirmadoAsistir(uid, eventuid) {
    return this.firestore.collection('eventos', ref => ref.where('asistentes', 'array-contains' , uid )).snapshotChanges();
  }
  getConfirmadoNoAsistir(uid, eventuid) {
    return this.firestore.collection('eventos', ref => ref.where('noasistentes', 'array-contains' , uid )).snapshotChanges();
  }

  getEventoById(uid) {
    return this.firestore.collection('eventos').doc(uid).snapshotChanges();
  }

  createdEvento(evento) {
    return this.firestore.collection('eventos').add(evento);
  }

  editEvento(uid, evento) {
    return this.firestore.collection('eventos').doc(uid).update(evento);
  }

  DeleteEvento(uid) {
    return this.firestore.collection('eventos').doc(uid).delete();
  }

}
