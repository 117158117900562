<!-- <p>{{messages?.payload.doc.data().message}}</p> -->
<div id="container">
  <div class="box-alerta">
    <div id="title">
      <img src="assets/img/alertIcon.svg" />
      <h1>Alerta</h1>
    </div>
    <div id="dataTitle">
      <h2>Nombre</h2>
      <h2>Colegio</h2>
      <h2>Fecha</h2>
      <h2>Id</h2>
    </div>
    <div class="box-message">
      <div id="data">
        <h3>{{ nombreAlumno | titlecase }}</h3>
        <h3>{{ alerta.idColegio | titlecase }}</h3>
        <h3>{{alerta.fechaAlerta | date:"short"}}</h3>
        <h3>{{ alerta.idAlerta }}</h3>
      </div>
      <div id="Message">
        <p><b>Mensaje:</b> {{ alerta.mensaje }}</p>
      </div>
    </div>
    <div id="stateMessage">
      <div *ngIf="alerta.estado == 'pendiente'">
        <div class="botones-alerta2">
          <a
            (click)="aceptAlert()"
            style="background-color: rgb(44, 51, 82)"
            class="button"
            >Aprobar</a
          >
          <a
            (click)="discardAlert()"
            style="background-color: #fa3b32"
            class="button"
            >Denegar</a
          >
          <a
            (click)="deleteAlert()"
            style="background-color:rgb(104, 109, 131, 0.8)"
            class="button"
          >
            Ignorar
          </a>
        </div>
      </div>
      <div id="divA" *ngIf="alerta.estado == 'aceptada'">
        <h2 id="msgA">
          <b>{{ alerta.idColegio | titlecase }}</b>&nbsp; ha cambiado el estado del
          reporte a &nbsp;<b>{{ alerta.estado | titlecase }}</b>
          <div *ngIf="alerta.estado === 'aceptada'">
            <img src="assets/img/ok.svg" />
          </div>
        </h2>
        <a
          (click)="deleteAlert()"
          style="background-color: #fa3b32"
          class="button"
        >
          <img src="assets/img/delete.svg"/>
        </a>
      </div>
      <div id="divB" *ngIf="alerta.estado == 'rechazada'">
        <h2>
          <b>{{ alerta.idColegio | titlecase }}</b>&nbsp; ha cambiado el estado del
          reporte a &nbsp;<b> {{ alerta.estado | titlecase }}</b>
          <div *ngIf="alerta.estado === 'rechazada'">
            <img src="assets/img/rechazada.svg" />
          </div>
        </h2>
        <a
          (click)="deleteAlert()"
          style="background-color: #fa3b32"
          class="button"
        >
          <img src="assets/img/delete.svg"/>
        </a>
      </div>
    </div>
  </div>

</div>
