import { Component, OnInit } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/functions';
import { Router, RouterLink } from '@angular/router';

@Component({
  selector: 'app-soporte',
  templateUrl: './soporte.component.html',
  styleUrls: ['./soporte.component.css']
})
export class SoporteComponent implements OnInit {

  nombre:string = null;
  apellidos:string = null;
  email:string = null;
  usuario:string = null;
  texto:string = null;
  mensaje: string = "";


  constructor(
    private functions: AngularFireFunctions,
    private router: Router
  ) { }

  ngOnInit(): void {
  }


  sendEmail(){
    if (this.validateEmail(this.email)){
      const callable = this.functions.httpsCallable('sendEmailSupport');
      const obs = callable({
        nombre: this.nombre, 
        apellidos: this.apellidos,
        email: this.email,
        usuario: this.usuario,
        texto: this.texto
      });
      obs.subscribe(async(res)=>{
        
      });
      
      this.mensaje="Email Enviado a Support"
      this.nombre=""
      this.apellidos=""
      this.email=""
      this.usuario=""
      this.texto=""
      setTimeout(() => {
        this.mensaje=""
      }, 2500);
  
      
    }
    else{
      this.mensaje="El formato del email no es válido"
    }
    
  }
  
  validateEmail(email) {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }
}
