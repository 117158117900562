import { AuthenticationService } from '../../../services/authentication.service';
import { UserService } from '../../../services/user.service';
import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase/app';

@Component({
  selector: 'app-pass',
  templateUrl: './pass.component.html',
  styleUrls: ['./pass.component.css']
})
export class PassComponent implements OnInit {
  currentuid: string;
  user: any;
  uid: string;
  uid_in_userdatabase: any;

  pass1: string;
  pass2: string;
  cupass: string;
  saveresponse1: string;

  email1: string;
  email2: string;
  cupass2: string;
  saveresponse2: string;

  constructor(private userService: UserService,
              private authenticationService: AuthenticationService,
              private auth: AngularFireAuth) {

    const Userdata: any = this.userService.getUserinfodata();

    if (Userdata === undefined) {
      this.authenticationService.getStatus().subscribe((status) => {
        this.currentuid = status.uid;
        this.userService.getUserById(status.uid).subscribe((data)=>{
          this.user = data[0].payload.doc.data();
          this.uid_in_userdatabase = data[0].payload.doc.id;
          this.uid = this.currentuid;
        });
      });
    } else {
      this.currentuid = Userdata.payload.doc.data().uid;
      this.user = Userdata.payload.doc.data();
      this.uid_in_userdatabase = Userdata.payload.doc.id;
      this.uid = this.currentuid;
    }

  }



  async changepass() {

    if(this.cupass) {

      if(this.pass1 && this.pass2) {

        if(this.pass1 === this.pass2) {

          await (await this.auth.currentUser).reauthenticateWithCredential(
            firebase.auth.EmailAuthProvider.credential(
              (await this.auth.currentUser).email, this.cupass
              )
            ).then(async promise => {
              if(promise.user.email) {
                await (await this.auth.currentUser).updatePassword(this.pass1).then(updata => {
                  this.saveresponse1 = 'Contraseña actualizada';
                  setTimeout(() => {this.saveresponse1 = ''; }, 5000);
                }).catch(error => {
                  this.saveresponse1 = 'No se pudo actualizar la contraseña';
                  setTimeout(() => {this.saveresponse1 = ''; }, 5000);
                });

              } else {
                this.saveresponse1 = 'Contraseña incorrecta';
                setTimeout(() => {this.saveresponse1 = ''; }, 5000);
              }
            }).catch(error => {
              this.saveresponse1 = 'Contraseña incorrecta';
              setTimeout(() => {this.saveresponse1 = ''; }, 5000);
            });



        } else {
          this.saveresponse1 = 'Las contraseñas no coinciden';
          setTimeout(() => {this.saveresponse1 = ''; }, 5000);
        }

      } else {
        this.saveresponse1 = 'No pueden haber campos vacios';
        setTimeout(() => {this.saveresponse1 = ''; }, 5000);
      }

    } else {
      this.saveresponse1 = 'Debes escribir tu contraseña actual';
      setTimeout(() => {this.saveresponse1 = ''; }, 5000);
    }

  }


  async changeemail() {

    if(this.cupass2) {

      if(this.email1 && this.email2) {

        if(this.email1 == this.email2) {

          await (await this.auth.currentUser).reauthenticateWithCredential(
            firebase.auth.EmailAuthProvider.credential(
              (await this.auth.currentUser).email, this.cupass2
              )
            ).then(async promise => {
              if(promise.user.email) {
                await (await this.auth.currentUser).updateEmail(this.email1.toLowerCase()).then(upmail => {
                  const theuser = {
                    uid: this.uid,
                    email: this.email1,
                  };
                  this.userService.editUser(theuser, this.uid_in_userdatabase);
                  this.saveresponse2 = 'Email actualizado';
                  setTimeout(() => {this.saveresponse2 = ''; }, 5000);
                }).catch(error => {
                  this.saveresponse2 = 'No se pudo actualizar el email';
                  setTimeout(() => {this.saveresponse2 = ''; }, 5000);
                });

              } else {
                this.saveresponse2 = 'Contraseña incorrecta';
                setTimeout(() => {this.saveresponse2 = ''; }, 5000);
              }
            }).catch(error => {
              this.saveresponse2 = 'Contraseña incorrecta';
              setTimeout(() => {this.saveresponse2 = ''; }, 5000);
            });



        } else {
          this.saveresponse2 = 'Los email no coinciden';
          setTimeout(() => {this.saveresponse2 = ''; }, 5000);
        }

      } else {
        this.saveresponse2 = 'No pueden haber campos vacios';
        setTimeout(() => {this.saveresponse2 = ''; }, 5000);
      }

    } else {
      this.saveresponse2 = 'Debes escribir tu contraseña actual';
      setTimeout(() => {this.saveresponse2 = ''; }, 5000);
    }

  }

  ngOnInit(): void {
  }

}
