import { Component, OnInit, Input } from '@angular/core';
import { Colegio } from 'src/app/interfaces/colegio';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ColegiosService } from 'src/app/services/colegios.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit {
  @Input() nombres: string;
  @Input() apellidos: string;
  @Input()colegio: Colegio;
  @Input() genero:string;

  background="#0033A1"
  avatar: any = "assets/img/logo.png";

  constructor(
    private userService: UserService,
    private authenticationService: AuthenticationService,
    private colegioService: ColegiosService
  ) {
  }

  ngOnInit(): void {
    if(this.colegio.background){
      this.background = this.colegio.background;
    }
    if(this.colegio.avatar){
      this.avatar = this.colegio.avatar
    }

  }
}