import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class NotificacionesService {

  constructor(private firestore: AngularFirestore) { }

  getNotificaciones(uid) {
    return this.firestore.collection('notificaciones', ref => ref.where('user', '==' , uid).orderBy('fecha', 'asc')).snapshotChanges();
  }

  getNotificacionessinver(uid) {
    return this.firestore.collection('notificaciones', ref => ref.where('verlo', '==' , uid).orderBy('fecha', 'asc')).snapshotChanges();
  }

  getNotificacionById(uid) {
    return this.firestore.collection('notificaciones').doc(uid).snapshotChanges();
  }

  createdNotificacion(evento) {
    return this.firestore.collection('notificaciones').add(evento);
  }

  editNotificacion(evento) {
    return this.firestore.collection('notificaciones').doc(evento.uid).set(evento);
  }

  removeNotificacion(uid) {
    return this.firestore.collection('notificaciones').doc(uid).delete();
  }

  updateNotificacion(uid, update){
    return this.firestore.collection('notificaciones').doc(uid).update(update);
  }

  SearchNotification(eventid) {
    return this.firestore.collection('notificaciones', ref => ref.where('event_id', '==' , eventid)).snapshotChanges();
  }
}
