import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { AlertasService } from 'src/app/services/alertas.service';
import { ColegiosService } from 'src/app/services/colegios.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-historialbox',
  templateUrl: './historialbox.component.html',
  styleUrls: ['./historialbox.component.css']
})
export class HistorialBoxComponent implements OnInit {
  @Input() alerta: any;
  nombreAlumno: any;
  imagenAlumno: any;
  avatarColegio: any;
  @Output() valueResponse: EventEmitter<any> = new EventEmitter();

  idOriginal:any = '';
  constructor(
    private _userService: UserService,
    private _alertService: AlertasService,
    private _colegioService: ColegiosService,
  ) {}

  ngOnInit(): void {
    this.getInfoUser();
    this._alertService.getAlerts().subscribe((alertas) => {
      alertas.forEach((alertaComp) => {
        let alertaVar = alertaComp.payload.doc.data();
        if (alertaVar['idAlerta'] == this.alerta.idAlerta) {
          this.idOriginal = alertaComp.payload.doc.id;
          }
         
      });
    });
  }
  
  getInfoUser() {
    this._userService.getUsers().subscribe((users) => {
      users.forEach((user) => {
        const User = user.payload.doc.data();
        if (User['uid'] == this.alerta.idUsuario) {
          this.nombreAlumno = User['nombres'];
          if (User['avatar']) {
            this.alerta.avatarAlumno = User['avatar'];
          }
          else{
            this.alerta.avatarAlumno = 'assets/img/defaultavatar.jpg'
          }
        }
      });
    });
    
    this._colegioService.getColegios().subscribe((colegios) => {
      colegios.forEach((cole) => {
        const Cole = cole.payload.doc.data();
        if (Cole['nombres'] == this.alerta.idColegio) {
          this.avatarColegio = Cole['avatar'];
        }
        else{
          this.avatarColegio = 'assets/img/defaultavatar.jpg';
        }
      });
    });
  }
  deleteAlert() {
    this._alertService.deleteAlert(this.idOriginal);
    this.ngOnDestroy();
  }

  ngOnDestroy(): void {
    this.valueResponse.emit(this.alerta);
  }
}

