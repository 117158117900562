<div>
  <section class="basicbox clase grupo">
    <header>
      <div class="icon">
        <div class="wrapicon">
          <img src="/assets/img/bell.svg" alt="" />
        </div>
      </div>
      <h3>Mis notificaciones</h3>
      <div>
        <div class="pagination">
          <div>
            <ng-container *ngIf="p > 1">
              <div class="al" (click)="prevpage()"><</div>
            </ng-container>
          </div>
  
          <div>
            <span class="current">{{ p }} </span>- {{ totalpages }}
          </div>
  
          <div>
            <ng-container *ngIf="p < totalpages">
              <div class="ar" (click)="nextpage()">></div>
            </ng-container>
          </div>
        </div>
      </div>
    </header>


    <ng-container
      *ngFor="
        let notificacion of lasnotificaciones
          | paginate: { itemsPerPage: itemsperpage, currentPage: p }
      "
    >
      <div class="claseheader">
        <div class="subbasicbox chat headinguser">
          <div class="boxtitles">
            <h4>{{ notificacion.payload.doc.data().texto }}</h4>
          </div>
          <div class="rigthbuttons">
            <ng-container *ngIf="notificacion.payload.doc.data().url">
              <a
                (click)="vernotificacion(notificacion.payload.doc.id)"
                routerLink="{{ notificacion.payload.doc.data().url }}"
              >
                Ver
              </a>
            </ng-container>

            <br />

            <a (click)="deletenotificacion(notificacion.payload.doc.id)">
              Eliminar
            </a>
          </div>

          <div class="boxdata">
            <div class="adminsgrid">
              <div>
                <p></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <div></div>
  </section>
</div>
