import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AuthenticationService } from '../../services/authentication.service';
import { Router } from '@angular/router';
import { UserService } from '../../services/user.service';
import { NotificacionesService } from './../../services/notificaciones.service';
import { PresenciaService } from '../../services/presencia.service';
import { BreadcrumbService } from '../../services/breadcrumb.service';
import { AlertasService } from 'src/app/services/alertas.service';
import {HttpClient} from '@angular/common/http';
import {HttpParams} from '@angular/common/http';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css'],
})
export class MenuComponent implements OnInit {
  @Input() uid: string;
  @Input() avatar: string;
  @Input() uidb: string;
  @Input() rolUser: string;
  lasnotificaciones: any;
  alertasContador: any;
  userUid: string;
  mainuseremail: string;
  pexipdata: any;
  apellidos: any ="";
  nombres: any ="";
  userdata: any;
  usuario:any

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private userService: UserService,
    private presencia: PresenciaService,
    private notificacionesService: NotificacionesService,
    private breadService: BreadcrumbService,
    private alertas: AlertasService,
    private http: HttpClient,
  ) {
    const Userdata: any = this.userService.getUserinfodata();
    if (Userdata === undefined) {
      this.authenticationService.isAuth().subscribe((auth) => {
        if (auth) {
          this.userUid = auth.uid; /* id del usuario */
          this.mainuseremail = auth.email; /* email del usuario */
          this.getroom(auth.email);
          this.notificacionesService
            .getNotificacionessinver(auth.uid)
            .subscribe((notificaciones) => {
              this.lasnotificaciones = notificaciones.length;
            });
          this.alertas
            .getAlertaByIdDirector(auth.uid)
            .subscribe((alertasDirector) => {
              var contador = 0;
              alertasDirector.forEach((aler) => {
                var alertaVar = aler.payload.doc.data();
                if (
                  alertaVar['mostrarDirector'] == true &&
                  alertaVar['reportada'] == true &&
                  alertaVar['estado'] == 'pendiente'
                ) {
                  contador++;
                }
              });
              this.alertasContador = contador;
            });


            this.authenticationService.isUserAdmin(this.userUid).subscribe(userdata => {
              if(userdata[0]){
    
                this.userdata = userdata[0];
                this.nombres = this.userdata.nombres; /* nombres del usuario logueado */
                this.apellidos = this.userdata.apellidos; /*apellidos del usuario logueado */
                this.usuario=this.userdata.usuario
              }
            });
        }
      });
    } else {
      this.userUid = Userdata.payload.doc.data().uid; /* id del usuario */
      this.mainuseremail = Userdata.payload.doc.data().email; /* email del usuario */
      this.usuario =  Userdata.payload.doc.data().usuario
      this.getroom(Userdata.payload.doc.data().email);
      this.userdata = Userdata.payload.doc.data();
      this.nombres = this.userdata.nombres; /* nombres del usuario logueado */
      this.apellidos = this.userdata.apellidos; /*apellidos del usuario logueado */
     
      this.notificacionesService
        .getNotificacionessinver(Userdata.payload.doc.data().uid)
        .subscribe((notificaciones) => {
          this.lasnotificaciones = notificaciones.length;
        });
      this.alertas
        .getAlertaByIdDirector(Userdata.payload.doc.data().uid)
        .subscribe((alertasDirector) => {
          var contador = 0;
          alertasDirector.forEach((aler) => {
            var alertaVar = aler.payload.doc.data();
            if (
              alertaVar['mostrarDirector'] == true &&
              alertaVar['reportada'] == true &&
              alertaVar['estado'] == 'pendiente'
            ) {
              contador++;
            }
          });
          this.alertasContador = contador;
        });
    }
  }

  ngOnInit() {}

  logout(uiddb) {
    const status = {
      status: [{ estado: 'offline', time: new Date() }],
    };
    this.presencia
      .setPresence('offline')
      .then((updated) => {
        this.authenticationService
          .logOut()
          .then(() => {
            this.router.navigate(['']);
          })
          .catch((error) => {
            console.log('error');
          });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  UpdateBreadcrumb(name, url) {
    this.breadService.addtobread(name, url);
  }

  getroom(email) {

    this.http.get<any>("https://us-central1-the-campus-56b06.cloudfunctions.net/getRoom",
    { params: new HttpParams().set('salaname', email) }).subscribe(data => {
      this.pexipdata = data;
    });

  }
}
