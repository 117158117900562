
  <div class="calendarbuttons">
    <div class="btn-group">
      <div
        class="btn btn-primary"
        mwlCalendarPreviousView
        [view]="view"
        [(viewDate)]="viewDate"
        (viewDateChange)="closeOpenMonthViewDay()"
      >
      <  Mes Anterior
      </div>
      <div
        class="btn btn-outline-secondary"
        mwlCalendarToday
        [(viewDate)]="viewDate"
      >
        Hoy
      </div>
      <div
        class="btn btn-primary"
        mwlCalendarNextView
        [view]="view"
        [(viewDate)]="viewDate"
        (viewDateChange)="closeOpenMonthViewDay()"
      >
        Mes Siguiente >
      </div>
    </div>

  <div class="calendartitle">
    <h6>{{ viewDate | calendarDate:(view + 'ViewTitle'):'en' }}</h6>
  </div>
</div>
<br />
<div [ngSwitch]="view">
  <mwl-calendar-month-view
    *ngSwitchCase="CalendarView.Month"
    [viewDate]="viewDate"
    [events]="events"
    [refresh]="refresh"
    (eventClicked)="eventClicked($event)"
    (eventTimesChanged)="eventTimesChanged($event)"
  >
  </mwl-calendar-month-view>
</div>
