<section *ngIf="!borrarAlerta">
  <div id="{{ evento.payload.doc.id }}" class="basicbox bme">
    <div class="mainContainer">
      <div class="container1">
        <div class="dataEvent">
          <h3>
            <b>Evento: </b>{{ evento.payload.doc.data().nombre | titlecase }}
          </h3>
          <h3>
            <b>Descripción: </b>{{ evento.payload.doc.data().descripcion }}
          </h3>
          <h3>
            <b>Convocante: </b>{{ creador.nombres | titlecase }}
            {{ creador.apellidos | titlecase }}
          </h3>
          <h3
            *ngIf="
              evento.payload.doc.data().videoconferencia != '' &&
              evento.payload.doc.data().videoconferencia != null
            "
          >
            <b>Link conferencia: </b>
            <a
              id="link"
              href="{{ evento.payload.doc.data().videoconferencia }}"
              target="_blank"
              rel="noopener noreferrer"
              >{{ evento.payload.doc.data().videoconferencia }}</a
            >
          </h3>
          <h3>
            <b>Nº Participantes: </b
            >{{participantesTotales}}
          </h3>
        </div>
        <div>
          <img
            id="close"
            src="assets/img/close.svg"
            alt="close"
            (click)="deleteAsistencia()"
          />
        </div>
      </div>

      <div class="container2">
        <div class="datebg">
          <div class="wfecha">
            <div class="fecha">
              {{
                evento.payload.doc.data().fecha_inicio.toDate()
                  | date: "dd/MM/y - HH:mm"
              }}
            </div>
          </div>
        </div>
        <label>
          <input
            id="buttonEventos"
            class="buttonV"
            type="checkbox"
            (click)="ver()"
          /><span>Ver Participantes</span>
        </label>

        <div class="buttons">
          <div
            *ngIf="
              evento?.payload.doc.data().creado == userUid;
              else elsebutton
            "
            class="wbutton"
          >
            <button
              class="button"
              routerLink="/dashboard/profile/{{ userUid }}/editarevento/{{
                evento.payload.doc.id
              }}"
            >
              Editar
            </button>
            <button class="button" (click)="deleteevent()">Borrar</button>
          </div>
        </div>
        <ng-template #elsebutton>
          <div
            class="asistir"
            *ngIf="
              !asistira && !noasistira && fechaFinal > fechaActual;
              else estado
            "
          >
            <h3>¿Asistirás?</h3>
            <div class="asistirB">
              <button class="button" (click)="addAsistir()">Si</button>
              <button class="button" (click)="addNoAsistir()">No</button>
            </div>
          </div>
          <ng-template #estado>
            <div *ngIf="fechaFinal < fechaActual; else estadoAsistencia">
              <button id="eventoTerminado" class="button">
                Evento terminado
              </button>
            </div>
            <ng-template #estadoAsistencia>
              <div *ngIf="asistira == true">
                <button id="estado" class="button">Asistiré</button>
              </div>
              <div *ngIf="noasistira == true">
                <button id="estado" class="button">No Asistiré</button>
              </div>
            </ng-template>
          </ng-template>
        </ng-template>
      </div>
    </div>
  </div>
</section>

<section
  *ngIf="verParticipantes == true && (asistentes.length || noasistentes.length)"
>
  <div class="basicbox bme2">
    <div class="asistentes">
      <h3 id="title">Asistirán({{ asistentes.length }}):</h3>
      <div class="participantesContainer">
        <ng-container *ngFor="let item of asistentes">
          <div class="participantes">
            <div *ngIf="item.avatar != ''; else elseImagen">
              <img
                class="avatar"
                src="{{ item.avatar }}"
                alt="Imagen de perfil del participante"
              />
            </div>
            <ng-template #elseImagen>
              <img
                class="avatar"
                src="assets/img/defaultavatar.jpg"
                alt="Imagen generica"
              />
            </ng-template>
            <h3>
              {{ item.nombres | titlecase }} {{ item.apellidos | titlecase }}
            </h3>
          </div>
        </ng-container>
      </div>
      <h3 id="title">No Asistirán({{ noasistentes.length }}):</h3>
      <div class="participantesContainer">
        <ng-container *ngFor="let item of noasistentes">
          <div class="participantes">
            <div *ngIf="item.avatar != ''; else elseImagen">
              <img
                class="avatar"
                src="{{ item.avatar }}"
                alt="Imagen de perfil del participante"
              />
            </div>
            <ng-template #elseImagen>
              <img
                class="avatar"
                src="assets/img/defaultavatar.jpg"
                alt="Imagen generica"
              />
            </ng-template>
            <h3>
              {{ item.nombres | titlecase }} {{ item.apellidos | titlecase }}
            </h3>
          </div>
        </ng-container>
      </div>
    </div>
    <div *ngIf="emails.length > 0">
      <h3 id="title">Invitados por email</h3>
      <div *ngFor="let item of emails">
        <h3>{{ item }}</h3>
      </div>
    </div>
  </div>
</section>

<section
  *ngIf="
    verParticipantes == true &&
    asistentes.length == 0 &&
    noasistentes.length == 0
  "
>
  <div class="basicbox bme2">
    <h3 id="title">Nadie ha confirmado asistencia</h3>

    <div *ngIf="emails.length > 0">
      <h3 id="title">Invitados por email</h3>
      <div *ngFor="let item of emails">
        <h3>{{ item }}</h3>
      </div>
    </div>
  </div>
</section>
