import { CursosService } from './../../../services/cursos.service';
import { Component, OnInit } from '@angular/core';
import { ClasesService } from '../../../services/clases.service';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { UserService } from '../../../services/user.service';
import { AngularFireStorage } from '@angular/fire/storage';
import { AuthenticationService } from '../../../services/authentication.service';
import { Colegio } from '../../../interfaces/colegio';
import { ColegiosService } from '../../../services/colegios.service';
import { Router, ActivatedRoute  } from '@angular/router';
import * as firebase from 'firebase/app';

@Component({
  selector: 'app-editarclase',
  templateUrl: './editarclase.component.html',
  styleUrls: ['./editarclase.component.css']
})
export class EditarclaseComponent implements OnInit {
  changeFoto: boolean = false;
  nombre: string;
  descripcion: string;
  imageChangedEvent: any = '';
  croppedImage: any = '';
  picture: any;
  currentuid: string;
  saveresponse: string;
  userUid: any;
  userdata: any;
  colegio: Colegio;
  curso: any;
  results: any;
  searchValue: string;
  userlist = [];
  userlisids = [];
  userindatabase: any; /* datos del usuario en la coleccion usuarios */
  cursos: any; /* nombres de cursos */
  saving = false;
  clicked = false;

  claseuid: any;
  currentclass: any;
  colegionombre: string;
  deleteusers = [];


  constructor(private clasesService: ClasesService,
              private authenticationService: AuthenticationService,
              private firebaseStorage: AngularFireStorage,
              private colegioService: ColegiosService,
              private CursosService: CursosService,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              private userService: UserService)
  {
    /* toma el parametro uid que viene en la url */
    this.activatedRoute.params.subscribe(params => {
      /* uid de la clase actual */
      this.claseuid = params['uid'];

      const Userdata: any = this.userService.getUserinfodata();
      if (Userdata === undefined) {
        this.getmainuserdata();
      } else {
        this.userUid = Userdata.payload.doc.data().uid; /* id del usuario, uid en authentication */
        this.userdata = Userdata.payload.doc.data();
        this.userindatabase = Userdata; /* datos del usuario en coleccion usuarios */
        this.getColegio(this.userdata);
        this.getClase();
        this.search();
      }
    });

  }


  ngOnInit(): void {
  }


  /* trae informacion principal del usuario logueado */
  getmainuserdata() {
    this.authenticationService.isAuth().subscribe(auth =>{
      if(auth) {
        this.userUid = auth.uid; /* id del usuario, uid en authentication */

        this.userService.getUserById(this.userUid).subscribe(userdata => {
          if(userdata[0]){
            this.userdata = userdata[0].payload.doc.data();
            this.userindatabase = userdata[0]; /* datos del usuario en coleccion usuarios */
            this.getColegio(this.userdata);
            this.getClase();
            this.search();
          }
        });

      }
    });
  }


  /* obtiene el primer colegio(array) al que pertenezca el usuario logueado */
  getColegio(userdata) {

    if (userdata && userdata.colegios[0]){

      this.colegioService.getColegioById(userdata.colegios[0]).subscribe(colegio => {
        if (colegio) {
          this.colegio = colegio.payload.data();
          //console.log(this.colegio);
          this.cursos = [];
          this.colegio.cursos.forEach((elcurso) => {
            //console.log(elcurso);
            this.CursosService.getCursosById(elcurso).subscribe(cursodata => {
              let elcursodata:any = cursodata.payload.data();
              //console.log(elcursodata);
              this.cursos.push(elcursodata.nombre);
              //console.log(this.cursos);
            })
          })
        }
      });

    }

  }

  getClase() {
    this.clasesService.getClaseById(this.claseuid).subscribe(clasedata => {
      this.currentclass = clasedata.payload.data();
      this.nombre = this.currentclass.nombre;
      this.descripcion = this.currentclass.descripcion;
      this.colegionombre = this.currentclass.colegio_nombre;
      this.curso = this.currentclass.curso_nombre;
      this.getusers();
    });
  }

  getusers() {
    if (this.currentclass.usuarios) {
      this.currentclass.usuarios.forEach((user) => {
        this.userService.getUserById(user).subscribe(userdata => {
          if (this.saving === false) { /* si es true, osea esta creando un grupo, no se actualiza el listado */
            this.userlist.push(userdata[0]);
          }
        });
      });
    }
  }

  /* funcion para la busqueda de usuarios */
  search() {
    this.saving = false; /* impide que se muestre el listado por cambios en la base de datos */
    this.userService.searchUsers(this.userdata.clases).subscribe(searchdata => {
      if (this.saving === false) { /* si es true, osea esta creando un grupo, no se actualiza el listado */
        this.results = searchdata;
      }
    });
  }

  /* funcion para añadir usuarios haciendo click en el  boton +*/
  adduser(user) {
    if(!this.userlist.some(el => el.payload.doc.data().uid === user.payload.doc.data().uid)) { /*comprueba que no exista en el array */
      this.userlist.push(user);
    }
  }

  /* funcion para quitar el usuario del array de invitados */
  removeuser(uid) {
    this.userlist.forEach((user) => {
      if (user.payload.doc.data().uid === uid) {
        this.deleteusers.push(user);
        this.userlist.splice(this.userlist.indexOf(user), 1);
      }
    });
  }


  /*image drop funciones */
  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
    this.changeFoto = true;
  }
  imageCropped(event: ImageCroppedEvent) {
      this.croppedImage = event.base64;
  }
  imageLoaded() {
      // show cropper
  }
  cropperReady() {
      // cropper ready
  }
  loadImageFailed() {
      // show message
  }


  /* funcion para crear la clase */
  createclass() {

    this.saving = true; /* en true quiere decir que se esta creando una clase */

    /* validaciones, que no sean campos vacíos */
    if(this.nombre === '' || this.nombre === undefined) {
      this.saveresponse = 'Debe tener un nombre';
      return;
    }

    if(this.curso === '' || this.curso === undefined) {
      this.saveresponse = 'Debe tener un curso';
      return;
    }

    if(this.userlist.length <= 0 || this.userlist.length === undefined) {
      this.saveresponse = 'Debe tener al menos un miembro';
      return;
    }



    /***************
     * validación ?
     * *************/

    /*this.curso = this.curso.replace(/[^01]+/gi, '');
    this.curso = this.curso.replace(/^0+/, '');

    this.nombre = this.nombre.replace(/[^01]+/gi, '');
    this.nombre = this.nombre.replace(/^0+/, '');

    if(this.descripcion !== undefined) {
      this.descripcion = this.descripcion.replace(/[^01]+/gi, '');
      this.descripcion = this.descripcion.replace(/^0+/, '');
    }*/

    if (this.croppedImage) { /* si hay avatar para subir */

      if(this.currentclass.imagen) {
        this.firebaseStorage.storage.refFromURL(this.currentclass.imagen).delete().then(filedel => {

          this.saveresponse = 'Subiendo avatar...'; /* notifica arriba del boton guardar */
          const currenPictureId = Date.now();
          const pictures = this.firebaseStorage.ref('avatars/' + currenPictureId + '.jpg').putString(this.croppedImage, 'data_url');
          pictures.then(() => {
            this.picture = this.firebaseStorage.ref('avatars/' + currenPictureId + '.jpg').getDownloadURL();
            this.picture.subscribe((p) => { /* p es la url de la imagen subida */

              this.userlist.forEach((user) => {
                this.userlisids.push(user.payload.doc.data().uid); /* array de solo uids de usuarios para guardar al crear la clase,
                                                                        uids en authentication */
              });

              if(!this.userlisids.some(el => el === this.userUid)) {
                this.userlisids.push(this.userUid); /* añade la uid del usuario actual al array que para crear la clase,
                                                      uid en authentication */
              }

              const theclass = {
                administradores: [ this.userUid ], /* uid del usuario en authentication */
                descripcion: this.descripcion,
                colegio: this.currentclass.colegio,
                colegio_nombre: this.colegionombre.toLowerCase(),
                curso_nombre: this.curso.toLowerCase(),
                imagen: p,
                nombre: this.nombre.toLowerCase(),
                usuarios: this.userlisids, /* uids de los usuarios en authentication */
              };

              this.clasesService.editClase(this.claseuid, theclass).then((edited) => {

                this.saveresponse = 'El grupo ha sido actualizado';
                setTimeout(() => {this.saveresponse = '';
                /* despues de editada se redirecciona a mis grupos */
                this.router.navigate(['/dashboard/misgrupos']);}, 2000);

                /* recorre el listado de usuarios para actualizarlos y añadir la nueva clase a su lista de clases */
                this.userlist.forEach((user) => {
                  /* se añade uid de la clase al array clase del usuario */
                  const clases = { clases: firebase.firestore.FieldValue.arrayUnion(this.claseuid) };
                  /* para actualizar el usuario se usa la uid del usuario en la coleccion usuarios */
                  this.userService.editUser(clases, user.payload.doc.id).then((edit) => {
                  }).catch((error) => {console.log(error)});
                });

                /* aqui se actualiza y añade la uid de la clase en el usuario que la esta creando y por lo tanto
                no esta añadido al listado de invitados */
                //const clases = { clases: firebase.firestore.FieldValue.arrayUnion(this.claseuid) };
                /* para actualizar el usuario se usa la uid del usuario en la coleccion usuarios */
                /*this.userService.editUser(clases, this.userindatabase.payload.doc.id).then((edit) => {
                }).catch((error) => {console.log(error)});*/

                /* actualiza los usuarios borrados de la clase, elimina la clase de su lista de clases */
                if (this.deleteusers.length > 0) {
                  this.deleteusers.forEach((user) => {
                    const clasedelete = { clases: firebase.firestore.FieldValue.arrayRemove(this.claseuid) };
                    this.userService.editUser(clasedelete, user.payload.doc.id).then((edit) => {
                    }).catch((error) => {console.log(error)});
                  });
                }



              }).catch((error) =>  {
                this.saveresponse = 'Ha ocurrido un error';
                setTimeout(() => {this.saveresponse = ''; }, 5000);
              });
            });
          }).catch((error)=> {
            console.log(error);
          });

        });
      } else {

        this.saveresponse = 'Subiendo avatar...'; /* notifica arriba del boton guardar */
        const currenPictureId = Date.now();
        const pictures = this.firebaseStorage.ref('avatars/' + currenPictureId + '.jpg').putString(this.croppedImage, 'data_url');
        pictures.then(() => {
          this.picture = this.firebaseStorage.ref('avatars/' + currenPictureId + '.jpg').getDownloadURL();
          this.picture.subscribe((p) => { /* p es la url de la imagen subida */

            this.userlist.forEach((user) => {
              this.userlisids.push(user.payload.doc.data().uid); /* array de solo uids de usuarios para guardar al crear la clase,
                                                                      uids en authentication */
            });


            if(!this.userlisids.some(el => el === this.userUid)) {
              this.userlisids.push(this.userUid); /* añade la uid del usuario actual al array que para crear la clase,
                                                    uid en authentication */
            }


            const theclass = {
              administradores: [ this.userUid ], /* uid del usuario en authentication */
              descripcion: this.descripcion,
              colegio: this.currentclass.colegio,
              colegio_nombre: this.colegionombre.toLowerCase(),
              curso_nombre: this.curso.toLowerCase(),
              imagen: p,
              nombre: this.nombre.toLowerCase(),
              usuarios: this.userlisids, /* uids de los usuarios en authentication */
            };

            this.clasesService.editClase(this.claseuid, theclass).then((edited) => {

              this.saveresponse = 'El grupo ha sido actualizado';
              setTimeout(() => {this.saveresponse = '';
              /* despues de editada se redirecciona a mis grupos */
              this.router.navigate(['/dashboard/misgrupos']);
              }, 2000);

              /* recorre el listado de usuarios para actualizarlos y añadir la nueva clase a su lista de clases */
              this.userlist.forEach((user) => {
                /* se añade uid de la clase al array clase del usuario */
                const clases = { clases: firebase.firestore.FieldValue.arrayUnion(this.claseuid) };
                /* para actualizar el usuario se usa la uid del usuario en la coleccion usuarios */
                this.userService.editUser(clases, user.payload.doc.id).then((edit) => {
                }).catch((error) => {console.log(error)});
              });

              /* aqui se actualiza y añade la uid de la clase en el usuario que la esta creando y por lo tanto
              no esta añadido al listado de invitados */
              //const clases = { clases: firebase.firestore.FieldValue.arrayUnion(this.claseuid) };
              /* para actualizar el usuario se usa la uid del usuario en la coleccion usuarios */
              /*this.userService.editUser(clases, this.userindatabase.payload.doc.id).then((edit) => {
              }).catch((error) => {console.log(error)});*/

              /* actualiza los usuarios borrados de la clase, elimina la clase de su lista de clases */
              if(this.deleteusers.length > 0) {
                this.deleteusers.forEach((user) => {
                  const clasedelete = { clases: firebase.firestore.FieldValue.arrayRemove(this.claseuid) };
                  this.userService.editUser(clasedelete, user.payload.doc.id).then((edit) => {
                  }).catch((error) => {console.log(error)});
                });
              }



            }).catch((error) =>  {
              this.saveresponse = 'Ha ocurrido un error';
              setTimeout(() => {this.saveresponse = ''; }, 5000);
            });


          });
        }).catch((error) => {
          console.log(error);
        });

      }



    } else { /* si no hay imagen para subir */

      this.userlist.forEach((user) => {
        this.userlisids.push(user.payload.doc.data().uid); /* array de solo uids de usuarios para guardar al crear la clase,
                                                              uids en authentication */
      });

      if(!this.userlisids.some(el => el === this.userUid)) {
        this.userlisids.push(this.userUid); /* añade la uid del usuario actual al array que para crear la clase,
                                              uid en authentication */
      }

      const theclass = {
        administradores: [ this.userUid ], /* uid del usuario en authentication */
        descripcion: this.descripcion,
        colegio: this.currentclass.colegio,
        colegio_nombre: this.colegionombre.toLowerCase(),
        curso_nombre: this.curso.toLowerCase(),
        imagen: this.currentclass.imagen,
        nombre: this.nombre.toLowerCase(),
        usuarios: this.userlisids, /* uids de los usuarios en authentication */
      };

      this.clasesService.editClase(this.claseuid, theclass).then((edited) => {

        this.saveresponse = 'El grupo ha sido actualizado';
        setTimeout(() => {this.saveresponse = '';
        /* despues de editada se redirecciona a mis grupos */
        this.router.navigate(['/dashboard/misgrupos']);
        }, 2000);

        /* recorre el listado de usuarios para actualizarlos y añadir la nueva clase a su lista de clases */
        this.userlist.forEach((user) => {
          /* se añade uid de la clase al array clase del usuario */
          const clases = { clases: firebase.firestore.FieldValue.arrayUnion(this.claseuid) };
          /* para actualizar el usuario se usa la uid del usuario en la coleccion usuarios */
          this.userService.editUser(clases, user.payload.doc.id).then((edit) => {
          }).catch((error) => {console.log(error)});
        });

        /* aqui se actualiza y añade la uid de la clase en el usuario que la esta creando y por lo tanto
          no esta añadido al listado de invitados */
        //const clases = { clases: firebase.firestore.FieldValue.arrayUnion(this.claseuid) };
        /* para actualizar el usuario se usa la uid del usuario en la coleccion usuarios */
        /*this.userService.editUser(clases, this.userindatabase.payload.doc.id).then((edit) => {
        }).catch((error) => {console.log(error)});*/

        /* actualiza los usuarios borrados de la clase, elimina la clase de su lista de clases */
        if(this.deleteusers.length > 0) {
          this.deleteusers.forEach((user) => {
            const clasedelete = { clases: firebase.firestore.FieldValue.arrayRemove(this.claseuid) };
            this.userService.editUser(clasedelete, user.payload.doc.id).then((edit) => {
            }).catch((error) => {console.log(error)});
          });
        }



      }).catch((error) =>  {
        this.saveresponse = 'Ha ocurrido un error';
        console.log(error);
        setTimeout(() => {this.saveresponse = ''; }, 5000);
      });

    }

  }

}
