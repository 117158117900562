import { CursosService } from './../../services/cursos.service';
import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../services/authentication.service';
import { ClasesService } from '../../services/clases.service';
import { Clases } from '../../interfaces/clases';
import { UserService } from '../../services/user.service';
import * as firebase from 'firebase/app';


@Component({
  selector: 'app-misgrupos',
  templateUrl: './misgrupos.component.html',
  styleUrls: ['./misgrupos.component.css']
})
export class MisgruposComponent implements OnInit {
  userdata: any;
  misclases = [];
  userUid: any;
  clase: Clases;
  clasecontador: number;
  searchValue: string;
  clicked = false;

  constructor(private authenticationService: AuthenticationService,
              private userService: UserService,
              private cursosService: CursosService,
              private clasesService: ClasesService) { }

  ngOnInit(): void {
    const Userdata: any = this.userService.getUserinfodata();

    if (Userdata === undefined) {
      this.getmainuserdata();
    } else {
      this.userUid = Userdata.payload.doc.data().uid; /* id del usuario */
      this.userdata = Userdata.payload.doc.data();
      this.getClases(this.userdata); /* llama a la funcion para obtener las clases del usuario */
    }
  }

  /* trae informacion principal del usuario logueado */
  getmainuserdata() {
    this.authenticationService.isAuth().subscribe(auth =>{
      if(auth) {
        this.userUid = auth.uid; /* id del usuario */

        this.authenticationService.isUserAdmin(this.userUid).subscribe(userdata => {
          if(userdata[0]){
            this.userdata = userdata[0];
            this.getClases(this.userdata); /* llama a la funcion para obtener las clases del usuario */
          }
        });

      }
    });
  }


  /* obtiene las clases al que pertenezca el usuario logueado */
  getClases(userdata) {

    if (userdata && userdata.clases[0]){

      this.misclases = [];
      userdata.clases.forEach((clase: any) => {

        this.clasesService.getClaseById(clase).subscribe(laclase => {
          if (laclase) {

            this.clase = laclase.payload.data();

            this.misclases.push({
              uid: clase,
              administradores: this.clase.administradores,
              cursos: this.clase.cursos,
              colegio: this.clase.colegio,
              nombre: this.clase.nombre,
              imagen: this.clase.imagen,
              usuarios: this.clase.usuarios,
              colegio_nombre: this.clase.colegio_nombre,
              curso_nombre: this.clase.curso_nombre,
            });

            this.clasecontador = this.misclases.length;
          }
        });

      });

    }

  }


  DeleteClass(classuid) {
    console.log(classuid);

    let searchusersbyclass = this.userService.getUsersbyClass(classuid).subscribe(resulusers => {
      if(resulusers) {
        resulusers.forEach((eluser) => {
          const clases = { clases: firebase.firestore.FieldValue.arrayRemove(classuid) };
          this.userService.editUser(clases, eluser.payload.doc.id).then((edit) => {
            console.log(edit);
          }).catch((error) => {console.log(error)});
        });
      }
      searchusersbyclass.unsubscribe();
    });

    let searchcursosbyclass = this.cursosService.getCursosByClassid(classuid).subscribe(resulcurso => {
      if(resulcurso) {
        resulcurso.forEach((elcurso) => {
          const clases = { clases: firebase.firestore.FieldValue.arrayRemove(classuid) };
          this.cursosService.editCurso(elcurso.payload.doc.id, clases).then((edit) => {
            console.log(edit);
          }).catch((error) => {console.log(error)});
        });
      }
      searchcursosbyclass.unsubscribe();
    });

    this.clasesService.DeleteClass(classuid).then(respondel => {
      this.clicked = false;
    });

  }

}
