<div class="container">
    <h1 >Visualización de dispositivos conectados</h1>
    <div class="columns">
      <div class="camara">
        <div id="videoContainer">
          <video id="video"></video>
        </div> 
      </div>
      <div class="data">
          <div class="formgroup">
            <label for="camaraSelect">Ver mis dispositivos de cámara</label>  
            <select 
              name="camara" 
              id="camaraSelect" 
              class="form-control" 
              #mySelect (change)="selectCamera($event.target.value)"
              >
                <option class='option' *ngFor="let item of camaras" value="{{item.deviceId}}">{{item.label}}</option>
            </select>
          </div>

          <div class="formgroup">
              <label for="microSelect">Ver mis dispositivos de micro</label>
            <select
              name="micro"
              id="microSelect"
              class="form-control"
              #mySelectMicro
              (change)="selectAudio(mySelectMicro.value)"
            >
              <option class="option" *ngFor="let item of micros" value="{{ item.deviceId }}">
                {{ item.label }}
              </option>
            </select>
          </div>

        <div class="formgroup">
            <label for="audioSelect">Ver mis dispositivos de audio</label>
           <select
             name="audio"
             id="audioSelect"
             class="form-control"
             #mySelectAudio
             (change)="selectAudio(mySelectAudio.value)"
           >
             <option class="option" *ngFor="let item of audios" value="{{ item.deviceId }}">
               {{ item.label }}
             </option>
           </select>


        </div>

      </div>
      </div>
</div>
