<!-- menu -->
<div class="mainmenu">
  <div class="usermenu">
    <ul>
      <li class="avatar" >
        <div class="ajustesC">
          <img src="/assets/img/cog2.svg" alt="ajustes" />
        </div>
        <img routerLink="/dashboard/profile/{{ uid }}/ajustes"  (click)="UpdateBreadcrumb('Perfil', '/dashboard/profile/' + uid)"
        *ngIf="avatar; else defaultheadavatar" src="{{ avatar }}"/>

        <ng-template ng-template #defaultheadavatar
          ><img routerLink="/dashboard/profile/{{ uid }}/ajustes"  (click)="UpdateBreadcrumb('Perfil', '/dashboard/profile/' + uid)" src="assets/img/defaultavatar.jpg"
          />
        </ng-template>
      </li>
    <li>
      <ng-container *ngIf="rolUser == 'director'">
        <a
          routerLink="/dashboard/alertas/"
          (click)="
            UpdateBreadcrumb('Alertas', '/dashboard/alertas/')"
            title="Alertas"
        >
          <img src="/assets/img/alertIcon.svg" alt="" />
          <ng-container *ngIf="alertasContador > 0">
            <div class="notificationnumber">
              {{ alertasContador }}
            </div>
          </ng-container>
        </a>
      </ng-container>
    </li>
      <li>
        <a
          routerLink="/dashboard/notificaciones/"
          (click)="
            UpdateBreadcrumb('Notificaciones', '/dashboard/notificaciones/')
          "
          title="Notificaciones"
        >
          <img src="/assets/img/bell.svg" alt="" />
          <ng-container *ngIf="lasnotificaciones > 0">
            <div class="notificationnumber">
              {{ lasnotificaciones }}
            </div>
          </ng-container>
        </a>
      </li>
      <li>
        <a routerLink="/dashboard"  title="Home">
          <img src="/assets/img/home2.svg" alt="" />
        </a>
      </li>
      
    </ul>
  </div>

  <div class="utilities">
    <ul>
      <li>
        <a
          routerLink="/dashboard/misgrupos"  title="Mis Clases"
          (click)="UpdateBreadcrumb('Mis Grupos', '/dashboard/misgrupos')"
        >
          <img src="/assets/img/grupo.png" alt="" />
        </a>
      </li>
      <li>
        <a
          routerLink="/dashboard/mensajes"  title="Mensajes"
          (click)="UpdateBreadcrumb('Mensajes', '/dashboard/mensajes')"
        >
          <img src="/assets/img/chatbox.png" alt="" />
        </a>
      </li>
      <li>
        <a href="https://in.thecampus.education/webapp/#/?conference={{usuario }}&name={{nombres | titlecase }} {{apellidos | titlecase }}&pin={{pexipdata?.pin}}&role=host&callType=video" target="_blank">
          <img src="/assets/img/videocall.png" alt="" />
        </a>
      </li>
      <li>
        <a
          routerLink="/dashboard/profile/{{ uid }}/miseventos"  title="Mis eventos"
          (click)="UpdateBreadcrumb('Mis eventos', '/dashboard/profile/'+uid+'/miseventos')"
        >
          <img src="/assets/img/calendar.png" alt="" />
        </a>
      </li>
      <li>
        <a
          routerLink="/dashboard/archivos/user"  title="Mis archivos"
          (click)="UpdateBreadcrumb('Mis Archivos', '/dashboard/archivos/user')"
        >
          <img src="/assets/img/folder.png" alt="" />
        </a>
      </li>
      <li>
        <a (click)="logout(uidb)"  title="Log out">
          <img src="/assets/img/logout.svg" alt="" />
        </a>
      </li>
    </ul>
  </div>
</div>
<!-- /menu -->
