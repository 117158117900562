import { Component, OnInit, NgZone } from '@angular/core';

import { AlertasService } from 'src/app/services/alertas.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { UserService } from 'src/app/services/user.service';
import { AlertaboxComponent } from './alertabox/alertabox.component';

@Component({
  selector: 'app-alertas',
  templateUrl: './alertas.component.html',
  styleUrls: ['./alertas.component.css'],
})
export class AlertasComponent implements OnInit {
  infoU: boolean;
  user: any;
  idUser: any;
  chat:any;
  idEsteUsuario:any;
  alertas: Array<any>;

  constructor(
    private _alertService: AlertasService,
    private _userService: UserService,
    private authenticationService: AuthenticationService
  ) {
    this.getAlertasAndInfo();
  }

  ngOnInit(): void {
    this.alertas.length=0;
  }

  getAlertasAndInfo() {
    this.infoU=false;
    this.alertas = new Array();
    this.idEsteUsuario="";
    
    this.user={avatar:"",uid:""};
    this._alertService.getAlerts().subscribe((alerts) => {
      this.alertas.splice(0,this.alertas.length);
      alerts.forEach((alertaDato) => {
        // const AlertaRecogida: any = alertaDato.payload.doc.data();
        const AlertaRecogida: any = alertaDato;
        this.getUserInfoData(AlertaRecogida);
      });
    });
  }
  getUserInfoData(AlertaRecogida) {
   
    this.authenticationService.isAuth().subscribe((auth) => {
      if (auth) {
        this.idEsteUsuario = auth.uid;
        var AlertaVar = AlertaRecogida.payload.doc.data();
        if (
          AlertaVar['idDirector'] == this.idEsteUsuario &&
          AlertaVar['reportada'] == true &&
          AlertaVar['mostrarDirector'] == true
        ) {
          this.alertas.push(AlertaRecogida);
        }
      }
    });
  }
  quitarAlertas(alertaHija) {
    var contador = 0;
    this.alertas.forEach((alertaArray) => {
      var alertaDato = alertaArray.payload.doc.data();
      if (alertaDato['idAlerta'] == alertaHija.idAlerta) {
        this.alertas.splice(contador, 1);
        return;
      }
      contador++;
    });
  }
  boxInfoUser(idUser) {
    if (this.infoU == true) {
      this.infoU = false;
      this.user={avatar:"",uid:""};
    } else {
      this.infoU = true;
      this._userService.getUserById(idUser).subscribe((userInfo) => {
        this.user = userInfo[0].payload.doc.data();
        var arryId = new Array();
        arryId.push(this.user.uid);
        arryId.push(this.idEsteUsuario);
        arryId.sort();
        this.chat=arryId.join("|");
      });
    }
  }
}
