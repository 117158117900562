import { AppsInternasComponent } from './home/apps-internas/apps-internas.component';
import { MiseventosComponent } from './profile/eventos/miseventos/miseventos.component';
import { EditareventoComponent } from './profile/eventos/editarevento/editarevento.component';
import { EditarclaseComponent } from './clase/editarclase/editarclase.component';
import { CreargrupoComponent } from './misgrupos/creargrupo/creargrupo.component';
import { EventosComponent } from './profile/eventos/eventos.component';
import { MisgruposComponent } from './misgrupos/misgrupos.component';
import { FotoComponent } from './profile/foto/foto.component';
import { NotificacionesComponent } from './notificaciones/notificaciones.component';
import { PassComponent } from './profile/pass/pass.component';
import { AjustesComponent } from './profile/ajustes/ajustes.component';
import { HiloComponent } from './mensajes/hilo/hilo.component';
import { DashboardHomeComponent } from './home/home.component';
import { ArchivosComponent } from './archivos/archivos.component';
import { MensajesComponent } from './mensajes/mensajes.component';
import { ProfileComponent } from './profile/profile.component';
import { DashboardComponent } from './dashboard.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ClaseComponent } from './clase/clase.component';
import { AlertasComponent } from './alertas/alertas.component';
import { AlertaComponent } from './alertas/alerta/alerta.component';
import { MisalaModule } from './profile/misala/misala.module';




const routes: Routes = [
  {path: '', component: DashboardComponent,
    children: [
      {path: '', component: DashboardHomeComponent},
      {path: 'profile/:uid', component: ProfileComponent,
        children: [
          {path: '', component: AjustesComponent},
          {path: 'ajustes', component: AjustesComponent},
          {path: 'pass', component: PassComponent},
          {path: 'foto', component: FotoComponent},
          {path: 'eventos', component: EventosComponent},
          {path: 'miseventos', component: MiseventosComponent},
          {path: 'editarevento/:uid', component: EditareventoComponent},
          {path: 'misala',loadChildren: () => MisalaModule}
        ]
      },
      {path: 'mensajes', component: MensajesComponent,
        children: [
          {path: 'chat/:uid', component: HiloComponent},
        ]
      },
      {path: 'alertas', component: AlertasComponent,
    },
    {path: 'alertas/alerta/:idAlerta', component: AlertaComponent},
      {path: 'clase/:uid', component: ClaseComponent},
      {path: 'archivos/:uid', component: ArchivosComponent},
      {path: 'misgrupos', component: MisgruposComponent},
      {path: 'notificaciones', component: NotificacionesComponent},
      {path: 'creargrupo', component: CreargrupoComponent},
      {path: 'editarclase/:uid', component: EditarclaseComponent},
      {path: 'appsint/:url', component: AppsInternasComponent}
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DashboardRoutingModule { }
