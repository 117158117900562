<div>
<ng-container *ngIf="clase">
  <section class="basicbox clase">
     <div class="claseheader">
          <!-- clase box -->
                      <div class="subbasicbox chat headinguser">

                          <div class="boximage">

                            <img *ngIf="clase.imagen else defaultclassavatar" src="{{clase.imagen}}" alt="{{clase.nombre | titlecase }}">
                            <ng-template #defaultclassavatar><img src="assets/img/clase_default.jpg" alt="{{clase.nombre | titlecase }}"></ng-template>

                          </div>
                          <div class="boxtitles">
                                 <h4>{{clase.nombre | titlecase }}</h4>
                                 <p class="admins">Administradores de la clase</p>


                          </div>
                          <div class="rigthbuttons">
                           
                              <a routerLink="/dashboard/archivos/{{claseuid}}">
                                  Ver Archivos
                                  <span>
                                      <img src="/assets/img/folder.png" alt="">
                                  </span>
                              </a>
                              <ng-container *ngIf="clase.administradores.includes(userdata.uid)">
                              <a routerLink="/dashboard/editarclase/{{claseuid}}">
                                  Editar clase

                              </a>
                            </ng-container>

                          </div>

                          <div class="boxdata">

                               <div class="adminsgrid" *ngIf="this.administradores.length > 0">
                                   <div>
                                       <ul>

                                        <ng-container *ngFor="let admin of this.administradores |  slice:0:6; let i= index">

                                          <li>
                                            <a routerLink="/dashboard/profile/{{admin.uid}}">
                                                <img *ngIf="admin.avatar else defaultheadavatar" src="{{admin.avatar}}" alt="{alumno.nombres | titlecase }}">
                                                <ng-template #defaultheadavatar><img src="assets/img/defaultavatar.jpg" alt=""></ng-template>

                                            </a>
                                          </li>

                                        </ng-container>

                                       </ul>
                                   </div>
                                   <div>
                                      <p>
                                        <ng-container *ngFor="let admin of this.administradores |  slice:0:6; let last = last">

                                          {{admin.nombres | titlecase }}<span *ngIf="!last">,</span>

                                        </ng-container>
                                      </p>
                                   </div>
                               </div>

                          </div>


                      </div>
                      <!--/clase box -->
     </div>

      <div>
          <section class="basicbox chatusercol padtop">
              <header>
                  <div class="icon">
                      <img src="/assets/img/grupos-b.svg" alt="">
                  </div>
                  <h3>
                      Miembros
                  </h3>
              </header>

              <article>
                  <div class="search">
                     <img src="/assets/img/search.svg" alt="">
                      <input type="text" placeholder="Buscar usuarios "  [(ngModel)]="searchValue">
                  </div>

                  <div class="basicbox users results">

                    <app-userbox-clase *ngFor="let alumno of this.estudiantes | search:searchValue ;  trackBy:trackByArtNo" [mainuid]="currentuserid" [alumno]="alumno" [nombres]="nombres" [apellidos]="apellidos" ></app-userbox-clase>

                  </div>
              </article>
          </section>
      </div>
      <div class="userchat">


       <section class="basicbox padtop">

              <header>
                  <div class="icon">
                      <img src="/assets/img/chatbox-b.svg" alt="">
                  </div>
                  <h3>
                      Chat de la clase
                  </h3>
              </header>

           <section class="basicbox chatmessages" >

              <!--<div class="messages" id="themessagesbox" [scrollTop]="scrolltobottom()">

                <ng-container *ngFor="let messages of this.chatmessages; trackBy: trackFbObjects;">


                  <div class="message">
                      <div class="avatar">
                          <img *ngIf="messages.usuariodata.avatar else defaultheadavatar" src="{{messages.usuariodata.avatar}}" alt="{alumno.nombres}}">
                          <ng-template #defaultheadavatar><img src="assets/img/defaultavatar.jpg" alt=""></ng-template>
                          <div class="status"></div>
                      </div>
                      <div class="messagedata">
                          <div class="name">
                            {{messages.usuariodata.nombres}} {{messages.usuariodata.apellidos}}
                          </div>
                          <div class="date">
                            {{ messages.fecha | date: 'MM/dd/y - HH:mm' }}h
                          </div>
                          <div class="themessage">
                              <p>{{messages.message}}</p>
                          </div>
                      </div>
                  </div>

                </ng-container>

              </div>-->


              <div class="messages" id="themessagesbox" [scrollTop]="scrolltobottom()">
                <ng-container *ngIf="this.chatorimessa[0]">

                  <ng-container *ngIf="chatusers[this.chatorimessa[0].payload.doc.data().sender]">
                    <ng-container *ngFor="let messages of this.chatorimessa;" >


                      <div class="message">
                          <div class="avatar">
                              <ng-container *ngIf="chatusers[messages.payload.doc.data().sender]!=undefined">

                                <img *ngIf="chatusers[messages.payload.doc.data().sender].avatar else defaultheadavatar" src="{{chatusers[messages.payload.doc.data().sender].avatar}}" alt="{alumno.nombres | titlecase }}">
                                <ng-template ng-template #defaultheadavatar><img src="assets/img/defaultavatar.jpg"></ng-template>
                                <!--<div class="status"></div>-->

                            </ng-container>
                          </div>
                          <div class="messagedata">
                              <div class="name">
                                <ng-container *ngIf="chatusers[messages.payload.doc.data().sender]!=undefined">
                                  {{chatusers[messages.payload.doc.data().sender].nombres | titlecase }} {{chatusers[messages.payload.doc.data().sender].apellidos | titlecase }}
                                </ng-container>
                              </div>
                              <div class="date">
                                {{ messages.payload.doc.data().fecha.toDate() | date: 'dd/MM/y - HH:mm' }}h
                              </div>
                              <div class="themessage">
                                  <p>{{messages.payload.doc.data().message }}</p>
                              </div>
                          </div>
                      </div>

                    </ng-container>
                  </ng-container>
                </ng-container>

              </div>

              <div class="sendbox">
                  <input class="messagebox" type="text" placeholder="Escribir mensaje…." [(ngModel)]="messagechatclase" (keyup.enter)="sendmessageclase()">
                  <input class="sendmessage" type="submit" value="Enviar Mensaje" (click)="sendmessageclase()" >
              </div>

          </section>
       </section>
      </div>


  </section>

</ng-container>
</div>
