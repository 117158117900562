import { AppsService } from '../../services/apps.service';
import { ColegiosService } from '../../services/colegios.service';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { AuthenticationService } from '../../services/authentication.service';
import { App } from '../../interfaces/app';
import { ChatService } from './../../services/chat.service';
import { UserService } from './../../services/user.service';
import { createEvent, download } from './event-download.utils';
import { EventosService } from './../../services/eventos.service';
import { BreadcrumbService } from '../../services/breadcrumb.service';
import { HttpClient } from '@angular/common/http';
import { HttpParams } from '@angular/common/http';

import { AngularFireFunctions } from '@angular/fire/functions';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class DashboardHomeComponent implements OnInit {
  userdata: any;
  colegio: any;
  app: App;
  colegioapps_internas = [];
  colegioapps_externas = [];
  mainuseremail: any;
  userUid: any;
  nombres: any;
  apellidos: any;
  chats = [] as any;
  receiveruserchat: any;
  chatusers = [];
  grupodechats = [];
  events = [];
  content: any = '';
  eventos = [];
  locals: any;
  pexipdata: any;
  camaras: any = [];
  micros: any[];
  audios: any[];
  n: number = 0;
  apagado: boolean = false;
  deviceId: any;
  results: any = '';
  clases: any;
  searchValue = '';
  usuario:any;

  constructor(
    private authenticationService: AuthenticationService,
    private colegioService: ColegiosService,
    private appsService: AppsService,
    private chatService: ChatService,
    private userService: UserService,
    private breadService: BreadcrumbService,
    private http: HttpClient,
    private eventosService: EventosService,
    private functions: AngularFireFunctions
  ) {}

  ngOnInit(): void {
    const Userdata: any = this.userService.getUserinfodata();

    if (Userdata === undefined) {
      this.getmainuserdata();
    } else {
      this.userUid = Userdata.payload.doc.data().uid; /* id del usuario */
      this.mainuseremail =
        Userdata.payload.doc.data().email; /* email del usuario */
        this.usuario = Userdata.payload.doc.data().usuario;
      this.getroom(Userdata.payload.doc.data().email);

      this.userdata = Userdata.payload.doc.data();
      this.clases = Userdata.payload.doc.data().clases;
      this.nombres = this.userdata.nombres; /* nombres del usuario logueado */
      this.apellidos =
        this.userdata.apellidos; /*apellidos del usuario logueado */
      this.getApps(
        this.userdata
      ); /* llama a la funcion para obtener las apps del colegio */
      this.getLastChats(this.userUid); /* obtiene los ultimos mensajes */
    }
    this.camaras = new Array();
    var id = localStorage.getItem('deviceId');
    var apagado = localStorage.getItem('apagado');
    if (apagado == 'true') {
      this.apagado = true;
      this.videoMedias();
    } else {
      if (id) {
        this.videoMedias().then(() => {
          this.selectCamera(id);
          this.selectAudio(this.audios[0].deviceId)
          this.selectAudio(this.micros[0].deviceId) 
          this.cambiarNdeCamara(id);
        });
      } else {
        this.videoMedias().then(() =>{
          this.selectCamera(this.camaras[this.n].deviceId)
          this.selectAudio(this.audios[0].deviceId)
          this.selectAudio(this.micros[0].deviceId) 
        }
        );
      }
    }
  }

  cambiarNdeCamara(id) {
    for (let index = 0; index < this.camaras.length; index++) {
      if (id == this.camaras[index].deviceId) {
        this.n = index;
      }
    }
  }

  /* trae informacion principal del usuario logueado */
  getmainuserdata() {
    this.authenticationService.isAuth().subscribe((auth) => {
      if (auth) {
        this.userUid = auth.uid; /* id del usuario */
        this.mainuseremail = auth.email; /* email del usuario */
        this.getroom(auth.email);

        this.authenticationService
          .isUserAdmin(this.userUid)
          .subscribe((userdata) => {
            if (userdata[0]) {
              this.userdata = userdata[0];
              this.nombres =
                this.userdata.nombres; /* nombres del usuario logueado */
                this.usuario = this.userdata.usuario;
              this.apellidos =
                this.userdata.apellidos; /*apellidos del usuario logueado */
              this.clases = this.userdata.clases;
              this.getApps(
                this.userdata
              ); /* llama a la funcion para obtener las apps del colegio */
              this.getLastChats(
                this.userUid
              ); /* obtiene los ultimos mensajes */
            }
          });
      }
    });
  }

  /* obtiene las app del primer colegio(array) al que pertenezca el usuario logueado */
  getApps(userdata) {
    if (userdata && userdata.colegios[0]) {
      this.colegioService
        .getColegioById(userdata.colegios[0])
        .subscribe((colegio) => {
          if (colegio) {
            this.colegio = colegio.payload.data();

            this.colegioapps_internas = [];
            this.colegioapps_externas = [];

            if (this.colegio.apps) {
              this.colegio.apps.forEach((app: any) => {
                this.appsService.getAppById(app).subscribe((app) => {
                  if (app) {
                    this.app = app.payload.data();

                    if (this.app.tipo === 'interna') {
                      if (
                        !this.colegioapps_internas.some(
                          (el) => el.nombre === this.app.nombre
                        )
                      ) {
                        /* añade las apps internas a un array */
                        this.colegioapps_internas.push({
                          icono: this.app.icono,
                          nombre: this.app.nombre,
                          url: this.app.url,
                        });
                      }
                    } else {
                      if (
                        !this.colegioapps_externas.some(
                          (el) => el.nombre === this.app.nombre
                        )
                      ) {
                        /* añade las apps externas un array */
                        this.colegioapps_externas.push({
                          icono: this.app.icono,
                          nombre: this.app.nombre,
                          url: this.app.url,
                        });
                      }
                    }
                  }
                });
              });
            }
          }
        });
    }
  }

  /* obtiene los ultimos mensajes de chat */
  getLastChats(mainuserid) {
    this.chatService.getChats(mainuserid).subscribe((chat) => {
      chat.forEach((chatData: any) => {
        /* genera la id del chat */
        const messagechatid = [
          chatData.members[0].uid,
          chatData.members[1].uid,
        ].sort();
        const themeschaid = messagechatid.join('|');

        /* encuentra el usuario receptor o emisor que es el que no tiene la id del usuario logueado */
        let receiveruser;
        if (chatData.members[1].uid != mainuserid) {
          receiveruser = chatData.members[1].uid;
        } else {
          receiveruser = chatData.members[0].uid;
        }

        /* trae la informacion el usuario receptor o emisor */
        this.userService.getUserById(receiveruser).subscribe((user) => {
          this.receiveruserchat = user[0].payload.doc.data();
          /* guarda la informacion del usuario en un array usando su id como clave */
          this.chatusers[this.receiveruserchat.uid] =
            user[0].payload.doc.data();
          this.chats = chat; /* guarda todos los chats abiertos */
          this.getLastMessage(themeschaid, mainuserid);
        });
      });
    });
  }

  getLastMessage(chatid, mainuserid) {
    this.chatService.getLastMessage(chatid, mainuserid).subscribe((chat) => {
      if (chat[0]) {
        this.grupodechats[chatid] = chat[0].payload.doc.data();
      }
    });
  }

  /* funcion para descargar ics de eventos */
  download() {
    var click = true; //si el usuario cliquea en download comienza la función, y no se ve afectada la suscripcion de modificación del evento.

    /* añade todos los eventos al array para luego generar el archivo ics */
    this.eventosService.getEventos().subscribe((eventosdata) => {
      if (eventosdata !== undefined) {
        this.eventos = eventosdata;
        this.eventos.forEach((evento) => {
          this.events.push({
            start: evento.payload.doc
              .data()
              .fecha_inicio.toDate() /*fecha de inicio*/,
            end: evento.payload.doc
              .data()
              .fecha_final.toDate() /* fecha final */,
            summary: evento.payload.doc.data().nombre /* resumen */,
            description: evento.payload.doc.data().descripcion /*descripción */,
          });
        });
        this.content = createEvent(this.events);
        if (click) {
          download('thecampus-calendar.ics', this.content); /* genera archivo */
          click = false;
        }
      }
    });
  }

  UpdateBreadcrumb(name, url) {
    this.breadService.addtobread(name, url);
  }

  getroom(email) {
    this.http
      .get<any>(
        'https://us-central1-real-oviedo.cloudfunctions.net/getRoom',
        { params: new HttpParams().set('salaname', email) }
      )
      .subscribe((data) => {
        this.pexipdata = data;
      });
  }

  //////CÁMARA/////

  changeCamera() {
    if (this.n === this.camaras.length - 1) {
      this.videoMedias().then(() => {
        this.n = 0;
        this.selectCamera(this.camaras[this.n].deviceId);
        this.deviceId = this.camaras[this.n].deviceId;
      });
    } else {
      this.videoMedias().then(() => {
        this.n++;
        this.selectCamera(this.camaras[this.n].deviceId);
        this.deviceId = this.camaras[this.n].deviceId;
      });
    }
    this.apagado = false;
    localStorage.setItem('apagado', 'false');
  }
  async videoMedias() {
    const stream = await window.navigator.mediaDevices
      .enumerateDevices()
      .then((videoMedia) => {
        const todos = [];
        const camaraList = [];
        videoMedia.forEach((video) => {
          const type = video.kind;
          todos.push(video);
          if (type === 'videoinput') {
            camaraList.push(video);
          }
        });
        this.camaras = camaraList;
      });
  }
  async selectCamera(id) {
    var constraints = {
      deviceId: id,
      video: true,
      audio: true,
      width: { ideal: 1080 },
    };
    const video = await window.navigator.mediaDevices
      .getUserMedia({ video: constraints })
      .then((mediastream) => {
        const tracks = mediastream.getTracks();
        localStorage.setItem('deviceId', id);
        localStorage.setItem('apagado', 'false');
        document.querySelector('video').srcObject = mediastream;
        document.querySelector('video').play();
      })
      .catch(function (err) {});
  }

  async apagar() {
    this.eleccion();
  }
  eleccion() {
    if (this.apagado == true) {
      this.encenderCamara().then(() => {
        this.apagado = false;
        localStorage.setItem('apagado', 'false');
      });
    } else {
      this.apagarCamara().then(() => {
        this.apagado = true;
        localStorage.setItem('apagado', 'true');
      });
    }
  }

  async apagarCamara() {
    var constraints = {
      video: false,
      audio: false,
      width: { ideal: 2160 },
    };
    console.log('encendido');
    await window.navigator.mediaDevices
      .getUserMedia({ video: constraints, audio: true })
      .then((mediastream) => {
        document.querySelector('video').srcObject = mediastream;
        const tracks = mediastream.getTracks();
        tracks.forEach(function (track) {
          track.stop();
        });
      });
  }
  async encenderCamara() {
    this.videoMedias().then(() => {
      this.selectCamera(this.deviceId);
    });
  }

  ///  BUSCAR USUARIOS   ///
  search() {
    this.userService.searchUsers(this.clases).subscribe((searchdata) => {
      this.results = searchdata;
    });
  }

  openVideo(usuario) {
    window.open(
      'https://in.thecampus.education/webapp/#/?conference=' +
        usuario.usuario +
        '&name=' +
        this.userdata.nombres +
        ' ' +
        this.userdata.apellidos +
        '&role=guest&callType=video'
    );
  }
  async selectAudio(id) {
    var constraints = { deviceId: id, audio: true };
    const audio = await window.navigator.mediaDevices
      .getUserMedia({ audio: constraints })
      .then((mediastream) => {
        const tracks = mediastream.getAudioTracks();
      });
  }
}
