import { NotificacionesService } from './../../../../services/notificaciones.service';
import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../../../services/authentication.service';
import { EventosService } from '../../../../services/eventos.service';
import { UserService } from '../../../../services/user.service';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';


@Component({
  selector: 'app-miseventos',
  templateUrl: './miseventos.component.html',
  styleUrls: ['./miseventos.component.css']
})
export class MiseventosComponent implements OnInit {
  userUid: any;
  userdata: any;
  eventos = [];





  constructor(private eventosService: EventosService,
              private userService: UserService,
              private NotificacionesService: NotificacionesService,
              private breadService: BreadcrumbService,
              private authenticationService: AuthenticationService) { }


  ngOnInit(): void {
    const Userdata: any = this.userService.getUserinfodata();
 
    if (Userdata === undefined) {
      this.getmainuserdata();
    } else {
      this.userUid = Userdata.payload.doc.data().uid;
      this.eventosService.getEventosByinvited(this.userUid).subscribe(eventos => {
        this.eventos = eventos;
      });
    }
  }


  /* trae informacion principal del usuario logueado */
  getmainuserdata() {
    this.authenticationService.isAuth().subscribe(auth =>{
      if(auth) {
        this.userUid = auth.uid; /* id del usuario */

        this.eventosService.getEventosByinvited(this.userUid).subscribe(eventos => {
          this.eventos = eventos;
        });

      };
    });
  }

  
  UpdateBreadcrumb(name, url) {
    this.breadService.addtobread(name, url);
  }


}
