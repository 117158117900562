<!-- clase box -->
<div class="subbasicbox chat">

    <div class="boximage">
        <a routerLink="/admin/mensajes/chat/{{chaturl}}">
  
            <img *ngIf="theuser?.avatar else defaultheadavatar" src="{{theuser?.avatar}}" alt="{{theuser?.nombres | titlecase}} {{theuser?.apellidos | titlecase}}">
            <ng-template #defaultheadavatar>
              <img src="assets/img/defaultavatar.jpg" alt="{{theuser?.nombres | titlecase}} {{theuser?.apellidos | titlecase}}">
            </ng-template>
  
            <div class="status" [ngClass]="{
              'online': presencia === 'online',
              'away':  presencia === 'away',
              '':  presencia === 'offline'
            }">
            </div>
  
  
        </a>
    </div>
    <div class="boxtitles">
          <h4>{{theuser?.nombres | titlecase}} {{theuser?.apellidos | titlecase}}</h4>
          <p><!--<app-presencia [mainuid]="userUid" [members]="chat.members" [actividad]="true" ></app-presencia>--></p>
          <ng-container *ngIf="tiempoDesconexion">
            <p> Activo hace {{tiempoDesconexion}}</p>
          </ng-container>
  
    </div>
    <div>
      <div class="boxchatvideocall">
  
          <a href="https://in.thecampus.education/webapp/#/?conference={{theuser?.nombres | titlecase}} {{theuser?.apellidos | titlecase}}&name={{themainuser_nombre | titlecase}} {{themainuser_apellidos | titlecase}}&role=guest&callType=video" target="_blank">
            <img src="assets/img/videocall.svg" >
          </a>
  
  
      </div>
  
    </div>
  
    <div class="boxdata">
          <div class="lastchat">
  
                <p>{{ LastChatMessage?.message | slice:0:13 }}</p>
  
          </div>
    </div>
  
    <div class="boxvideocall">
            <div class="videocall">
              <a routerLink="/admin/mensajes/chat/{{chaturl}}">
                <img src="/assets/img/chatbox-b.svg">
              </a>
            </div>
    </div>
  </div>
  <!--/clase box -->
  