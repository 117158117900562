import { Component, OnInit, OnDestroy } from '@angular/core';
import { AlertasService } from 'src/app/services/alertas.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ColegiosService } from 'src/app/services/colegios.service';
import { UserService } from 'src/app/services/user.service';
import { AIService } from '../services/ai.service';
import { ChatService } from '../services/chat.service';
import { NumeroalertasService } from '../services/numeroalertas.service';



@Component({
  selector: 'app-alertas',
  templateUrl: './alertas.component.html',
  styleUrls: ['./alertas.component.css'],
})
export class AlertasComponent implements OnInit {
  alertas: Array<any>;
  alertasHistorial: Array<any>;
  menuHistorialActivo: boolean;
  fechaCheck: any;
  // uid: string;
  admin: any;
  palabras: Array<any>;
  mensajes: Array<any>;
  checkeo: Array<any>;
  chats = <any>[];
  idAdminOriginal: string;

  constructor(
    private _alertService: AlertasService,
    private authenticationService: AuthenticationService,
    private _userService: UserService,
    private _colegiosService: ColegiosService,
    private _numeroAlertas: NumeroalertasService,
    private _aiBullying: AIService,
    private _mensajes: ChatService
  ) {}

  ngOnInit(): void {
    this.getPalabras();
    
    this.getMensajes();
    this.getAlertas();
  }

  getAlertas() {
    this.alertas = new Array();
    this.alertasHistorial = new Array();
    this.menuHistorialActivo = false;
    this._alertService.getAlerts().subscribe((alerts) => {
      this.alertas.splice(0, this.alertas.length);
      this.alertasHistorial.splice(0, this.alertasHistorial.length);
      alerts.sort((a, b) => b.payload.doc.data()['fechaAlerta'] - a.payload.doc.data()['fechaAlerta']).forEach((alertaDato) => {
        const AlertaRecogida: any = alertaDato.payload.doc.data();

        if (AlertaRecogida['reportada'] == false) {
          this.alertas.push(alertaDato);
        } else {
          this.alertasHistorial.push(AlertaRecogida);
        }
      });
    });
  }

  //recoge las palabras bullying de la base de datos, para saber que palabras son consideradas bullying
  getPalabras() {
    this.palabras = new Array();
    this.palabras.length = 0;
    this._aiBullying.getWordsNow().subscribe((palabrasBBDD) => {
      palabrasBBDD.forEach((palabraU) => {
        var word = palabraU.data();
        this.palabras.push(word['palabra']);
      });
    });
  }

  getMensajes() {
    this._mensajes.getChatsNow().subscribe((chat) => {
      this.chargeAdmin();
      this.chats = chat;
      chat.forEach((chatData: any) => {
        var chatecillo = chatData.data();
        const messagechatid = [
          chatecillo.members[0].uid,
          chatecillo.members[1].uid,
        ].sort();
        const chatId = messagechatid.join('|');
        
        this._mensajes.getMessagesNow(chatId).subscribe((messages) => {
          messages.forEach((men) => {
            var menDatos = men.data();
            
            if (menDatos.fecha > this.fechaCheck) {
             
              var arrayDePalabras = menDatos.message.split(' ');

              var contieneBullying = false;
              for (let index = 0; index < arrayDePalabras.length; index++) {
                for (let indexJ = 0; indexJ < this.palabras.length; indexJ++) {
                  
                  if (this.palabras[indexJ] == arrayDePalabras[index]) {
                    contieneBullying = true;
               
                    break;
                  }
                }
              }

              if (contieneBullying) {
               
                this._userService
                  .getUserByIdNow(menDatos.sender)
                  .subscribe((users) => {
                    
                    users.forEach((user) => {
                     
                      var usu = user.data();
                      var idColegio = usu['colegios'][0];
                      var avaAlumno = usu['avatar'];

                      this._colegiosService
                        .getColegioByIdNow(idColegio)
                        .subscribe((colegio) => {
                         
                          var cole = colegio.data();
                          var nombreColegio = cole['nombre'];
                          var avaColegio = cole['avatar'];
                       

                          this._userService
                            .getUsersNow()
                            .subscribe((usuarios) => {
                          
                              usuarios.forEach((usuario) => {
                           
                                var usua = usuario.data();

                                if (
                                  usua['colegios'][0] == idColegio &&
                                  usua['rol'] == 'director'
                                ) {
                                  var idDirect = usua['uid'];

                                  this._numeroAlertas
                                    .getNumber()
                                    .subscribe((alerta) => {
                                      alerta.forEach((alertaDato) => {
                                        var alertita = alertaDato.data();
                                        var number: number = alertita.numero;
                                        this._numeroAlertas.editNumber();
                                        var alerta = {
                                          avatarColegio: avaColegio,
                                          estado: 'pendiente',
                                          fechaAlerta: menDatos.fecha,
                                          idAlerta: number,
                                          idColegio: nombreColegio,
                                          idDirector: idDirect,
                                          idUsuario: menDatos.sender,
                                          mensaje: menDatos.message,
                                          mostrarDirector: true,
                                          avatarAlumno: avaAlumno,
                                          reportada: false,
                                        };        
                                       
                                        this._alertService.getAlertaByMensajeAndName(alerta.mensaje,alerta.idUsuario,alerta.fechaAlerta).subscribe(alertas=>{
                                          if(alertas.size==0){
                                            this._alertService.createdAlerta(alerta);
                                          }
                                        })
                                      });
                                    })
                                }
                              });
                            });
                        });
                    });
                  });
              }
            }
          });
        });
      });
      //para que no haga el reload antes que los mensajes.
      setTimeout(() => {
        
          this.reloadDateCheck();

      }, 1000);
    });
  }

  changeMenu() {
    if (this.menuHistorialActivo) {
      this.menuHistorialActivo = false;
    } else {
      this.menuHistorialActivo = true;
    }
  }

  chargeAdmin() {
    this.authenticationService.isAuth().subscribe((auth) => {
  
      var uid = auth.uid;
      
      this._userService.getUserById(uid).subscribe((user) => {
        var userAdmin = user[0].payload.doc.data();
        this.fechaCheck = userAdmin['fechaCheck'];
      });
    });
  }

  reloadDateCheck() {
    this.authenticationService.isAuth().subscribe((auth) => {
      var uid = auth.uid;
      this._userService.getUserByIdNow(uid).subscribe((us) => {
        us.forEach((user) => {
          var userAdmin = user.data();
          var id = user.id;
          var current = this.getFecha();
          if (current != userAdmin['fechaCheck']) {
            userAdmin['fechaCheck'] = current;
            this._userService.editUser(userAdmin, id);
          }
        });
      });
    });
  }

  getFecha() {
    const current = new Date();
    current.setHours(current.getHours());
    current.setMinutes(current.getMinutes());
    current.setSeconds(0);
    current.setMilliseconds(0);
    return current;
  }
  
}
