import { Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import { UserService } from '../../../services/user.service';
import { ChatService } from '../../../services/chat.service';

@Component({
  selector: 'app-userbox-chat',
  templateUrl: './userbox-chat.component.html',
  styleUrls: ['./userbox-chat.component.css']
})
export class UserboxChatComponent implements OnChanges {
  @Input() mainuid;
  @Input() members;
  @Input() chatusers;
  @Input() grupodechats;
  @Input() actividad;
  @Input() chatactive;
  @Input() nombres;
  @Input() apellidos;

  presencia: any;
  tiempoDesconexion: any;
  chats: any;

  constructor(private userService: UserService,
              private chatService: ChatService) { }

  ngOnChanges(changes: SimpleChanges) {
    const members = changes.members.currentValue;
    const mainuid = changes.mainuid.currentValue;
    this.chats = changes.chatactive.currentValue;

    const uid = members[1].uid === mainuid ? members[0].uid : members[1].uid;

    this.getuserpresence(uid);

  }

  get theuser(){
    return this.chatusers[
      this.chatusers[
                      this.members[1]?.uid === this.mainuid
                      ? this.members[0]?.uid : this.members[1]?.uid
                    ]
      ?.uid];
  }

  get themainuser_nombre(){
    return this.nombres;
  }

  get themainuser_apellidos(){
    return this.apellidos;
  }

  get chaturl(){
    return  [this.mainuid, this.members[1].uid === this.mainuid ? this.members[0].uid : this.members[1].uid].sort().join('|');
  }

  get LastChatMessage(){
    return  this.grupodechats[[this.members[0]?.uid, this.members[1]?.uid].sort().join('|')];
  }



  getuserpresence(uid) {
    if (uid) {

      this.userService.getPresence(uid).subscribe(presencia => {
        if (presencia != null) {
          this.presencia = presencia;
          this.tiempoDesconexion = this.timeDifference(Math.floor(new Date().getTime()/1000.0), this.presencia.timestamp );
          this.presencia = this.presencia.status;
        }
      });

    }
  }

  timeDifference(date1, date2) {
    if(date1 > Math.floor(date2/1000.0)) {
      var difference =  date1 - Math.floor(date2/1000.0);
    } else  {
      var difference =  Math.floor(date2/1000.0) - date1;
    }

    var seconds = Number(difference);
    var d = Math.floor(seconds / (3600*24));
    var h = Math.floor(seconds % (3600*24) / 3600);
    var m = Math.floor(seconds % 3600 / 60);
    var s = Math.floor(seconds % 60);

    var dDisplay = d > 0 ? d + (d == 1 ? " día " : " días ") : "";
    var hDisplay = h > 0 ? h + (h == 1 ? " hora " : " horas ") : "";
    var mDisplay = m > 0 ? m + (m == 1 ? " minuto " : " minutos ") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? " segundo" : " segundos") : "";

    var content = '';

    if (s > 0) {
      content = sDisplay;
    }
    if (m > 0) {
      content = mDisplay;
    }
    if (h > 0) {
      content = hDisplay;
    }
    if (d > 0) {
      content = dDisplay;
    }

    return content;
  }

  borrarchat(mainuid, theuser){
    const data = {
      mainuid, theuser
    };
    this.chatService.borrarelChat(data);
  }

}
