<div class="loginwrap">
  <div class="loginbox support">
      <header>
          <div class="support">
              <p>
                  Formulario de <br> Soporte para Usarios
              </p>
          </div>
          <div class="title">
              <h1>Apoyo y soporte</h1>
          </div>
      </header>
      <article>

         <div class="formwrp">
             <div>
                 <label for="">
                     Nombre
                 </label>
             </div>
             <div>
                 <input         
                    type="text" 
                    placeholder="Nombre"
                    [(ngModel)]="nombre"
                 >
             </div>

             <div>
                 <label for="">
                  Apellidos
                 </label>
             </div>
             <div>
                 <input 
                    type="text" 
                    placeholder="Apellidos"
                    [(ngModel)]="apellidos"
                 >
             </div>

             <div>
                 <label for="">
                  Correo Electrónico
                 </label>
             </div>
             <div>
                 <input 
                    type="email"
                    placeholder="Email"
                    [(ngModel)]="email"
                 >
             </div>

             <div>
                 <label for="">
                  Nombre de usuario
                 </label>
             </div>
             <div>
                 <input 
                    type="text" 
                    placeholder="Nombre de usuario"
                    [(ngModel)]="usuario"
                 >
             </div>

             <div>
                 <label for="">
                     ¿En que podemos ayudarte?
                 </label>
             </div>
             <div>
                 <textarea 
                    cols="30" 
                    rows="10" 
                    placeholder="Escribe tu mensaje…"
                    [(ngModel)]="texto"
                 ></textarea>
             </div>

            </div>
            <div class="submit">
               <div>
                   <input type="submit" value="enviar" (click)="sendEmail()">
               </div>
               <h2 id="mensaje"> {{mensaje}}</h2>
            </div>
            
      </article>
      <footer>

      </footer>
  </div>
</div>
