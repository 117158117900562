<!-- mensajes -->
<div class="mensajes">
  <section class="basicbox2 mensajes">

      <div>
          <section class="basicbox2 chatusercol">
              <header>
                  <div class="icon">
                      <img src="assets/img/chatbox-b.svg" alt="">
                  </div>
                  <h3>
                      Mis mensajes
                  </h3>
              </header>

              <article>
                  <div class="search">
                     <img src="assets/img/search.svg" alt="">
                      <input type="text" placeholder="Buscar usuarios " (keyup)="search()"  [(ngModel)]="searchValue">
                      <div class="resulstsearch" *ngIf="results!='' && searchValue">
                        <ng-container *ngFor="let result of results | search2:searchValue" >
                          <ng-container *ngIf="result.payload.doc.data().uid != mainuserid">
                            <p (click)="openchat(result.payload.doc.data())">{{result.payload.doc.data().nombres}} {{result.payload.doc.data().apellidos}}</p>
                          </ng-container>
                        </ng-container>
                      </div>
                  </div>

                  <div class="basicbox2 users results">

                    <app-userbox-chat *ngFor="let chat of chats;" [mainuid]="mainuserid" [members]="chat.members" [chatusers]="chatusers" [grupodechats]="grupodechats" [chatactive]="chatactive" [nombres]="nombres" [apellidos]="apellidos"></app-userbox-chat>

                  </div>
              </article>
          </section>
      </div>
      <div>
        <router-outlet>
        </router-outlet>
      </div>


  </section>
</div>
<!-- /mensajes -->
