<section class="basicbox fondo evento">

  <header class="headerperfil padtop">
    <div class="icon">
      <div class="wrapicon">
        <img src="/assets/img/calendar-b.svg" />
      </div>
    </div>
    <h3>Eventos</h3>
  </header>

  <div class="submenu">
    <ul>
      <li class="active" >Crear eventos</li>
      <li id="misEventos" routerLink="/dashboard/profile/{{ userUid }}/miseventos/" (click)="UpdateBreadcrumb('Mis Eventos', '/dashboard/profile/'+ userUid +'/miseventos/')">
        Mis eventos
      </li>
    </ul>
  </div>

  <div *ngIf="ocultarForm==false; else elsebox" class="basicbox uploadfile">

    <div class="formgroup">
      <label for="idnombres">Nombre del evento </label>
      <input
        id="idnombres"
        class="form-control"
        type="text"
        placeholder="Escribir.."
        [(ngModel)]="nombre"
      />
    </div>
    <div class="formgroup">
      <label for="idapellidos">Descripción del evento (144 caracteres máximo)</label>
      <textarea class="form-control" maxlength="144" [(ngModel)]="descripcion"></textarea>
    </div>

    <div *ngIf="clases != undefined">
      <div class="formgroup">
        <label for="idgenero">Clase</label>
        <select
        id="idgenero"
        class="form-control"
        [(ngModel)]="clase"
        (change)="selectclass($event.target.value)"
        >
        <ng-container *ngFor="let clase of clases">
          <option value="{{ clase?.payload.id }}">
            {{ clase?.payload.data().nombre |titlecase }}
          </option>
        </ng-container>
      </select>
    </div>
   

    
  </div>
  
  <div *ngIf="selectuser.length > 0">
    <div class="formgroup">
      <label for="idgenero">Invitados</label>
      <select
      id="idgenero"
      class="form-control"
      [(ngModel)]="invitados"
      (change)="selectinvitados($event.target.value)"
      >
      <option value="clase">Invitar a toda la clase o grupo</option>
      <option value="usuarios">
        Seleccionar algunos usuarios de la clase o grupo
      </option>
    </select>
  </div>
</div>

<div *ngIf="invitados === 'usuarios'">
  <div class="formgroup">
    <label for="idgenero">Estudiantes</label>
    <div class="basicbox estu">
      <div class="checkbox-inline" *ngFor="let user of selectuser">
        <input
          type="checkbox"
          name="{{user?.payload.doc.data().uid }}"
          [value]="user?.payload.doc.data().uid"
          (change)="getUserId($event, user?.payload.doc.data().uid)"
        />
          {{ user?.payload.doc.data().nombres  |titlecase}}
          {{ user?.payload.doc.data().apellidos |titlecase }}
          
      </div>
    </div>

  </div>
</div>

<div class="formgroup">
  <label for="idgenero">¿Es una videoconferencia?</label>
  <select
  id="idgenero"
  class="form-control"
  [(ngModel)]="videollamada"
  >
  <option selected value="no">No</option>
  <option value="si">Si</option>

</select>
</div>

<div *ngIf="videollamada === 'si'">
  <div class="formgroup">
    <label for="iddiremail">Email </label>
    <input
      id="iddiremail"
      class="form-control"
      type="email"
      placeholder="Escribir.."
      [(ngModel)]="email"
      (change)="addEmail()"
    />
  </div>
</div>
<span class="novalido">{{formatoEmailMensaje}}</span>
<div class="emailsContainer">
  <div class = "listEmails" *ngFor="let item of emails">
      <p class="emailText">{{item}}</p>
      <img
        id="close"
        src="assets/img/close.svg"
        alt="close"
        (click)="quitarEmail(item)" 
      />
  </div>

</div>

<div class="colw2">
  <div>
    <div class="formgroup">
      <label for="idfechanac">Fecha en la que comienza</label>
      <input
      id="idfechanac"
      class="form-control"
      type="date"
      placeholder="Escribir.."
      [(ngModel)]="fechainicio"
      />
    </div>
    
    <div class="formgroup">
      <label for="idpais">Hora en la que comienza</label>
      <input
      id="idpais1"
      class="form-control"
      type="time"
      [(ngModel)]="horainicio"
      
      />

    </div>
  </div>
  <div>
    <div class="formgroup">
      <label for="idfechanac">Fecha en la que termina</label>
      <input
      id="idfechanac"
      class="form-control"
      type="date"
      placeholder="poner.."
      [(ngModel)]="fechafinal"
      />
    </div>
    
    <div class="formgroup">
      <label for="idpais">Hora en la que termina</label>
      <input
      id="idfechanac"
      class="form-control"
      type="time"
      [(ngModel)]="horafinal"
    
      />
    </div>
  </div>
</div>

    <p style="color:#ce4937;;">{{ notificacion }}</p>
    <input
      type="submit"
      id="buttonEventos"
      value="Enviar"
      [disabled]="clicked"
      (click)="createevent(); clicked = false"
    />
  </div>
  <ng-template #elsebox >
    <div class="respuesta">
      <p >{{ notificacion }}</p>
    </div>
    
  </ng-template>

</section>