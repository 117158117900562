<div class="loginwrap">
    <div class="loginbox">
        <header>
            <div class="logo">
                <img src="assets/img/logocolor.png" alt="">
            </div>
            <div class="title">
                <h1>Acceso a the campus </h1>
            </div>
        </header>
        <article>
  
           <div class="loginform">
               <label for="">
                   Usuario
               </label>
               <input [(ngModel)]="usuario" class="user" type="text" placeholder="Usuario" >
               <label for="">
                   Contraseña
               </label>
               <input [(ngModel)]="password" class="pass" type="password" placeholder="Contraseña" (keyup.enter)="login()">
  
               <div class="loginbuttons">
                   <div>
                       <input class="acceso" type="submit" value="ACCESO" (click)="login()" >
                   </div>
                   <div class="rembox">
                       <div class="rememberme">
                           <div [ngClass]="{ 'checked' : remember }" class="checkbox" (click)="rememberme()">
  
                           </div>
                           <p (click)="rememberme()"> Recordarme</p>
                       </div>
                   </div>
               </div>
           </div>
  
        </article>
        <footer>
            <a routerLink="/olvidar" class="forget">
                Has olvidado la contraseña?
            </a>
            <a routerLink="/soporte" class="help">
                Help Desk
            </a>
        </footer>
    </div>
  </div>
  
  <div class="sm-video">
  
    <video id="video_background" loop muted autoplay oncanplay="this.play()" onloadedmetadata="this.muted = true">
    <source src="assets/video/thecampus.mp4" type="video/mp4">
    </video>
  
  </div>
  