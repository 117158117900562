<!-- header -->
<header id="mainheader">
  <div></div>
  <div class="saludo">
    <ng-container *ngIf="nombres && apellidos">
      <p>
        Bienvenido(a)
        <span>{{ nombres | titlecase }} {{ apellidos | titlecase }}</span>
      </p>
    </ng-container>
  </div>

  <div class="logo">
    <img src="assets/img/logo.png" alt="" />
  </div>
</header>
<!-- /header -->
