<!-- header -->
<header id="mainheader">
  <div></div>
  <div class="saludo" >
  </div>
  <div class="logo">
     <img src="assets/img/logo.png" alt="">
  </div>
</header>
<!-- /header -->

<section class="basicbox clase grupo" *ngIf="mode === 'resetPassword'">
  <header>
    <div class="icon">
      <div class="wrapicon">
        <svg style="padding-left: 0.781vw" xmlns="http://www.w3.org/2000/svg" width="46.03" height="16.1" viewBox="0 0 46.03 16.1">
          <g id="Grupo_9468" data-name="Grupo 9468" transform="translate(-7.94 -19.399)" opacity="0.9">
            <path id="Trazado_9254" data-name="Trazado 9254" d="M23.957,41.2h9.315v3.45a1.15,1.15,0,0,0,2.3,0V41.2h4.6v5.749a1.15,1.15,0,0,0,2.3,0v-6.9a1.149,1.149,0,0,0-1.149-1.15H23.957a8.049,8.049,0,1,0,0,2.3Zm-13.682-1.15A5.749,5.749,0,1,1,16.024,45.8,5.749,5.749,0,0,1,10.274,40.049Z" transform="translate(0 -12.599)" fill="#2D3453"/>
            <path id="Trazado_9255" data-name="Trazado 9255" d="M51.433,27Z" transform="translate(2.537 1.599)" fill="#2D3453"/>
          </g>
        </svg>
      </div>
    </div>
    <h3>Ajustes de Mail y Contraseña </h3>
  </header>


  <ng-container *ngIf="confirmed">
    <h4>Cambiar contraseña </h4>

    <section class="basicbox changepass">

      <div class="formgroup">
          <label for="idnewpass1">Escribe tu nueva contraseña </label>
          <input id="idnewpass1" class="form-control" type="password" placeholder="Introduce contraseña…" [(ngModel)]="newPassword">
      </div>

      <p></p>
      <input type="submit" value="Cambiar contraseña" (click)="changepass()" >

    </section>
  </ng-container>


  <div class="confirmacion" *ngIf="changed">
    <p>
      La nueva contraseña ha sido registrada.
    </p>

    <div class="basicbox confrbox">
      <img src="assets/img/checkok.svg">
      <p></p>
    </div>

    <a class="button btncenter" routerLink="" >OK</a>
  </div>

  <div class="licensiaerror" *ngIf="!changed && changed != null">
    <p>
      {{response}}
    </p>

    <div class="basicbox confrbox">
      <img src="assets/img/xerror.svg">
      <p>Error</p>
    </div>

  </div>

  <p><br></p>



</section>



<section class="basicbox clase grupo" *ngIf="mode === 'verifyEmail'">
  <header>
    <div class="icon">
      <div class="wrapicon">
        <svg style="padding-left: 0.781vw" xmlns="http://www.w3.org/2000/svg" width="46.03" height="16.1" viewBox="0 0 46.03 16.1">
          <g id="Grupo_9468" data-name="Grupo 9468" transform="translate(-7.94 -19.399)" opacity="0.9">
            <path id="Trazado_9254" data-name="Trazado 9254" d="M23.957,41.2h9.315v3.45a1.15,1.15,0,0,0,2.3,0V41.2h4.6v5.749a1.15,1.15,0,0,0,2.3,0v-6.9a1.149,1.149,0,0,0-1.149-1.15H23.957a8.049,8.049,0,1,0,0,2.3Zm-13.682-1.15A5.749,5.749,0,1,1,16.024,45.8,5.749,5.749,0,0,1,10.274,40.049Z" transform="translate(0 -12.599)" fill="#2D3453"/>
            <path id="Trazado_9255" data-name="Trazado 9255" d="M51.433,27Z" transform="translate(2.537 1.599)" fill="#2D3453"/>
          </g>
        </svg>
      </div>
    </div>
    <h3>Verificación de correo </h3>
  </header>


  <div class="confirmacion" *ngIf="changed">
    <p>
      El correo ha sido verificado
    </p>

    <div class="basicbox confrbox">
      <img src="assets/img/checkok.svg">
      <p></p>
    </div>

    <a class="button btncenter" routerLink="" >OK</a>
  </div>

  <div class="licensiaerror" *ngIf="!changed && changed != null">
    <p>
      {{response}}
    </p>

    <div class="basicbox confrbox">
      <img src="assets/img/xerror.svg">
      <p>Error</p>
    </div>

  </div>

  <p><br></p>
</section>




<section class="basicbox clase grupo" *ngIf="mode === 'recoverEmail'">
  <header>
    <div class="icon">
      <div class="wrapicon">
        <svg style="padding-left: 0.781vw" xmlns="http://www.w3.org/2000/svg" width="46.03" height="16.1" viewBox="0 0 46.03 16.1">
          <g id="Grupo_9468" data-name="Grupo 9468" transform="translate(-7.94 -19.399)" opacity="0.9">
            <path id="Trazado_9254" data-name="Trazado 9254" d="M23.957,41.2h9.315v3.45a1.15,1.15,0,0,0,2.3,0V41.2h4.6v5.749a1.15,1.15,0,0,0,2.3,0v-6.9a1.149,1.149,0,0,0-1.149-1.15H23.957a8.049,8.049,0,1,0,0,2.3Zm-13.682-1.15A5.749,5.749,0,1,1,16.024,45.8,5.749,5.749,0,0,1,10.274,40.049Z" transform="translate(0 -12.599)" fill="#2D3453"/>
            <path id="Trazado_9255" data-name="Trazado 9255" d="M51.433,27Z" transform="translate(2.537 1.599)" fill="#2D3453"/>
          </g>
        </svg>
      </div>
    </div>
    <h3>Restauración de correo </h3>
  </header>


  <div class="confirmacion" *ngIf="changed">
    <p>
      El correo ha sido restaurado.
    </p>

    <div class="basicbox confrbox">
      <img src="assets/img/checkok.svg">
      <p></p>
    </div>

    <a class="button btncenter" routerLink="" >OK</a>
  </div>

  <div class="licensiaerror" *ngIf="!changed && changed != null">
    <p>
      {{response}}
    </p>

    <div class="basicbox confrbox">
      <img src="assets/img/xerror.svg">
      <p>Error</p>
    </div>

  </div>

  <p><br></p>
</section>
