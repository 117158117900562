
<ng-container *ngIf="user && alerta==false">

  <section class="basicbox fondo">
    <header class="headerperfil padtop">
      <div class="icon">
          <div class="wrapicon">
              <img src="/assets/img/cog.svg" alt="">
          </div>
      </div>
      <h3>
         Mis ajustes de perfil
      </h3>
    </header>
      <div class="basicbox uploadfile">
      <div class="formgroup">
      <label for="idnombres">Nombres</label>
      <input  id="idnombres" class="form-control" type="text" [(ngModel)]="nombres" id=
      "disabled" disabled>
      </div>

      <div class="formgroup">
      <label for="idapellidos">Apellidos</label>
      <input id="idapellidos" class="form-control" type="text" [(ngModel)]="apellidos" id="disabled" disabled>
      </div>

      <div class="formgroup">
        <label for="idtelefono">Teléfono</label>
        <input id="idtelefono" class="form-control" type="text" placeholder="Escribir.." [(ngModel)]="telefono">
      </div>

      <div class="formgroup">
        <label for="idfechanac">Fecha de nacimiento</label>
        <input id="idfechanac" class="form-control" type="date" placeholder="Escribir.." [(ngModel)]="fechanac">
      </div>

      <div class="formgroup">
        <label for="idpais">País</label>
        <input id="idpais" class="form-control" type="text" placeholder="Escribir.." [(ngModel)]="pais">
      </div>

      <div class="formgroup">
      <label for="idgenero">Género</label>
      <input type=text id="idgenero" class="form-control" [(ngModel)]="genero" id="disabled" disabled 
      >
      </div>
      <input id="buttonAjustes" type="submit" value="Guardar cambios" (click)="saveSettings()">
    </div>
  </section>
</ng-container>

<ng-container *ngIf="alerta==true">
  <div class="respuesta">
    <p>{{saveresponse}}</p>
  </div>
</ng-container>