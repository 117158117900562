<header class="headerperfil padtop">
  <div class="icon">
      <div class="wrapicon">
          <img src="/assets/img/calendar-b.svg">
      </div>
  </div>
  <h3>
    Eventos
  </h3>
</header>

<div class="submenu">
  <ul>
    <li routerLink="/dashboard/profile/{{userUid}}/eventos/">
      Crear eventos
    </li>
    <li routerLink="/dashboard/profile/{{userUid}}/miseventos/">
      Mis eventos
    </li>
  </ul>
</div>

<section class="basicbox uploadfile">

  <div class="formgroup">
    <label for="idnombres">Nombre del evento </label>
    <input  id="idnombres" class="form-control" type="text" placeholder="Escribir.." [(ngModel)]="nombre">
  </div>

  <div class="formgroup">
    <label for="idapellidos">Descripción del evento</label>
    <textarea class="form-control" [(ngModel)]="descripcion"></textarea>
  </div>

  <ng-container *ngIf="clases != undefined">
  <div class="formgroup">
    <label for="idgenero">Clase</label>
    <select id="idgenero" class="form-control" [(ngModel)]="clase" (change)="selectclass($event.target.value)">
      <ng-container *ngFor="let clase of clases;" >
        <option value="{{clase?.payload.id}}">{{clase?.payload.data().nombre}}</option>
      </ng-container>
    </select>
  </div>
  </ng-container>

  <ng-container *ngIf="selectuser.length > 0">
    <div class="formgroup">
      <label for="idgenero">Invitados</label>
      <select id="idgenero" class="form-control" [(ngModel)]="invitados" (change)="selectinvitados($event.target.value)">
          <option value="clase">Invitar a toda la clase o grupo</option>
          <option value="usuarios">Seleccionar algunos usuarios de la clase o grupo</option>
      </select>
    </div>
  </ng-container>

  <ng-container *ngIf="invitados === 'usuarios'">
    <div class="formgroup">
      <label for="idgenero">Estudiantes</label>
      <div class="basicbox estu">
        <div class="checkbox-inline" *ngFor="let user of selectuser">
          <input
            type="checkbox"
            name="{{user?.payload.doc.data().uid }}"
            [value]="user?.payload.doc.data().uid"
            (change)="getUserId($event, user?.payload.doc.data().uid)"
          />
            {{ user?.payload.doc.data().nombres  |titlecase}}
            {{ user?.payload.doc.data().apellidos |titlecase }}
            
        </div>
      </div>
  
    </div>
  </ng-container>
  <div class="formgroup">
    <label for="idgenero">¿Es una videoconferencia?</label>
    <select
    id="idgenero"
    class="form-control"
    [(ngModel)]="videollamada"
    >
    <option selected value="no">No</option>
    <option value="si">Si</option>
  
  </select>
  </div>
  
  <div *ngIf="videollamada === 'si'">
    <div class="formgroup">
      <label for="iddiremail">Email </label>
      <input
        id="iddiremail"
        class="form-control"
        type="email"
        placeholder="Escribir.."
        [(ngModel)]="email"
        (change)="addEmail()"
      />
    </div>
  </div>
  <span>{{formatoEmailMensaje}}</span>
  <div class = "listEmails" *ngFor="let item of emails">
    <p class="emailText">{{item}}</p>
    <button class="botonEliminar" (click)="quitarEmail(item)" >X</button>
  </div>

  <div class="colw2">
    <div>

      <div class="formgroup">
        <label for="idfechanac">Fecha en la que comienza</label>
        <input id="idfechanac" class="form-control" type="date" placeholder="Escribir.." [(ngModel)]="fechainicio">
      </div>


      <div class="formgroup">
        <label for="idpais">Hora en la que comienza</label>
        <input id="idpais" class="form-control" type="time" [(ngModel)]="horainicio"   >
        
      </div>

    </div>
    <div>

      <div class="formgroup">
        <label for="idfechanac">Fecha en la que termina</label>
        <input id="idfechanac" class="form-control" type="date" placeholder="Escribir.." [(ngModel)]="fechafinal">
      </div>

      <div class="formgroup">
        <label for="idpais">Hora en la que termina</label>
        <input id="idpais" class="form-control" type="time"   [(ngModel)]="horafinal" >
        
     
      </div>

    </div>
  </div>
  <p>{{notificacion}}</p>
  <input type="submit" value="Enviar" (click)="createevent()">

</section>
