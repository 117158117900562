import { LoginComponent } from './login/login.component';
import { AppRoutingModule } from './app-routing.module';
import { AuthenticationGuard } from './services/authentication.guard';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { Routes, RouterModule } from '@angular/router';

import { UserService } from './services/user.service';
import { AngularFireModule } from '@angular/fire';
import { environment } from '../environments/environment';
import { AngularFireAnalyticsModule } from '@angular/fire/analytics';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { RegistroComponent } from './registro/registro.component';
import { OlvidarpassComponent } from './olvidarpass/olvidarpass.component';
import { SoporteComponent } from './soporte/soporte.component';
import { AngularFireFunctionsModule } from '@angular/fire/functions';
import { PasswordresetComponent } from './auth_handlers_page/passwordreset.component';
import {HttpClientModule} from '@angular/common/http';
import { RegistroAcademicaComponent } from './registro-academica/registro-academica.component';
import { ExcelService } from './services/excel.service';
import { DatePipe } from '@angular/common';
import { RedirectComponent } from './redirect/redirect.component';






/*const appRoutes: Routes = [
  {path: '', component: DashboardComponent, canActivate: [AuthenticationGuard]},
  {path: 'dashboard', component: DashboardComponent, canActivate: [AuthenticationGuard]},
  {path: 'login', component: LoginComponent},
  {path: 'conversation/:uid', component: ConversationComponent, canActivate: [AuthenticationGuard]},
  {path: 'profile', component: ProfileComponent, canActivate: [AuthenticationGuard]}
];*/

@NgModule({
  imports:      [ BrowserAnimationsModule, BrowserModule, FormsModule, AppRoutingModule , AngularFireModule.initializeApp(environment.firebase), AngularFireAnalyticsModule, AngularFirestoreModule, AngularFireDatabaseModule, AngularFireFunctionsModule, HttpClientModule],
  declarations: [ AppComponent, LoginComponent, RegistroComponent, OlvidarpassComponent, SoporteComponent, PasswordresetComponent, RegistroAcademicaComponent,RedirectComponent],
  bootstrap:    [ AppComponent ],
  providers: [UserService, ExcelService,DatePipe],
  //schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class AppModule { }
