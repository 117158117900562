import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AIService {

  constructor(private firestore: AngularFirestore) {}

  getStrings() {
      return this.firestore.collection('ai_strings', ref => ref.orderBy('type', 'desc')).valueChanges();
  }

  saveString(data) {
    return this.firestore.collection('ai_strings').add(data);
    // return this.firestore.collection('chats').doc(chatid).collection('messages').snapshotChanges([ 'added' ]);
  }
  addData(data) {
    return this.firestore.collection('IA_Bullyng').add(data);
    // return this.firestore.collection('chats').doc(chatid).collection('messages').snapshotChanges([ 'added' ]);
  }
  getWords(){
    return this.firestore.collection('IA_Bullyng').snapshotChanges();
  }
  getWordsNow(){
    return this.firestore.collection('IA_Bullyng').get();
  }
}

