import { Component, Input, OnInit } from '@angular/core';
import { EventosService } from 'src/app/services/eventos.service';
import { NotificacionesService } from 'src/app/services/notificaciones.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-evento-box',
  templateUrl: './evento-box.component.html',
  styleUrls: ['./evento-box.component.css'],
})
export class EventoBoxComponent implements OnInit {
  @Input() evento;
  @Input() userUid;
  fechaActual;
  borrarAlerta: boolean = false;
  asistira = false;
  noasistira = false;
  verParticipantes: boolean = false;
  eventoDatos = '';
  creador: any = '';
  asistentes = [];
  noasistentes = [];
  emails = new Array()
  fechaFinal:any;
  participantesTotales:number = 0;
  estudiantes: any=new Array();


  constructor(
    private eventosService: EventosService,
    private userService: UserService,
    private NotificacionesService: NotificacionesService
  ) {}

  ngOnInit(): void {
    this.getFecha();


    this.asistira = false;
    this.noasistira = false;
    this.eventoDatos = this.evento.payload.doc.data();
    if(this.evento.payload.doc.data().emails){   this.emails = this.evento.payload.doc.data().emails}
    if(this.evento.payload.doc.data().estudiantes){ this.estudiantes = this.evento.payload.doc.data().estudiantes}
   
    if(this.estudiantes){this.participantesTotales+=this.estudiantes.length}
    if(this.emails){this.participantesTotales+=this.emails.length}
    
    this.fechaFinal = new Date(this.eventoDatos['fecha_final'].seconds*1000);

    this.evento.payload.doc.data().asistentes.forEach((element) => {
      this.userService.getUserById(element).subscribe((item) => {
        if (item.length > 0) {
          var it = item[0].payload.doc.data();
          this.asistentes.push(it);
        }
      });
    });
    //Comprueba si el usuario esta en el listado de gente que asistirá
    this.eventosService
      .getConfirmadoAsistir(this.userUid, this.evento.payload.doc.id)
      .subscribe((element) => {
        element.forEach((event) => {
          if (event.payload.doc.id == this.evento.payload.doc.id) {
            this.asistira = true;
          }
        });
      });
    //Comprueba si el usuario esta en el listado de gente que no asistirá
    this.eventosService
      .getConfirmadoNoAsistir(this.userUid, this.evento.payload.doc.id)
      .subscribe((element) => {
        element.forEach((event) => {
          if (event.payload.doc.id == this.evento.payload.doc.id) {
            this.noasistira = true;
          }
        });
      });
    this.evento.payload.doc.data().noasistentes.forEach((element) => {
      this.userService.getUserById(element).subscribe((item) => {
        if (item.length > 0) {
          var it = item[0].payload.doc.data();
          this.noasistentes.push(it);
        }
      });
    });
    this.userService
      .getUserById(this.evento.payload.doc.data().creado)
      .subscribe((item) => {
        this.creador = item[0].payload.doc.data();
      });
  }
  
  getFecha() {
    const current = new Date();
    current.setHours(current.getHours());
    current.setMinutes(current.getMinutes());
    current.setMonth(current.getMonth());
    current.setDate(current.getDate());
    this.fechaActual = current;
    return current;
  }

  //Elimina el evento dependiendo de si es el creador
  deleteevent() {
    var creado = this.evento.payload.doc.data()['creado'];
    if (creado == this.userUid) {
      this.eventosService
        .DeleteEvento(this.evento.payload.doc.id)
        .then((respondel) => {});
      this.NotificacionesService.SearchNotification(
        this.evento.payload.doc.id
      ).subscribe((noti) => {
        noti.forEach((lanoti) => {
          this.NotificacionesService.removeNotificacion(
            lanoti.payload.doc.id
          ).then((notidel) => {});
        });
      });
    } else {
      this.borrarAlerta = true;
      setTimeout(() => {
        this.borrarAlerta = false;
      }, 2000);
    }
  }

  //Añade al usuario al listado de gente que asistirá
  addAsistir() {
    var event = this.evento.payload.doc.data();
    event['asistentes'].push(this.userUid);
    this.eventosService.editEvento(this.evento.payload.doc.id, event);
  }
  //Añade al usuario al listado de gente que no asistirá
  addNoAsistir() {
    var event = this.evento.payload.doc.data();
    event['noasistentes'].push(this.userUid);
    this.eventosService.editEvento(this.evento.payload.doc.id, event);
  }
  ver() {
    this.verParticipantes == false
      ? (this.verParticipantes = true)
      : (this.verParticipantes = false);
  }

  deleteAsistencia() {
    var participantes = this.evento.payload.doc.data()['estudiantes'];
    if (participantes.length != 0) {
      var asis = participantes.indexOf(this.userUid);
      if (asis != -1) {
        participantes.splice(asis, 1);
        this.eventoDatos['estudiantes'] = participantes;
        this.eventosService.editEvento(
          this.evento.payload.doc.id,
          this.eventoDatos
        );
      }
    }
  }
}
