<div *ngIf="cargadoDatos==true">
  <h1>Cambiar PIN</h1>
  <div class="containerForm">
    <form (ngSubmit)="actualizar()" #forma="ngForm">
      <!--PIN ADMIN FORM-->

      <div class="formgroup">
        <label for="pinAdm"><b>PIN</b></label>
        <input
          type="number"
          #pinAdm="ngModel"
          [(ngModel)]="datosPin"
          placeholder="..."
          name="pinAdm"
          class="form-control"
          maxlength="4"
          max="9999"
          required
          [ngClass]="{
            'is-valid': !pinAdm.invalid,
            'is-invalid': pinAdm.invalid
          }"
        />
      </div>
      <div
        class="invalid-feedback"
        *ngIf="pinAdm.invalid && (pinAdm.dirty || pinAdm.touched)"
      >
        <div class="invalid-feedback" *ngIf="pinAdm.errors.required">
          Pin requerido
        </div>
        <div
          class="invalid-feedback"
          *ngIf="pinAdm.errors.max || pinAdm.errors.min"
        >
          No puede superar las 4 cifras
        </div>
      </div>

      <div class="formgroup">
        <label for="pinInv"><b>Permitir invitados</b></label>
        <select name="permitir" [(ngModel)]="datosPermitir"   class="form-control">
          <option value="true">Si</option>
          <option value="false">No</option>
        </select>
      </div>

      <!--PIN GUESTS FORM-->
      <div *ngIf="datosPermitir == 'true'">
        <div class="formgroup">
          <label for="pinInv"><b>PIN invitados</b></label>
          <input
            type="number"
            #pinInv="ngModel"
            [(ngModel)]="datosPinInvitado"
            placeholder="Des"
            name="pinInv"
            class="form-control"
            max="9999"
            min="0"
            [ngClass]="{
              'is-valid': !pinInv.invalid,
              'is-invalid': pinInv.invalid
            }"
          />
        </div>

        <div
          class="invalid-feedback"
          *ngIf="pinInv.invalid && (pinInv.dirty || pinInv.touched)"
        >
          <div *ngIf="pinInv.errors.max || pinInv.errors.min">
            No puede superar las 4 cifras
          </div>
        </div>
      </div>

      <!--BOTON SUBMIT-->
      <button
        (click)="checktrue()"
        translate
        type="submit"
        [disabled]="!forma.valid"
        class="button medium center"
        translate
      >
        Enviar
      </button>
      <div id="check" *ngIf="check == true">Cambiado</div>
    </form>
  </div>
</div>
