import { BreadcrumbService } from './../services/breadcrumb.service';
import { PresenciaService } from './../services/presencia.service';
import { Colegio } from './../interfaces/colegio';
import { ClasesService } from './../services/clases.service';
import { ColegiosService } from './../services/colegios.service';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { UserService } from '../services/user.service';
import { AuthenticationService } from '../services/authentication.service';
import { Clases } from '../interfaces/clases';

@Component({
  selector: 'app-dash',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
})
export class DashboardComponent implements OnInit {
  datosUsuario: any;
  colegio: Colegio;
  clase: Clases;
  userUid: any;
  misclases: any;
  nombres: string;
  apellidos: string;
  useravatar: string;
  breadcrumb = [];
  mainUserUidDb: string;
  rolUsuario: string;
  genero: string;
  cargado: boolean = false;

  constructor(
    private userService: UserService,
    private authenticationService: AuthenticationService,
    private router: Router,
    private colegioService: ColegiosService,
    private clasesService: ClasesService,
    private breadService: BreadcrumbService
  ) {}

  ngOnInit() {
   
    this.breadcrumb = this.breadService.getbread();
    const Userdata: any = this.userService.getUserinfodata();
  
    if (Userdata === undefined) {
      this.authenticationService.isAuth().subscribe((auth) => {
        if (auth) {
          this.userUid = auth.uid;

          this.userService.getUserById(this.userUid).subscribe((userdata) => {
            if (userdata[0].payload.doc.data()) {
              this.mainUserUidDb = userdata[0].payload.doc.id;

              this.datosUsuario = userdata[0].payload.doc.data();
              this.nombres = this.datosUsuario.nombres;
              this.apellidos = this.datosUsuario.apellidos;
              this.useravatar = this.datosUsuario.avatar;
              this.rolUsuario = this.datosUsuario.rol;
              this.genero = this.datosUsuario.genero;

              if (this.datosUsuario.colegios[0]) {
                this.colegioService
                  .getColegioById(this.datosUsuario.colegios[0])
                  .subscribe(
                    (colegio) => {
                      if (colegio) {
                        this.colegio = colegio.payload.data();
                      }
                      this.cargado = true;
                    },
                    (error) => {
                      console.log(error);
                      this.cargado = true;
                    }
                  );

                this.clasesService
                  .getUserClass(this.userUid)
                  .subscribe((lasclases) => {
                    this.misclases = lasclases;
                  });
              }
            }
          });
        }
      });

      // this.getCurrentUser();
    } else {
      this.userUid = Userdata.payload.doc.data().uid;

      this.mainUserUidDb = Userdata.payload.doc.id;
      this.datosUsuario = Userdata.payload.doc.data();
      this.nombres = this.datosUsuario.nombres;
      this.apellidos = this.datosUsuario.apellidos;
      this.useravatar = this.datosUsuario.avatar;
      this.rolUsuario = this.datosUsuario.rol;
      this.genero = this.datosUsuario.genero;

      if (this.datosUsuario.colegios[0]) {
        this.colegioService
          .getColegioById(this.datosUsuario.colegios[0])
          .subscribe(
            (colegio) => {
              if (colegio) {
                this.colegio = colegio.payload.data();
                this.cargado = true;
              }
            },
            (error) => {
              this.cargado = true;
              console.log(error);
            }
          );

        this.clasesService.getUserClass(this.userUid).subscribe((lasclases) => {
          this.misclases = lasclases;
        });
      }
    }
  }

  getCurrentUser() {
    this.authenticationService.isAuth().subscribe((auth) => {
      if (auth) {
        this.userUid = auth.uid;

        this.userService.getUserById(this.userUid).subscribe((userdata) => {
          if (userdata[0].payload.doc.data()) {
            this.mainUserUidDb = userdata[0].payload.doc.id;

            this.datosUsuario = userdata[0].payload.doc.data();
            this.nombres = this.datosUsuario.nombres;
            this.apellidos = this.datosUsuario.apellidos;
            this.useravatar = this.datosUsuario.avatar;
            this.rolUsuario = this.datosUsuario.rol;
            this.genero = this.datosUsuario.genero;

            if (this.datosUsuario.colegios[0]) {
              this.colegioService
                .getColegioById(this.datosUsuario.colegios[0])
                .subscribe(
                  (colegio) => {
                    if (colegio) {
                      this.colegio = colegio.payload.data();
                      this.cargado = true;
                    }
                  },
                  (error) => {
                    console.log(error);
                    this.cargado = true;
                  }
                );

              this.clasesService
                .getUserClass(this.userUid)
                .subscribe((lasclases) => {
                  this.misclases = lasclases;
                });
            }
          }
        });
      }
    });
  }

  UpdateBreadcrumb(name, url) {
    this.breadService.addtobread(name, url);
  }

  RemoveBreadcrumb(name) {
    this.breadService.removetobread(name);
    this.router.navigate(['dashboard']);
  }

  irSala(clase) {
    let administradores = clase.payload?.doc.data().administradores
    let sala = clase.payload?.doc.data().urlsala
    console.log(sala)
    if (sala == null) {
      if (administradores.length > 0) {
        this.userService
          .getUserByIdNow(administradores[0])
          .subscribe((user) => {
            user.forEach((user2) => {
              window.open(
                'https://in.thecampus.education/webapp/#/?conference=' +
                  user2.data().usuario +
                  '&name=' +
                  this.nombres +
                  ' ' +
                  this.apellidos +
                  '&role=guest&callType=video'
              );
            });
          });
      }
    }
    else{
      window.open(
        // 
        sala
      );

    }
  }
}
