import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/functions';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { UserService } from 'src/app/services/user.service';
import { HttpParams } from '@angular/common/http';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.css'],
})
export class LayoutComponent implements OnInit {
  datosUsuario: any;
  userUid: string;
  user: any;
  mainUserUidDb: string;

  listLayout = [
    {
      name: 'Pantalla completa',
      valor: 'one_main_zero_pips',
      imagen: 'assets/img/layout1_0.jpg',
    },
    {
      name: '1:7',
      valor: 'one_main_seven_pips',
      imagen: 'assets/img/layout1_7.jpg',
    },
    {
      name: '1:21',
      valor: 'one_main_twentyone_pips',
      imagen: 'assets/img/layout1_21.jpg',
    },
    {
      name: 'Reconocimiento facial',
      valor: 'five_mains_seven_pips',
      imagen: 'assets/img/layout5_7.jpg',
    },
    {
      name: '4:0',
      valor: 'four_mains_zero_pips',
      imagen: 'assets/img/layout4_0.jpg',
    },
    {
      name: '2:21',
      valor: 'two_mains_twentyone_pips',
      imagen: 'assets/img/layout2_21.jpg',
    },
  ];
  cargadoDatos: boolean;
  dataSala: any;
  layout: any = 'one_main_seven_pips';
  checktrue: boolean = false;
  nombreLayout: any = 'Cargando...';

  constructor(
    private _userService: UserService,
    private authenticationService: AuthenticationService,
    private functions: AngularFireFunctions,
    private http: HttpClient
  ) {}

  ngOnInit(): void {
    const Userdata: any = this._userService.getUserinfodata();
    this.cargadoDatos = false;
    if (Userdata === undefined) {
      this.authenticationService.isAuth().subscribe((auth) => {
        if (auth) {
          this.userUid = auth.uid;
          this._userService.getUserById(this.userUid).subscribe((userdata) => {
            if (userdata[0].payload.doc.data()) {
              this.user = userdata[0];
              this.mainUserUidDb = userdata[0].payload.doc.id;
              this.datosUsuario = userdata[0].payload.doc.data();

              this.getroom().then(() => {
                this.cargadoDatos = true;
              });
            }
          });
        }
      });
    } else {
      this.user = Userdata;
      this.userUid = Userdata.payload.doc.data().uid;
      this.mainUserUidDb = Userdata.payload.doc.id;
      this.datosUsuario = Userdata.payload.doc.data();
      this.getroom().then(() => {
        this.cargadoDatos = true;
      });
    }
  }

  async getroom() {
    //this.http.get<any>("https://us-central1-the-campus-56b06.cloudfunctions.net/getRoom",
    await this.http
      .get<any>(
        'https://us-central1-real-oviedo.cloudfunctions.net/getRoom',
        { params: new HttpParams().set('salaname', this.datosUsuario.email) }
      )
      .subscribe((element) => {
        this.dataSala = element;
        this.layout = element.host_view;
        this.nameOfLayout();
      });
  }

  changeLayout(event, layout) {
    console.log(layout);
    if (event.target.checked) {
      this.layout = layout;
    } else {
      this.layout = 'one_main_zero_pips';
    }
  }

  check() {
    if (this.checktrue === false) {
      this.checktrue = true;
    } else {
      this.checktrue = false;
    }
  }

  nameOfLayout() {
    switch (this.layout) {
      case 'one_main_zero_pips':
        this.nombreLayout = 'Pantalla completa';
        break;
      case 'one_main_seven_pips':
        this.nombreLayout = '1:7';
        break;
      case 'one_main_twentyone_pips':
        this.nombreLayout = '1:21';
        break;
      case 'five_mains_seven_pips':
        this.nombreLayout = 'Reconocimiento facial';
        break;
      case 'four_mains_zero_pips':
        this.nombreLayout = '4:0';
        break;
      case 'two_mains_twentyone_pips':
        this.nombreLayout = '2:21';
        break;
      default:
        this.nombreLayout = 'Ninguno';
        break;
    }
  }

  actualizar() {
    //modificar los pin de la sala

    //spin = true
    this.dataSala.host_view = this.layout;
    this.dataSala.guest_view = this.layout;

    var object = { objects: [this.dataSala] };

    const callable = this.functions.httpsCallable('changeSala');
    const obs = callable({ saladata: object, name: this.datosUsuario.email });

    obs.subscribe(async (res) => {
      console.log('datos cambiados');
      window.location.reload();
    });
  }
}
