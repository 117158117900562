import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../services/authentication.service';
import { UserService } from '../services/user.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  email: string = null;
  password: string = null;
  userdata: any;
  remember = false;
  usuario: any;

  constructor(
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private router: Router
  ) {}

  ngOnInit() {
    if (localStorage.getItem('user')) {
      this.usuario = localStorage.getItem('user');
    }

    if (localStorage.getItem('passw')) {
      this.password =  atob(localStorage.getItem('passw'))
    }
  }

  login() {

    this.userService.getUserByUsuario(this.usuario.toLowerCase()).subscribe((usuar) => {
    
      if (usuar.size>0) {
      
        usuar.forEach((us) => {
          this.email = us.data().email;
          this.authenticationService
          .loginWithEmail(this.email, this.password)
          .then((data) => {
          
            let opt = data.operationType;
            this.remembercredentials(this.remember);
    
            this.userService.getUserById(data.user.uid).subscribe((ddata) => {
              if (ddata[0]) {
                this.userdata = ddata[0].payload.doc.data();
                this.userService.setUserinfo(ddata[0]);
    
                if (
                  this.userdata.rol === 'estudiante' ||
                  this.userdata.rol === 'profesor' ||
                  this.userdata.rol === 'director'
                ) {
                  this.router.navigate(['dashboard']);
                } else if (this.userdata.rol === 'admin') {
                  this.router.navigate(['admin']);
                }
              }
            });
          })
          .catch((error) => {
            alert('Datos equivocados o el usuario no existe.');
          });
        });
      }else{
        alert('Datos equivocados o el usuario no existe.');
      }
    });
   
  }

  remembercredentials(remember) {
   
    if (remember) {
 
      let con =  btoa(this.password);
      localStorage.setItem('user', this.usuario);
      localStorage.setItem('passw', con);
    }
  }

  rememberme() {
    if (this.remember == false) {
      this.remember = true;
    } else {
      this.remember = false;
    }
  }

}
